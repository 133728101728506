import { useState, useEffect } from 'react'

export const useAuth = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return { ready }
};

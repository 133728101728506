import { useState, useCallback } from 'react';

export const useApi = () => {
  const [busy, setbusy] = useState(false);

  const api = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
    setbusy(true);
    // eslint-disable-next-line no-useless-catch
    try {
      if (body) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(url, { method, body, headers });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Что то пошло не так')
      }

      setbusy(false);
      return data

    } catch (e) {
      setbusy(false);
      throw e;
    }
  }, []);

  return { busy, api }
};

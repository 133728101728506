import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Divider,
  TextField,
  colors
} from '@material-ui/core';
import { useHttp } from '../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';
import {
  systemLogout
  // systemLogin
} from '../../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const Security = (props) => {
  const { user, className, systemLogout, ...rest } = props;

  const classes = useStyles();

  const [form, setForm] = useState({
    password: '',
    confirm: ''
  });
  const { request, loading } = useHttp();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const valid = form.password && form.password === form.confirm;

  const updateSettingsHandler = useCallback(async () => {
    const res = await request('/user/update/password', 'POST', {
      id: user.id,
      password: form.password
    });
    if (!loading && res) {
      // systemLogin({ TOKEN: token, USER_DATA: {...user, pass_changed:'Y'} });
      enqueueSnackbar('Успешная смена пароля!', { variant: 'success' });
      systemLogout();
    }
  }, [form]);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateSettingsHandler();
  };
  useEffect(() => {
    if (user.pass_changed === 'N') {
      enqueueSnackbar('Смените пароль для вашей безопасности!', {
        variant: 'warning'
      });
    }
  }, []);

  return (
    <>
      {loading && <LinearProgress />}
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader title="Изменить пароль" />
        <Divider />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Пароль"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={form.password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Подтверждение пароля"
                  name="confirm"
                  onChange={handleChange}
                  type="password"
                  value={form.confirm}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            color="secondary"
            disabled={!valid}
            onClick={handleSubmit}
            variant="contained"
          >
            Сохранить настройки
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

Security.propTypes = {
  className: PropTypes.string,
  systemLogout: PropTypes.function,
  user: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    user: state.system.USER_DATA
    // token: state.system.TOKEN
  };
};
const mapDispatchToProps = {
  systemLogout
  // systemLogin
};
export default connect(mapStateToProps, mapDispatchToProps)(Security);

import React, {useState, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { DokaOverlay, DokaModal } from '../../../../plugins/react-doka';
import './DemoProfile.css';
import {Avatar, Button, colors, Typography, Tooltip, FormControlLabel, Badge} from '@material-ui/core';
import bg from '../../../../resources/images/backgrounds/auth_bg.jpg';

import { makeStyles } from '@material-ui/styles';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import {uploadImage} from '../../../../hooks/uploader.hook';
import {SwitchHandler} from '../../../../components/Switcher/Switcher';
import {changeTheme} from '../../../../redux/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  cover: {
    position: 'relative',
    maxHeight: 233,
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
    },
    '&:hover': {
      '& $changeButton': {
        opacity: 1
      }
    }
  },
  changeButton: {
    opacity: 0.25,
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      bottom: 'auto'
    },
    '&:hover': {
      backgroundColor: colors.blueGrey[900]
    },
    zIndex: 9
  },
  addPhotoIcon: {
    marginRight: theme.spacing(1)
  },
  container: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    padding: theme.spacing(2, 3),
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  avatar: {
    border: `2px solid ${theme.palette.white}`,
    height: 120,
    width: 120,
    top: -20,
    left: theme.spacing(3),
    position: 'absolute',
    cursor: 'pointer'
  },
  details: {
    marginLeft: 136
  },
  actions: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    },
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  pendingButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  personAddIcon: {
    marginRight: theme.spacing(1)
  },
  mailIcon: {
    marginRight: theme.spacing(1)
  },
  input: {
    width: 0,
    height: 0,
    zIndex: -666,
    visibility: 'hidden',
    position: 'absolute',
    top: -666,
    left: -666
  }
}));

export const InteractiveHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.system.USER_DATA)
  const [state, setState] = useState({
    mask: (root, setInnerHTML) => {
      setInnerHTML(root, `
                    <mask id="my-mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                        <circle cx="50%" cy="50%" r="50%" fill="black"/>
                    </mask>
                    <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
                    <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
                `);
    },
    banner: {
      enabled: false,
      image: bg,
      src: '',
      crop: {
        aspectRatio: null,
        center: {
          x: 0.5,
          y: 0.543
        }
      }
    },
    profile: {
      enabled: false,
      image: user.avatar || null,
      src: '',
      crop: {
        aspectRatio: 1,
        center: {
          x: 0.5378,
          y: 0.355
        }
      }
    }
  });

  const handleDokaConfirmBanner = (output) => {

    console.log('Confirmed Doka Banner Overlay', output);

    setState({
      ...state,
      banner: {
        ...state.banner,
        image: output.file,
        crop: output.data.crop,
        enabled: false
      }
    })

    uploadImage(user.id, 'banner', user.role, output.file, dispatch)
  };

  const handleDokaCancelBanner = () => {

    console.log('Cancelled Doka Banner Overlay');

    setState({
      ...state,
      banner: {
        ...state.banner,
        enabled: false
      }
    });
  }

  const handleToggleBannerEditor = input => {

    console.log('Toggle Doka Banner Overlay');

    setState({
      ...state,
      banner: {
        ...state.banner,
        src: input,
        enabled: !state.banner.enabled
      }
    })
  };


  /**
   * Profile Picture
   */
  const handleToggleProfileEditor = input => {

    console.log('Toggle Doka Profile Modal');

    setState({
      ...state,
      profile: {
        ...state.profile,
        src: input,
        enabled: !state.profile.enabled
      }
    });
  }

  const handleDokaConfirmProfile = (output) => {

    console.log('Confirmed Doka Profile Modal', output);

    setState({
      ...state,
      profile: {
        ...state.profile,
        enabled: false,
        image: output.file,
        crop: output.data.crop
      }
    });

    uploadImage(user.id, 'avatar', user.role, output.file, dispatch)
  }

  const handleDokaCancelProfile = () => {

    console.log('Cancelled Doka Profile Modal');

    setState({
      ...state,
      profile: {
        ...state.profile,
        enabled: false
      }
    });
  }

  const handleBannerUpload = createRef();
  const handleAvatarUpload = createRef();
  
  const clickUploadHandler = source => {
    switch (source) {
      case 'banner':
        return handleBannerUpload.current.click()
      case 'avatar':
        return handleAvatarUpload.current.click()
      default: return null;
    }
  };

  const sourceFileHandler = e => {
    switch (e.target.name) {
      case 'banner':
        return handleToggleBannerEditor(e.target.files[0])
      case 'avatar':
        return handleToggleProfileEditor(e.target.files[0])
      default: return null;
    }
  };

  const _settings = JSON.parse(localStorage.getItem('theme')) || {check: true}
  const [check, setCheck] = useState(_settings.check)

  const changeThemeHandler = e => {
    const checked = e.target.checked
    setCheck(checked)

    const setup = {
      theme: checked ? 'WHITE' : 'DARK',
      check: checked
    }
    dispatch(changeTheme(setup))

    window.location.reload()
  };

  const { banner, profile, mask } = state;

  return (
    <div className="DemoProfile">
      <div
        className="ThemeChecker"
      >
        <Tooltip title={`Сменить тему на ${check ? 'темную' : 'светлую'}`}>
          <Badge
            badgeContent="НОВОЕ"
            color="primary"
          >
            <FormControlLabel
              control={(
                <SwitchHandler
                  checked={check}
                  onChange={changeThemeHandler}
                />
              )}
              label=""
            />
          </Badge>
        </Tooltip>
      </div>

      <DokaOverlay
        className={classes.cover}
        crop={banner.crop}
        enabled={banner.enabled}
        onCancel={handleDokaCancelBanner}
        onConfirm={handleDokaConfirmBanner}
        src={banner.src}
        utils={['crop', 'filter', 'color']}
      >
        {!banner.enabled &&
          <Button
            className={classes.changeButton}
            onClick={() => clickUploadHandler('banner')} // onClick={handleToggleBannerEditor}
            variant="contained"
          >
            <AddPhotoIcon className={classes.addPhotoIcon} />
            Изменить обложку
          </Button>
        }
        <img
          alt=""
          src={user.banner ? user.banner : bg}
        />
      </DokaOverlay>

      <div className={classes.container}>
        <Tooltip
          arrow
          title="Изменить"
        >
          <Avatar
            alt="Person"
            className={classes.avatar}
            onClick={() => clickUploadHandler('avatar')}
            src={user.avatar}
          />
        </Tooltip>

        <div className={classes.details}>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            {user.role === 'CHAIRMAN' ?
              'ПРЕДСЕДАТЕЛЬ' :
              'ПОЛЬЗОВАТЕЛЬ'
            }
          </Typography>
          <Typography
            component="h1"
            variant="h4"
          >
            {user.first_name}  {user.last_name}
          </Typography>
        </div>
      </div>

      { profile.enabled &&
        <DokaModal
          crop={profile.crop}
          cropAspectRatio={1}
          cropMask={mask}
          onCancel={handleDokaCancelProfile}
          onConfirm={handleDokaConfirmProfile}
          outputData
          src={profile.src}
          utils={['crop', 'filter', 'color']}
        />
      }
      <input
        accept=".jpg, .jpeg, .png"
        className={classes.input}
        name="banner"
        onChange={sourceFileHandler}
        ref={handleBannerUpload}
        type="file"
      />
      <input
        accept=".jpg, .jpeg, .png"
        className={classes.input}
        name="avatar"
        onChange={sourceFileHandler}
        ref={handleAvatarUpload}
        type="file"
      />
    </div>
  )
}

import {GET_COUNTERS, SET_COUNTERS, UPDATE_COUNTER} from '../types';

const initialState = {
  parcel: '',
  counters: [],
  counter: {},
  loading: false
}

export const countersReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_COUNTERS:
      return {...state, parcel: action.payload, loading: true};
    case SET_COUNTERS:
      return {...state, counters: action.payload, loading: false};
    case UPDATE_COUNTER:
      return {...state, counter: state.counters.filter(counter => counter.id === action.payload)};
    default:
      return state
  }
}

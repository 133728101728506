import React, { useContext } from 'react';
// import {useParams} from 'react-router';
import { useSelector } from 'react-redux';
// noinspection ES6CheckImport
import { PDFViewer } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import {
  ListItemIcon,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  CircularProgress
} from '@material-ui/core';
import { MyDocument } from './pdfGenerator/pdfGenerator';
import { DataContext } from './CountersTable';
import { SelectContext } from '../../../DashboardCounter/DashboardCounter';
import QRCode from 'qrcode.react';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props} />;
});

const theme = JSON.parse(localStorage.getItem('theme'));
const setTheme = (set) => {
  if (set && set.theme === 'DARK') return 'DARK';
  return '';
};

// eslint-disable-next-line react/prop-types,react/no-multi-comp
export const PdfDialog = ({ open, state, loading, data, code }) => {
  const classes = useStyles();
  // const params = useParams();

  const context = useContext(DataContext);
  const contextSelect = useContext(SelectContext);
  // // eslint-disable-next-line react/prop-types
  const { last_name, second_name, first_name, role } = useSelector(
    (state) => state.system.USER_DATA
  );

  const sumHandler = (num) => {
    if (!num && isNaN(num)) {
      return ' ';
    } else {
      return (
        parseFloat(context.finalSum)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
          .replace('.', ',') + ' руб.'
      );
    }
  };

  return (
    <>
      {code.qr && (
        <>
          <QRCode
            className="QRCode"
            renderAs="svg"
            size={178}
            value={code.qr}
          />
          <canvas className="QRCanvas" />
        </>
      )}
      <>
        <ListItemIcon>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <ReceiptIcon fontSize="small" />
          )}
        </ListItemIcon>
        <Typography
          noWrap
          variant="inherit">
          Выгрузить квитанцию
        </Typography>
      </>
      <Dialog
        className={setTheme(theme)}
        fullScreen
        onClose={state}
        open={open}
        TransitionComponent={Transition}>
        <AppBar className={`${classes.appBar} pdfBar`}>
          <Toolbar>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={state}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <PDFViewer>
          <MyDocument
            address={
              role === 'GARDENER'
                ? `${data.address_region}, ${data.address_district}, ${data.address_locality}, ${data.address_array}`
                : ' '
            }
            bank={data.bank_name}
            bic={data.bik && data.bik.toString()}
            bill={`Оплата за электроэнергию с ${new Date(
              context.per.start_date
            ).toLocaleDateString('ru', {
              day: '2-digit',
              month: 'long'
            })} по ${new Date(context.per.end_date).toLocaleDateString('ru', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            })}: день - ${context.finalDay.toFixed(
              2
            )} кВт, ночь - ${context.finalNight.toFixed(2)} кВт, участок № ${
              contextSelect.params.plot
            }`}
            date={new Date().toLocaleDateString('ru', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            })}
            img={code.img}
            inn={data.inn && data.inn.toString()}
            kor={data.korr_account && data.korr_account.toString()}
            name={data.receiver}
            number={data.account_number && data.account_number.toString()}
            payer={
              role === 'GARDENER'
                ? `${last_name} ${first_name} ${second_name}`
                : ' '
            }
            sum={sumHandler(context.finalSum)}
          />
        </PDFViewer>
      </Dialog>
    </>
  );
};

PdfDialog.propTypes = {
  code: PropTypes.object,
  data: PropTypes.object,
  state: PropTypes.function,
};

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import axios from 'utils/axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel
} from '@material-ui/core';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  GmailIcon,
  InstagramIcon,
  SkypeIcon,
  TelegramIcon,
  VkIcon
} from '../../../../components/SocialIcons/SocialIcons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhoneInput from 'react-phone-input-2';
import { useApi } from '../../../../hooks/api.hook';
import { emailValidator } from '../../../../hooks/email.hook';
import { initialStateSuggestion } from './FormInitialSug';
import SuccessSnackbar from './SuccessSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  overview: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  product: {
    display: 'flex',
    alignItems: 'center'
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  notice: {
    marginTop: theme.spacing(2)
  }
}));

const SocialProfiles = (props) => {
  const { user, className, updateUserData } = props;

  const classes = useStyles();

  const [form, setForm] = useState({ ...user });

  const { api } = useApi();
  const [sug, setSug] = useState(initialStateSuggestion);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const inputFormHandler = (e) => {
    if (e !== null) {
      setForm({ ...form, [e.target.name]: e.target.value });
      if (e.target.name === 'email') {
        emailSug(e.target.name, e.target.value);
      }
    }
  };

  const updateSettingsHandler = useCallback(() => {
    axios.post('/user/update/contacts', { ...form }).then((r) => {
      if(r.data.status){
        updateUserData();
      }
    });
  }, [form]);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateSettingsHandler();
    
    // setOpenSnackbar(true);
  };
  const changePhoneHandler = (value, name) => {
    value = value.replace(/\D+/g, '');
    setForm({ ...form, [name]: value });
  };
  const emailSug = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/email', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Настройка социальных профилей" />
      <Divider />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid
            container
            spacing={2}>
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}>
              <Grid
                item
                xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined">
                  <PhoneInput
                    containerClass="react-tel-input PHONE"
                    country={'ru'}
                    countryCodeEditable={false}
                    disableDropdown
                    inputProps={{
                      required: true,
                      pattern: '.{18,}'
                    }}
                    onChange={(e) => changePhoneHandler(e, 'phone')}
                    value={form.phone}
                  />
                  {/* <InputLabel>Номер телефона *</InputLabel>
                  <OutlinedInput
                    labelWidth={120}
                    name="phone"
                    onChange={inputFormHandler}
                    required
                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    }
                    value={form.phone}
                  /> */}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}>
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) => option.value}
                  inputValue={form.email}
                  label="email"
                  name="email"
                  onChange={(e, newVal) => {
                    setForm({ ...form, email: newVal.value });
                  }}
                  onInputChange={inputFormHandler}
                  options={sug.email}
                  renderInput={(params) => (
                    <FormControl
                      fullWidth
                      ref={params.InputProps.ref}
                      variant="outlined">
                      <InputLabel
                        className={
                          !!form.email.trim().length &&
                          !emailValidator(form.email)
                            ? 'ERROR'
                            : ''
                        }>
                        Email *{' '}
                      </InputLabel>
                      <OutlinedInput
                        {...params.inputProps}
                        {...params.inputLabel}
                        error={
                          !!form.email.trim().length &&
                          !emailValidator(form.email)
                        }
                        labelWidth={46}
                        name="email"
                        required
                        startAdornment={
                          <InputAdornment position="start">
                            <GmailIcon />
                          </InputAdornment>
                        }
                        value={form.email}
                      />
                    </FormControl>
                  )}
                  renderOption={(option) => <span>{option.value}</span>}
                />
                {/* <FormControl
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Email</InputLabel>
                  <OutlinedInput
                    labelWidth={36}
                    name="email"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <GmailIcon />
                      </InputAdornment>
                    }
                    value={form.email}
                  />
                </FormControl> */}
              </Grid>
              <Grid
                item
                xs={12}>
                <PhoneInput
                  containerClass="react-tel-input WATSAPP"
                  country={'ru'}
                  countryCodeEditable={false}
                  disableDropdown
                  onChange={(e) => changePhoneHandler(e, 'watsapp')}
                  value={form.watsapp}
                />
                {/* <FormControl
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Watsapp</InputLabel>
                  <OutlinedInput
                    labelWidth={57}
                    name="watsapp"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <WatsappIcon />
                      </InputAdornment>
                    }
                    value={form.watsapp}
                  />
                </FormControl> */}
              </Grid>
              <Grid
                item
                xs={12}>
                <PhoneInput
                  containerClass="react-tel-input VIBER"
                  country={'ru'}
                  countryCodeEditable={false}
                  disableDropdown
                  onChange={(e) => changePhoneHandler(e, 'viber')}
                  value={form.viber}
                />
                {/* <FormControl
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Viber</InputLabel>
                  <OutlinedInput
                    labelWidth={35}
                    name="viber"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <ViberIcon />
                      </InputAdornment>
                    }
                    value={form.viber}
                  />
                </FormControl> */}
              </Grid>
            </Grid>
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}>
              <Grid
                item
                xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined">
                  <InputLabel>Telegram</InputLabel>
                  <OutlinedInput
                    labelWidth={60}
                    name="telegram"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <TelegramIcon />
                      </InputAdornment>
                    }
                    value={form.telegram}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined">
                  <InputLabel>Skype</InputLabel>
                  <OutlinedInput
                    labelWidth={40}
                    name="skype"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <SkypeIcon />
                      </InputAdornment>
                    }
                    value={form.skype}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined">
                  <InputLabel>VKontakte</InputLabel>
                  <OutlinedInput
                    labelWidth={65}
                    name="vk"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <VkIcon />
                      </InputAdornment>
                    }
                    value={form.vk}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined">
                  <InputLabel>Instagram</InputLabel>
                  <OutlinedInput
                    labelWidth={65}
                    name="instagram"
                    onChange={inputFormHandler}
                    startAdornment={
                      <InputAdornment position="start">
                        <InstagramIcon />
                      </InputAdornment>
                    }
                    value={form.instagram}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="secondary"
            type="submit"
            variant="contained">
            Сохранить настройки
          </Button>
        </CardActions>
      </form>
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar} />
    </Card>
  );
};

SocialProfiles.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    user: state.system.USER_DATA
  };
};

export default connect(mapStateToProps)(SocialProfiles);

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './theme'
import {blackTheme} from './theme/black'

import 'react-phone-input-2/lib/material.css';
import '../node_modules/video-react/dist/video-react.css';


import {useRoutes} from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './assets/scss/index.scss';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/index.css';
import './assets/black.css';
import moment from 'moment';
import ruLocale from 'date-fns/locale/ru';
import 'moment/locale/ru';
import {useAuth} from './hooks/auth.hook';
import {AuthContext} from './context/AuthContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from 'react-redux';
moment.locale('ru');
const history = createBrowserHistory();

const mapStateToProps = state => {
  return {
    auth: state.system.TOKEN,
    pass_change: !state.system.USER_DATA ? '' : state.system.USER_DATA.pass_changed
  }
};
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return moment(date).format('dd, MMM D');
  }
}

const App = connect(mapStateToProps, null)(({auth, pass_change}) => {
  const _theme = JSON.parse(localStorage.getItem('theme'))
  const {ready} = useAuth();
  const isAuthenticated = !!auth;
  const isPassChanged = (pass_change == 'N') ? false : true;
  const routes = useRoutes(isAuthenticated, isPassChanged);

  if (!ready) return <LinearProgress />;

  const setTheme = set => {
    if (set && set.theme === 'WHITE') return theme;
    if (set && set.theme === 'DARK') return blackTheme;
    return theme
  };

  return (
    <ThemeProvider theme={setTheme(_theme)}>
      <SnackbarProvider maxSnack={3}>
        <MuiPickersUtilsProvider
          locale={ruLocale}
          utils={LocalizedUtils}
        >
          <AuthContext.Provider
            value={{
              isAuthenticated,
              isPassChanged
            }}
          >
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <CookiesNotification />
              {routes}
            </Router>
          </AuthContext.Provider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
});

export default App;

import React, {useEffect, useState, createContext, useCallback} from 'react'
import {connect} from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TableDetails } from './components/TableDetails/TableDetails'
import { TableSkeleton } from './components/TableDetails/TableSkeleton'
import SearchIcon from '@material-ui/icons/Search';

import Paper from '@material-ui/core/Paper'
import {
  RowDetailState,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedFiltering,
  IntegratedPaging
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Toolbar,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  Table,
} from '@devexpress/dx-react-grid-material-ui';

import { Template } from '@devexpress/dx-react-core';

import {
  Fab
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add'
import {
  pagingPanelMessages,
  tableMessages,
  TemplateWrapper,
  PlotsWrapper
} from '../../utils/settings/settings'
import Aux from '../../layouts/Auxilary';
import {clearFunc, setPlots} from '../../redux/actions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

export const DetailsContext = createContext(undefined, undefined);

const mapStateToProps = state => {
  return {
    user: state.system.USER_DATA,
    snt: state.plots.snt,
    plots: state.plots.plots,
    func: state.plots.func,
    loading: state.plots.loading
  }
};


const mapDispatchToProps = {
  setPlots,
  clearFunc
}

const PlotsPage = connect(mapStateToProps, mapDispatchToProps)(({user, setPlots, plots, func, clearFunc, snt, loading}) => {
  const history = useHistory();

  const createPlotHandler = useCallback(() => {
    return history.push('/plot/configure/info')
  }, [history]);

  const editPlotHandler = useCallback((id) => {
    // if(plot.includes('/')){
    //   plot = plot.split('/').filter(Boolean);
    //   plot = plot.join('-')
    // }
    history.push(`/plot/${id}/configure/info`)
    // return clearFunc()
  }, [history, clearFunc]);

  const [columns] = useState([
    { name: 'plot', title: 'Участок' },
    { name: 'name', title: 'Контактное лицо' },
    { name: 'phone', title: 'Телефон' },
    { name: 'actions', title: ' '},
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'plot', width: 100 },
    { columnName: 'name' },
    { columnName: 'phone', width: 200 },
    { columnName: 'actions', width: 60 }
  ]);

  const getPlotsHandler = useCallback(() => {
    const role = user.role;
    const id = role === 'CHAIRMAN' ? user.group : user.id;
    setPlots({id, role})
  }, [setPlots, user]);

  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [id, setId] = useState([0]);

  const expandRowHandler = id => {
    const num = id.reverse();
    const _ = num[0];

    if (_ === expandedRowIds[0]) {
      setExpandedRowIds([])
    } else {
      const arr = [];
      setId(plots[_]);
      setExpandedRowIds([...arr, _])
    }
  };

  useEffect(() => {
    getPlotsHandler()
    if (func) {
      const {id} = func
      editPlotHandler(id)
    }
  }, [getPlotsHandler, func, editPlotHandler]);

  const [filtered, setFiltered] = useState([]);

  const searchStateHandler = e => {
    const value = e.target.value;
    const filteredPlots = plots.filter(plot => {
      const number = plot.plot;
      const name = plot.name.split(' ').join('').toLowerCase();
      const phone = plot.phone ? plot.phone.replace(/\D+/g,'') : '';
      const search = value.toString().toLowerCase();

      const str = `${number}${plot.phone === 'не задано' ? '' : phone}${plot.name === 'не задано' ? '' : name}`;

      console.log(str)
      return str.includes(search)
    })

    setFiltered(filteredPlots);
  } ;
  // console.log('plots', plots)
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'plot', compare: (a, b) => {
      const priorA = a.toString().match(/(\d+)/g) ? a.toString().match(/(\d+)/g)[0] : 0;
      const priorB = b.toString().match(/(\d+)/g) ? b.toString().match(/(\d+)/g)[0] : 0;

      return priorA - priorB
    }},
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <DetailsContext.Provider
      value={id || 0}
    >
      <Aux
        description={snt && `СНТ “${snt}”`}
        name="Участки"
      >
        <PlotsWrapper
          className="PlotsTable"
        >
          <Paper>
            <Grid
              columns={columns}
              rows={!filtered.length ? plots : filtered}
            >
              <RowDetailState
                expandedRowIds={expandedRowIds}
                onExpandedRowIdsChange={id => {expandRowHandler(id)}}
              />
              <SortingState
                defaultSorting={[{ columnName: 'plot', direction: 'asc' }]}
              />
              <PagingState
                pageSize={10}
              />
              <IntegratedSorting columnExtensions={integratedSortingColumnExtensions}/>
              <IntegratedPaging />
              <IntegratedFiltering />
              <Table
                columnExtensions={tableColumnExtensions}
                messages={tableMessages}
              />
              <TableRowDetail
                contentComponent={
                  loading ? TableSkeleton : TableDetails
                }
              />
              <TableHeaderRow showSortingControls />
              {user.role === 'CHAIRMAN' &&
                  <Toolbar />
              }
              {user.role === 'CHAIRMAN' &&
                  <Template
                    name="toolbarContent"
                  >
                    <TemplateWrapper>
                      <Fab
                        aria-label="add"
                        className="BtnGreen floatLeft"
                        color="secondary"
                        onClick={createPlotHandler}
                        size="small"
                      >
                        <AddIcon />
                      </Fab>
                      <span className="ButtonTitle">Добавить участок</span>
                    </TemplateWrapper>
                    <TextField
                      className="PaddingInput"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        placeholder: 'Найти...'
                      }}
                      onChange={searchStateHandler}
                      variant="outlined"
                    />
                  </Template>
              }
              <PagingPanel
                messages={pagingPanelMessages}
              />
            </Grid>
          </Paper>
        </PlotsWrapper>
      </Aux>
    </DetailsContext.Provider>
  )
});

export default PlotsPage;

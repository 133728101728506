import React from 'react';
import { useHistory } from 'react-router';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
  Fab,
  Typography,
  FormControlLabel,
  Avatar,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import EditIcon from '@material-ui/icons/Edit';
// import PropTypes from 'prop-types';

const place = 'expansion';
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%'
  },
  root: {
    width: '100%',
    display: place === 'expansion' ? 'flex' : 'inline-flex'
  },
  container: {
    flexDirection: 'column'
  },
  panel: {
    paddingLeft: 0,
    paddingRight: 16,
    width: '100%'
  },
  margin: {
    marginLeft: theme.spacing(2)
  },
  fl: {
    display: 'flex',
    minWidth: '100%',
    padding: '15px 0'
  }
}));
export const NewsItemDetails = ({
  newsItem,
  handleClickDelete
  //   getGroupsDataHandler
}) => {
  const classes = useStyles();
  const history = useHistory();
  const description = JSON.parse(newsItem.body).blocks[0].text;

  const handleClickOpen = (id) => {
    history.push(`/new/${id}/configure/main`);
  };
  return (
    <ExpansionPanel
      key={newsItem.id}
      //   onChange={() => {
      //     getDevicesDataHandler(group.id, group.id_type);
      //   }}
    >
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        className={`${classes.panel}  MuiReverse`}
        expandIcon={<ExpandMoreIcon />}>
        <div className={classes.content}>
          <List
            className={`${classes.root} `}
            onClick={(e) => e.stopPropagation()}>
            <ListItem>
              <ListItemText primary={newsItem.header} />
            </ListItem>
            <ListItem
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: 'flex-end', width: '30%' }}>
              <div>
                <span>
                  <Fab
                    aria-label="edit"
                    className="BtnGreen extraSmall"
                    color="secondary"
                    onClick={() => handleClickOpen(newsItem.id)}
                    size="small">
                    <EditIcon />
                  </Fab>
                </span>
              </div>
              <div className={classes.margin}>
                <span>
                  <Fab
                    aria-label="delete"
                    className="BtnRed extraSmall"
                    color="secondary"
                    onClick={() =>
                      handleClickDelete(newsItem.id, newsItem.header)
                    }
                    size="small">
                    <DeleteIcon />
                  </Fab>
                </span>
              </div>
            </ListItem>
          </List>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.container}>
        {description !== undefined ? (
          <div className={classes.fl}>
            <FormControlLabel
              control={
                <Avatar
                  className="plotAvatar"
                  // onClick={clickUploadHandler}
                  src={`${newsItem.img_link}`}
                  variant="square">
                  <PhotoCameraOutlinedIcon />
                </Avatar>
              }
              label=""
            />
            <div>
              <Typography
                component="p"
                variant="h6">
                <strong>Описание: </strong>
                <Typography
                  component="span"
                  variant="body1">
                  {description.length <= description.slice(0,53).length ? description : `${description.slice(0,53)}...`}
                </Typography>
              </Typography>
              <Typography
                component="p"
                variant="h6">
                <strong>Дата создания: </strong>
                <Typography
                  component="span"
                  variant="body1">
                  {newsItem.date_formatted}
                </Typography>
              </Typography>
            </div>
          </div>
        ) : (
          <div>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </div>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
// NewsItemDetails.propTypes = {
//   devices: PropTypes.object,
//   getDevicesDataHandler: PropTypes.function,
//   getGroupsDataHandler: PropTypes.function,
//   group: PropTypes.object,
//   handleClickDelete: PropTypes.function,
// };

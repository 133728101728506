import React, {useCallback, useEffect, useState, useContext }from 'react';
import {useSelector} from 'react-redux';
// import {useParams} from 'react-router';
import {
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import {PdfDialog} from './pdfDialog';

import { useHttp } from '../../../../hooks/http.hook';
import { DataContext } from './CountersTable';
import {SelectContext} from '../../../DashboardCounter/DashboardCounter';


export const CountersTableMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const {loading, request} = useHttp();
  const context = useContext(DataContext);
  const contextSelect = useContext(SelectContext);
  // eslint-disable-next-line react/prop-types
  const {group, last_name, second_name, first_name} = useSelector(state => state.system.USER_DATA)
  const [data, setData] = useState({});
  const [code, setCode] = useState({}); 

  const getData = useCallback(async final => {
    
    const response = await request ('/snt/get/info', 'POST', {id: group})

    if (!response.status) return;
    const result = response.result[0];
    setData({...data, ...result})

    if (
      result.receiver &&
      result.account_number &&
      result.bank_name &&
      result.bik &&
      result.korr_account &&
      result.inn &&
      result.account_name &&
      final.finalSum
    ) {

      const qr = `ST00012|Name=${result.receiver}|PersonalAcc=${result.account_number}|BankName=${result.bank_name}|BIC=${result.bik}|CorrespAcc=${result.korr_account}|PayeelNN=${result.inn}|${last_name ? `LastName=${last_name}|` : ''}${first_name ? `FirstName=${first_name}|` : ''}${second_name ? `MiddleName=${second_name}|` : ''}Purpose=Оплата за электроэнергию с ${new Date(final.per.start_date).toLocaleDateString('ru', {day: '2-digit', month: 'long'})} по ${new Date(final.per.end_date).toLocaleDateString('ru', {day: '2-digit', month: 'long', year: 'numeric'})}: день - ${final.finalDay.toFixed(2)} кВт, ночь - ${final.finalNight.toFixed(2)} кВт, участок № ${contextSelect.params.plot}|PayerAddress=${result.address_region} ${result.address_district} ${result.address_locality} ${result.address_array}|Sum=${final.finalSum.toFixed(2) * 100}`;

      console.log(qr)
      setCode({...code, qr})

      const SVGDomElement = document.querySelector('.QRCode');
      const serializedSVG = new XMLSerializer().serializeToString(SVGDomElement);
      const base64Data = window.btoa(serializedSVG);
      const imgsrc = `data:image/svg+xml;base64,${base64Data}`;


      const canvas = document.querySelector('.QRCanvas')
      const context = canvas.getContext('2d');

      canvas.setAttribute('width', 178);
      canvas.setAttribute('height', 178);

      const image = new Image();
      image.src = imgsrc;
      image.onload = function() {
        context.drawImage(image, 0, 0);
        const canvasdata = canvas.toDataURL('image/png');

        setCode({...code, img: canvasdata})
      };
    }


  }, [request, group]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context.finalSum) {
      getData(context)
    }
  }, [getData, context])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = JSON.parse(localStorage.getItem('theme'))
  const setTheme = set => {
    if (set && set.theme === 'DARK') return 'DARK';
    return ''
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open)
    setAnchorEl(null);
  }
  return (
    <div
      className="MenuMore"
    >
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        className={`${setTheme(theme)}`}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >

        <MenuItem
          disabled={loading}
          onClick={handleOpen}
        >
          <PdfDialog
            code={code}
            data={data}
            loading={loading}
            open={open}
            state={handleOpen}
          />
        </MenuItem>

      </Menu>
    </div>
  );
}

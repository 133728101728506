import React from 'react';
import { ProfileMenuNav } from './ProfileMenu.nav'

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Fab,
  Card,
  CardContent,
  CardActions,
  Collapse
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    padding: 0,
    boxShadow: 'none',
    border: 'none',
    position: 'relative',
    overflow: 'unset'
  },
  card: {
    padding: 0
  },
  expand: {
    padding: 0,
    bottom: '-37px',
    right: '-5px',
    position: 'absolute',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

// eslint-disable-next-line react/prop-types
export const ProfileMenu = ({ profile }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardContent
        className={classes.card}
      >
        {profile}
      </CardContent>
      <CardActions
        className="DeletePadding"
        disableSpacing
      >
        <Fab
          aria-expanded={expanded}
          className={`${clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })} DeleteShadow`}
          color="secondary"
          onClick={handleExpandClick}
          size="small"
        >
          <ExpandMoreIcon />
        </Fab>
      </CardActions>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <ProfileMenuNav/>
      </Collapse>
    </Card>
  );
};

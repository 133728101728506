/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
// import VideocamIcon from '@material-ui/icons/Videocam';

export const pages = [
  {
    title: 'Главная',
    href: '/',
    icon: HomeIcon,
    admin: false
  },
  {
    title: 'Садоводство',
    href: '/garden/address',
    icon: BallotOutlinedIcon,
    admin: false
  },
  {
    title: 'Участки',
    href: '/plots',
    icon: DashboardIcon,
    admin: false
  },
  {
    title: 'Показания',
    href: '/dashboards/default/electric',
    icon: BarChartIcon,
    admin: false
  },
  {
    title: 'Новости',
    href: '/newssettings/list',
    icon: AnnouncementOutlinedIcon,
    admin: true
  },
  {
    title: 'Документы',
    href: '/documents',
    icon: DescriptionIcon,
    admin: false
  }
  // {
  //   title: 'Видеонаблюдение',
  //   href: '/videos',
  //   icon: VideocamIcon,
  //   admin: false
  // }

  // {
  //   title: 'Новости',
  //   href: '/social-feed',
  //   icon: DynamicFeedOutlinedIcon
  // },

  // {
  //   title: 'Calendar',
  //   href: '/calendar',
  //   icon: CalendarTodayIcon,
  //   label: () => <Label color={colors.green[500]}>New</Label>
  // },
  // {
  //   title: 'Mail',
  //   href: '/mail',
  //   icon: MailIcon,
  //   label: () => (
  //     <Label
  //       color={colors.red[500]}
  //       shape="rounded"
  //     >
  //       2
  //     </Label>
  //   )
  // },
  // {
  //   title: 'Chat',
  //   href: '/chat',
  //   icon: ChatIcon,
  //   label: () => (
  //     <Label
  //       color={colors.red[500]}
  //       shape="rounded"
  //     >
  //       4
  //     </Label>
  //   )
  // },
];

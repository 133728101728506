import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import no_image from '../../../resources/images/backgrounds/no_image.png';
import moment from 'moment';

import {
  Typography,
  Avatar,
  // Collapse,
  CardContent,
  CardMedia,
  // CardActionArea,
  CardHeader,
  Card
} from '@material-ui/core';

import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer'
  },
  media: {
    // height: 250,
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#84be41'
  }
  // content:{
  //   background: 'rgba(255, 255, 255, 0.5)',
  //   height: 'inherit',
  //   color: '#fff'
  // }
}));

const dateHandler = (date) => {
  return moment(date).format('dddd, DD MMMM YYYY, hh:mm:ss');
};

// eslint-disable-next-line react/prop-types
export const NewsCard = ({
  id,
  date,
  title,
  description,
  img
}) => {
  const classes = useStyles();
  const history = useHistory()

  description = JSON.parse(description).blocks[0].text;

  const handleExpandClick = () => {
    history.push(`/newspage/${id}`)
  };

  // const validImgHandler = (url) => {
  //   if (!url) return false;
  //   if (url.indexOf('http') + 1) return true;
  // };
  // return (
  //   <Card className={classes.root}>
  //     <CardActionArea>
  //       <CardMedia
  //         className={`${classes.media}`}
  //         image={validImgHandler(img) ? img : no_image}
  //         title="Contemplative Reptile">
  //         <CardContent className={classes.content}>
  //           <Typography
  //             component="h2"
  //             gutterBottom
  //             variant="h5">
  //             Lizard
  //           </Typography>
  //           <Typography
  //             color="textSecondary"
  //             component="p"
  //             variant="body2">
  //             Lizards are a widespread group of squamate reptiles, with over
  //             6,000 species, ranging across all continents except Antarctica
  //           </Typography>
  //         </CardContent>
  //       </CardMedia>
  //     </CardActionArea>
  //   </Card>
  // );
  
  return (
    <Card
      className={classes.root}
      onClick={handleExpandClick}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}>
            <MenuBookOutlinedIcon />
          </Avatar>
        }
        subheader={dateHandler(date)}
        // title={author}
      />
      <CardMedia
        className={classes.media}
        image={!img ? no_image : img}
      />
      <CardContent>
        <Typography
          color="textSecondary"
          component="h2"
          variant="h5">
          {title}
        </Typography>
        <Typography
          color="textSecondary"
          component="p"
          variant="body2">
          {description.length <= description.slice(0,53).length ? description : `${description.slice(0,53)}...`}
        </Typography>
      </CardContent>
      {/* <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit>
        <CardContent>
          <Typography paragraph>{description}</Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid
} from '@material-ui/core';
import gradients from '../../../../utils/gradients';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';

// eslint-disable-next-line react/prop-types
export const CounterCardView = ({auto, name, serial, night_consumption, day_consumption, flag, date}) => {
  const shadowCardHandler = shadow => {
    if (shadow) return '0 0 20px rgba(132, 190, 65, 0.5)';
    return '0 0 20px rgba(255, 159, 146, 0.5)'
  };

  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: 0,
    },
    card: {
      height: '100%',
      boxShadow: shadowCardHandler(flag)
    },
    header: {
      paddingBottom: 0,
    },
    auto: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 0'
    },
    metrics: {
      display: 'inline-flex',
      alignItems: 'flex-end'
    },
    flow: {
      textAlign: 'center',
    },
    avatar: {
      backgroundImage: gradients.green,
    },
  }));

  const classes = useStyles();

  return (
    <Card
      className={classes.card}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
          >
            <FlashOnOutlinedIcon />
          </Avatar>
        }
        className={classes.header}
        title={
          <Typography
            color="textSecondary"
            variant="h5"
          >
              Электроэнергия. Потребление
          </Typography>
        }
      />
      <CardContent
        className={classes.root}
      >
        <Grid
          container
          justify="center"
          spacing={2}
        >
          <Grid
            className={classes.flow}
            item
            xs={12}
          >
            <Typography
              color="secondary"
              variant="h4"
            >{name}</Typography>
          </Grid>
          <Grid
            className={classes.flow}
            item
            xs={12}
          >
            <Typography variant="body1">Счетчик №{serial}</Typography>
          </Grid>
          {
            auto ?
              <>
                <Grid
                  className={classes.auto}
                  item
                  xs={12}
                >
                  <Typography variant="h5">День: </Typography>
                  <span className={classes.metrics}>
                    <Typography variant="h2">{day_consumption}&nbsp;</Typography>
                    <Typography variant="body1">кВт·ч</Typography>
                  </span>
                </Grid>
                <Grid
                  className={classes.auto}
                  item
                  xs={12}
                >
                  <Typography variant="h5">Ночь: </Typography>
                  <span className={classes.metrics}>
                    <Typography variant="h2">{night_consumption}&nbsp;</Typography>
                    <Typography variant="body1">кВт·ч</Typography>
                  </span>
                </Grid>
                <Typography
                  className={`${flag ? 'textGreen' : 'textRed'} pt40 textCenter`}
                  variant="body1"
                >
                  {flag ?
                    `Ваше потребление среднее или ниже среднего за ${new Date(date).toLocaleDateString('ru')}` :
                    `Ваше потребление выше среднего по садоводству за ${new Date(date).toLocaleDateString('ru')}`}
                </Typography>
              </> : null
              // <>
              //   <Grid
              //     className={classes.auto}
              //     item
              //     xs={12}
              //   >
              //     <Typography variant="h5">День: </Typography>
              //     <span className={classes.metrics}>
              //       {day ?
              //         <>
              //           <Typography variant="h2">{day}&nbsp;</Typography>
              //           <Typography variant="body1">кВт·ч</Typography>
              //         </> :
              //         <Typography variant="body1">нет данных</Typography>
              //       }
              //     </span>
              //   </Grid>
              //   <Grid
              //     className={classes.auto}
              //     item
              //     xs={12}
              //   >
              //     <Typography variant="h5">Ночь: </Typography>
              //     <span className={classes.metrics}>
              //       {night ?
              //         <>
              //           <Typography variant="h2">{night}&nbsp;</Typography>
              //           <Typography variant="body1">кВт·ч</Typography>
              //         </> :
              //         <Typography variant="body1">нет данных</Typography>
              //       }
              //     </span>
              //   </Grid>
              // </>
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

/* eslint-disable no-undef */
import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

import HomePage from './views/Home';
import NewsSettings from './views/NewsSettings';
import AddNews from './views/AddNews';
import NewsPage from './views/NewsPage';
import PlotsPage from './views/PlotsPage';
import CreatePage from './views/CreatePage';
import Settings from './views/Settings';
import SocialFeed from './views/SocialFeed';
import Profile from './views/Profile';
import DashboardDefaultView from './views/DashboardDefault';
import DashboardGroup from './views/DashboardGroup';
import Error404 from './views/Errors/Error404';
import Error500 from './views/Errors/Error500';
import Login from './views/Login';
import Reset from './views/Reset';
import Register from './views/Register';
import { GardenerPage } from './views/GardenerPage/GardenerPage';
import { DashboardCounter } from './views/DashboardCounter/DashboardCounter';
import AuthVideo from './views/AuthVideo';
import TestPage from './views/TestPage';
import DocumentsPage from './views/DocumentsPage';
import VideosPage from './views/VideosPage';

const NODE_ENV = process.env.NODE_ENV;

export const useRoutes = (isAuthenticated, isPassChanged) => {
  if (isAuthenticated) {
    if (!isPassChanged) {
      return (
        <Switch>
          <Route exact path="/settings/:tab">
            <DashboardLayout>
              <Settings isPassChanged={isPassChanged} />
            </DashboardLayout>
          </Route>
          <Redirect to="/settings/general" />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route exact path="/">
          <DashboardLayout>
            <HomePage />
          </DashboardLayout>
        </Route>
        <Route exact path="/plots">
          <DashboardLayout>
            <PlotsPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/plot/configure/:tab">
          <DashboardLayout>
            <CreatePage />
          </DashboardLayout>
        </Route>
        <Route exact path="/plot/:id/configure/:tab">
          <DashboardLayout>
            <CreatePage />
          </DashboardLayout>
        </Route>
        <Route exact path="/settings/:tab">
          <DashboardLayout>
            <Settings />
          </DashboardLayout>
        </Route>
        <Route exact path="/dashboards/default/:tab">
          <DashboardLayout>
            <DashboardDefaultView />
          </DashboardLayout>
        </Route>
        <Route exact path="/dashboards/:id/:tab/:auto?">
          <DashboardLayout>
            <DashboardCounter />
          </DashboardLayout>
        </Route>
        <Route exact path="/dashboards/groups">
          <DashboardLayout>
            <DashboardGroup />
          </DashboardLayout>
        </Route>
        <Route exact path="/social-feed">
          <DashboardLayout>
            <SocialFeed />
          </DashboardLayout>
        </Route>
        <Route exact path="/profile/:id">
          <DashboardLayout>
            <Profile />
          </DashboardLayout>
        </Route>
        <Route exact path="/profile/:id/:tab">
          <DashboardLayout>
            <Profile />
          </DashboardLayout>
        </Route>
        <Route exact path="/garden/:tab">
          <DashboardLayout>
            <GardenerPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/newssettings/:tab">
          <DashboardLayout>
            <NewsSettings />
          </DashboardLayout>
        </Route>
        <Route exact path="/new/configure/:tab">
          <DashboardLayout>
            <AddNews />
          </DashboardLayout>
        </Route>
        <Route exact path="/new/:id/configure/:tab">
          <DashboardLayout>
            <AddNews />
          </DashboardLayout>
        </Route>
        <Route exact path="/newspage/:id">
          <DashboardLayout>
            <NewsPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/documents">
          <DashboardLayout>
            <DocumentsPage />
          </DashboardLayout>
        </Route>
        <Route exact path="/videos">
          <DashboardLayout>
            <VideosPage />
          </DashboardLayout>
        </Route>

        <Route exact path="/video/oauth/success">
          <DashboardLayout>
            <AuthVideo />
          </DashboardLayout>
        </Route>
        {NODE_ENV === 'development' && (
          <Route exact path="/testpage">
            <DashboardLayout>
              <TestPage />
            </DashboardLayout>
          </Route>
        )}

        <Route exact path="/error-404">
          <ErrorLayout>
            <Error404 />
          </ErrorLayout>
        </Route>
        <Route exact path="/error-500">
          <ErrorLayout>
            <Error500 />
          </ErrorLayout>
        </Route>
        <Redirect to="/error-404" />
      </Switch>
    );
  }

  return (
    <AuthLayout>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/reset">
          <Reset />
        </Route>
        <Route
          exact
          path="/new/gardener-set-user-28765428671490/register/during">
          <Register />
        </Route>
        <Redirect to="/" />
      </Switch>
    </AuthLayout>
  );

  //   {
  //     path: '/',
  //       exact
  //   :
  //     true,
  //       component
  //   :
  //     () => <Redirect to="/home"/>
  //   }
  // ,
  //   {
  //     path: '/auth',
  //       component
  //   :
  //     AuthLayout,
  //       routes
  //   :
  //     [
  //       {
  //         path: '/auth/login',
  //         exact: true,
  //         component: lazy(() => import('views/Login'))
  //       },
  //       {
  //         path: '/auth/register',
  //         exact: true,
  //         component: lazy(() => import('views/Register'))
  //       },
  //       {
  //         component: () => <Redirect to="/errors/error-404"/>
  //       }
  //     ]
  //   }
  // ,
  //   {
  //     route: '*',
  //       component
  //   :
  //     DashboardLayout,
  //       routes
  //   :
  //     [
  //       // {
  //       //   path: '/calendar',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/Calendar'))
  //       // },
  //       // {
  //       //   path: '/changelog',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/Changelog'))
  //       // },
  //       // {
  //       //   path: '/chat',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/Chat'))
  //       // },
  //       // {
  //       //   path: '/chat/:id',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/Chat'))
  //       // },
  //       // {
  //       //   path: '/dashboards/analytics',
  //       //   exact: true,
  //       //   component: DashboardAnalyticsView
  //       // },
  //       // {
  //       //   path: '/invoices/:id',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/InvoiceDetails'))
  //       // },
  //       // {
  //       //   path: '/kanban-board',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/KanbanBoard'))
  //       // },
  //       // {
  //       //   path: '/mail',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/Mail'))
  //       // },
  //       // {
  //       //   path: '/management/customers',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/CustomerManagementList'))
  //       // },
  //       // {
  //       //   path: '/management/customers/:id',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/CustomerManagementDetails'))
  //       // },
  //       // {
  //       //   path: '/management/customers/:id/:tab',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/CustomerManagementDetails'))
  //       // },
  //       // {
  //       //   path: '/management/projects',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/ProjectManagementList'))
  //       // },
  //       // {
  //       //   path: '/management/orders',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/OrderManagementList'))
  //       // },
  //       // {
  //       //   path: '/management/orders/:id',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/OrderManagementDetails'))
  //       // },
  //       // {
  //       //   path: '/overview',
  //       //   exact: true,
  //       //   component: OverviewView
  //       // },
  //       // {
  //       //   path: '/projects/create',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/ProjectCreate'))
  //       // },
  //       // {
  //       //   path: '/projects/:id',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/ProjectDetails'))
  //       // },
  //       // {
  //       //   path: '/projects/:id/:tab',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/ProjectDetails'))
  //       // },
  //       // {
  //       //   path: '/projects',
  //       //   exact: true,
  //       //   component: lazy(() => import('views/ProjectList'))
  //       // },
  //
  //       {
  //         path: '/errors',
  //         component: ErrorLayout,
  //         routes: [
  //           {
  //             path: '/errors/error-401',
  //             exact: true,
  //             component: lazy(() => import('views/Errors/Error401'))
  //           },
  //           {
  //             path: '/errors/error-404',
  //             exact: true,
  //             component: lazy(() => import('views/Errors/Error404'))
  //           },
  //           {
  //             path: '/errors/error-500',
  //             exact: true,
  //             component: lazy(() => import('views/Errors/Error500'))
  //           },
  //           {
  //             component: () => <Redirect to="/errors/error-404"/>
  //           }
  //         ]
  //       },
  //       {
  //         component: () => <Redirect to="/errors/error-404"/>
  //       }
  //     ]
  //   }
};

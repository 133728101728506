import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';

import {PerformanceOverTime} from '../PerformanceOverTime/PerformanceOverTime';
import {CounterCard} from '../CounterCard/CounterCard';
import {CounterCardView} from '../CounterCardView/CounterCardView';
import {TariffsCard} from '../TariffsCard/TariffsCard';
import {DashboardTable} from '../DashboardTable/DashboardTable';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  },
  config: {
    marginBottom: 5
  }
}));

export const DashboardElectric = () => {
  const user = useSelector(state => state.system.USER_DATA)
  const classes = useStyles();

  const [widgets, setWidgets] = useState([]);

  const getWidgetDataHandler = useCallback(async () => {
    axios.post('/counters/widget', {id: user.id})
      .then(r => r.data)
      .then(data => {
        if (!data.status) return;

        const {result} = data;
        setWidgets(result)
      })
  }, [axios, user]);

  useEffect(() => {
    getWidgetDataHandler()
  }, [getWidgetDataHandler]);
  return (
    <Grid
      className={classes.container}
      container
      spacing={2}
    >
      {
        widgets.map(widget => (
          <Grid
            item
            key={Math.random()}
            lg={4}
            sm={6}
            xs={12}
          >
            <CounterCard
              auto={widget.auto}
              counter={widget.id}
              date={widget.state || widget.date}
              day={widget.energy_tariff1}
              name={widget.name}
              night={widget.energy_tariff2}
              serial={widget.serial}
              update={getWidgetDataHandler}
            />
          </Grid>
        ))
      }
      {
        widgets.map(widget => (
          widget.auto === 1 &&
          <Grid
            item
            key={Math.random()}
            lg={4}
            sm={6}
            xs={12}
          >
            <CounterCardView
              auto={widget.auto}
              counter={widget.id}
              date={widget.date}
              day={widget.energy_tariff1}
              day_consumption={widget.day_consumption}
              flag={(+widget.avg_day_consumption + +widget.avg_night_consumption) >= (+widget.day_consumption + +widget.night_consumption)}
              name={widget.name}
              night={widget.energy_tariff2}
              night_consumption={widget.night_consumption}
              serial={widget.serial}
            />
          </Grid>
        ))
      }
      <Grid
        item
        lg={4}
        sm={6}
        xs={12}
      >
        <TariffsCard/>
      </Grid>
      <Grid
        item
        lg={12}
        xs={12}
      >
        <PerformanceOverTime />
      </Grid>
      <Grid
        className="fix-table-toolbar"
        item
        lg={12}
        xs={12}
      >
        <DashboardTable />
      </Grid>
    </Grid>
  );
};

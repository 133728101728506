import React from 'react';

import { DeviceDetails } from '../DeviceDetails/DeviceDetails';

export const DeviceWrapper = ({ arr, inputFormHandler }) => {
  return arr.map((device) => {
    return (
      <DeviceDetails
        checkLabel
        house={device.address_house}
        id={device.id}
        inputFormHandler={inputFormHandler}
        isChecked={device.checked}
        key={device.id}
        name={device.name}
        number={device.serial}
        street={device.address_street}
        type={device.id_type_name}
      />
    );
  });
};

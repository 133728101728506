import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { emphasize, withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Chip } from '@material-ui/core';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300]
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    }
  }
}))(Chip);

// eslint-disable-next-line no-unused-vars
export const BreadcrumbsSetup = ({ location }) => {
  const history = useHistory();
  const params = useParams();

  const handleClick = (path) => {
    history.push(path);
  };

  const breadcrumbsHandler = () => {
    switch (location) {
      case '/plots':
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" href="#" label="Участки" />
          </Breadcrumbs>
        );
      case `/plot/${params.id}/configure/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Участки"
              onClick={() => handleClick('/plots')}
            />
            <StyledBreadcrumb component="span" label="Редактирование" />
          </Breadcrumbs>
        );
      case `/plot/configure/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Участки"
              onClick={() => handleClick('/plots')}
            />
            <StyledBreadcrumb component="span" label="Создание" />
          </Breadcrumbs>
        );
      case `/dashboards/default/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" label="Информация" />
          </Breadcrumbs>
        );
      case `/garden/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" label="Садоводство" />
          </Breadcrumbs>
        );
      case '/documents':
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" label="Документы" />
          </Breadcrumbs>
        );
      case '/videos':
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" label="Видеонаблюдение" />
          </Breadcrumbs>
        );
      case `/dashboards/${params.id}/${params.tab}${
        params.auto ? '/auto' : ''
      }`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Показания"
              onClick={() => handleClick('/dashboards/default/electric')}
            />
            <StyledBreadcrumb component="span" label="Подробнее" />
          </Breadcrumbs>
        );
      case '/dashboards/groups':
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Показания"
              onClick={() => handleClick('/dashboards/default/electric')}
            />
            <StyledBreadcrumb component="span" label="Управление группами" />
          </Breadcrumbs>
        );
      case `/newssettings/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" label="Новости" />
          </Breadcrumbs>
        );
      case `/new/${params.id}/configure/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Новости"
              onClick={() => handleClick('/newssettings/list')}
            />
            <StyledBreadcrumb component="span" label="Редактирование" />
          </Breadcrumbs>
        );
      case `/new/configure/${params.tab}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Новости"
              onClick={() => handleClick('/newssettings/list')}
            />
            <StyledBreadcrumb component="span" label="Создание" />
          </Breadcrumbs>
        );
      case `/newspage/${params.id}`:
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="span"
              label="Главная"
              onClick={() => handleClick('/')}
            />
            <StyledBreadcrumb component="span" label="Новость" />
          </Breadcrumbs>
        );
      case '/social-feed':
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb component="span" label="Новости" />
          </Breadcrumbs>
        );
      default:
        return;
    }
  };

  return breadcrumbsHandler();
};

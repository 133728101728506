import React from 'react';
import { Page } from 'components';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { PaddingControl } from '../../hooks/padding.hook';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  results: {
    marginTop: theme.spacing(2)
  }
}));

// eslint-disable-next-line react/prop-types
const Aux = ({ name = null, title = null, description = null, children = null }) => {
  const classes = useStyles();

  return (
    <PaddingControl>
      <Page
        className={classes.root}
        title={name}
      >
        <PageHeader
          description={description}
          title={title}
        />
        {children}
      </Page>
    </PaddingControl>
  );
};

export default Aux;

import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import clear from '../../../resources/images/weather/clear.svg'
import cloudy from '../../../resources/images/weather/cloudy.svg'
import cloudy_and_light_rain from '../../../resources/images/weather/cloudy-and-light-rain.svg'
import cloudy_and_light_snow from '../../../resources/images/weather/cloudy-and-light-snow.svg'
import cloudy_and_rain from '../../../resources/images/weather/cloudy-and-rain.svg'
import cloudy_and_snow from '../../../resources/images/weather/cloudy-and-snow.svg'
import overcast from '../../../resources/images/weather/overcast.svg'
import overcast_and_light_rain from '../../../resources/images/weather/overcast-and-light-rain.svg'
import overcast_and_light_snow from '../../../resources/images/weather/overcast-and-light-snow.svg'
import overcast_and_rain from '../../../resources/images/weather/overcast-and-rain.svg'
import overcast_and_snow from '../../../resources/images/weather/overcast-and-snow.svg'
import overcast_and_wet_snow from '../../../resources/images/weather/overcast-and-wet-snow.svg'
import overcast_thunderstorms_with_rain from '../../../resources/images/weather/overcast-thunderstorms-with-rain.svg'
import partly_cloudy from '../../../resources/images/weather/partly-cloudy.svg'
import partly_cloudy_and_light_rain from '../../../resources/images/weather/partly-cloudy-and-light-rain.svg'
import partly_cloudy_and_light_snow from '../../../resources/images/weather/partly-cloudy-and-light-snow.svg'
import partly_cloudy_and_rain from '../../../resources/images/weather/partly-cloudy-and-rain.svg'
import partly_cloudy_and_snow from '../../../resources/images/weather/partly-cloudy-and-snow.svg'

// eslint-disable-next-line react/prop-types
export const WeatherIcon = ({ icon }) => {
  if (!icon) return null;

  // eslint-disable-next-line react/prop-types
  const img = icon.split('-').join('_');
  let image;
  let conditions;
  

  switch (img) {
    case 'clear':
      image = clear;
      conditions = 'Ясно';
      break;
    case 'cloudy':
      image = cloudy;
      conditions = 'Облачно';
      break;
    case 'cloudy_and_light_rain':
      image = cloudy_and_light_rain;
      conditions = 'Облачно и мелкий дождь';
      break;
    case 'cloudy_and_light_snow':
      image = cloudy_and_light_snow;
      conditions = 'Облачно и снег';
      break;
    case 'cloudy_and_rain':
      image = cloudy_and_rain;
      conditions = 'Облачно и дождь';
      break;
    case 'cloudy_and_snow':
      image = cloudy_and_snow;
      conditions = 'Облачно и снег';
      break;
    case 'overcast':
      image = overcast;
      conditions = 'Пасмурно';
      break;
    case 'overcast_and_light_rain':
      image = overcast_and_light_rain;
      conditions = 'Пасмурно и мелкий дождь';
      break;
    case 'overcast_and_light_snow':
      image = overcast_and_light_snow;
      conditions = 'Пасмурно и снег';
      break;
    case 'overcast_and_rain':
      image = overcast_and_rain;
      conditions = 'Пасмурно и дождь';
      break;
    case 'overcast_and_snow':
      image = overcast_and_snow;
      conditions = 'Пасмурно и снег';
      break;
    case 'overcast_and_wet_snow':
      image = overcast_and_wet_snow;
      conditions = 'Пасмурно и снег с дождем';
      break;
    case 'overcast_thunderstorms_with_rain':
      image = overcast_thunderstorms_with_rain;
      conditions = 'Пасмурно и дождь с грозой';
      break;
    case 'partly_cloudy':
      image = partly_cloudy;
      conditions = 'Переменная облачность';
      break;
    case 'partly_cloudy_and_light_rain':
      image = partly_cloudy_and_light_rain;
      conditions = 'Переменная облачность с мелким дождем';
      break;
    case 'partly_cloudy_and_light_snow':
      image = partly_cloudy_and_light_snow;
      conditions = 'Переменная облачность со снегом';
      break;
    case 'partly_cloudy_and_rain':
      image = partly_cloudy_and_rain;
      conditions = 'Переменная облачность с дождем';
      break;
    case 'partly_cloudy_and_snow':
      image = partly_cloudy_and_snow;
      conditions = 'Переменная облачность со снегом';
      break;
    default:
      image = clear;
      conditions = 'Ясно';
      break
  }

  return (
    <Tooltip title={conditions} >
      <img
        alt={conditions}
        className="WeatherIcon"
        src={image}
      />
    </Tooltip>
  )
};
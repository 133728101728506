import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  ListItemIcon,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  DialogTitle as MuiDialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

import { useHttp } from '../../../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    paddingRight: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  progress: {
    width: '100%'
  }
});

export const ZipPdf = ({ open, setOpen, count, onClose }) => {
  const classes = useStyles();

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const currentDate = new Date();
  const [selectedDate, handleDateChange] = useState(
    new Date(currentDate.setMonth(currentDate.getMonth() - 1))
  );

  const { request, loading } = useHttp();
  const user = useSelector((state) => state.system.USER_DATA);
  const { enqueueSnackbar } = useSnackbar();

  const [progress, setProgress] = React.useState(0);
  const timeForOnePdf = 310;
  const maxProgress = count * 0.9;
  const download = (pdfUrl) => {
    const link = document.createElement('a');
    link.href = '/' + pdfUrl;
    link.setAttribute(
      'download',
      pdfUrl.split('/')[pdfUrl.split('/').length - 1]
    );
    link.click();
  };

  const getOffset = () => {
    return (
      selectedDate.getMonth() -
      (new Date().getMonth() - 1) -
      (new Date().getFullYear() - selectedDate.getFullYear()) * 12
    );
  };
  const createPdf = async () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress == maxProgress) {
          clearInterval(timer);
          return oldProgress;
        }
        return Math.min(oldProgress + 1, maxProgress);
      });
    }, timeForOnePdf);
    const result = await request('/create/pdf', 'POST', {
      id: user.group,
      offset: getOffset()
    });
    clearInterval(timer);
    if (!result.status) {
      return enqueueSnackbar('Что-то пошло не так...', {
        variant: 'error'
      });
    }
    getData(timer);
  };
  const getData = async () => {
    setProgress(() => maxProgress);
    const result = await request('/get/pdf', 'POST', {
      id: user.group,
      year: selectedDate.getFullYear(),
      month: selectedDate.getMonth() + 1
    });
    setProgress(() => 0);
    if (!result.status) {
      return enqueueSnackbar(result.message || 'Что-то пошло не так...', {
        variant: 'error'
      });
    }
    const createdLink = result.result.split('/').slice(2);
    download(createdLink.join('/'));
  };
  return (
    <>
      <div
        className="AlignCenter"
        onClick={setOpen}>
        <ListItemIcon>
          {count <= 0 ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <ReceiptIcon fontSize="small" />
            </>
          )}
        </ListItemIcon>
        <Typography noWrap variant="inherit">
          Выгрузить Квитанции
        </Typography>
      </div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={onClose}
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Выбрать за какой период загрузить квитанции
        </DialogTitle>
        <DialogContent>
          <DatePicker
            label="Год и месяц"
            maxDate={currentDate}
            // className="DashboardDatepicker"
            minDate={new Date('2019-08-01')}
            onChange={handleDateChange}
            value={selectedDate}
            views={['year', 'month']}
          />
        </DialogContent>
        <DialogActions>
          {loading || progress > 0 ? (
            <>
              <LinearProgress
                className={classes.progress}
                value={Math.round((progress / count) * 100)}
                variant="determinate"
              />
              <Button
                color="secondary"
                disabled
                type="submit"
                variant="contained">
                {Math.round((progress / count) * 100)}%
              </Button>
            </>
          ) : (
            <Button
              color="secondary"
              onClick={createPdf}
              type="submit"
              variant="contained">
              Выгрузить
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
ZipPdf.propTypes = {
  loading: PropTypes.bool,
  result: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux'

import {
  Card,
  Collapse,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {SwitchHandler} from '../../../../../../components/Switcher/Switcher';
import {DatePicker} from '@material-ui/pickers';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {useHttp} from '../../../../../../hooks/http.hook';
import {useParams} from 'react-router';
import {getCounters, updateCounter} from '../../../../../../redux/actions';
import {initialState} from './CountersInitial'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  form: {
    paddingTop: 20,
    paddingBottom: 30
  },
  select: {
    width: '100%'
  },
  control: {
    height: 53,
    marginLeft: 0
  },
  container: {
    height: 'fit-content'
  },
  save: {
    marginLeft: '-24px'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 16
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const mapStateToProps = state => {
  return {
    user: state.system.USER_DATA,
    counters: state.counters.counters,
    loading: state.counters.loading
  }
}

const mapDispatchToProps = {
  getCounters,
  updateCounter
};

// eslint-disable-next-line react/prop-types
export const CountersAdd = connect(mapStateToProps, mapDispatchToProps)(({trigger, user, counters, getCounters, open, close, flag, clear}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {request} = useHttp();
  const {id} = useParams();

  const [form, setForm] = useState({
    name: '',
    auto: 0,
    serial: '',
    add_date: null,
    // check_date: null,
    init_tariff1: '',
    init_tariff2: '',
    model: '',
    count_tariffs: 0,
    count_phases: 0,
    group: user.group,
    user_id: user.id,
    parcel: id
  });

  const inputFormHandler = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const dateFormHandler = (e, name) => {
    setForm({...form, [name]: e});
  };

  const checkerFormHandler = e => {
    setForm({ ...form, [e.target.name]: e.target.checked ? 1 : 0 });
  };

  const handleSubmit = e => {
    e.preventDefault();
    sentCounterAllData()
  }

  const sentCounterAllData = useCallback(async () => {
    if (!trigger) {
      const add_date = form.add_date === null || form.add_date === '' ? new Date() : form.add_date;
      const data = await request('/parcel/add/counters', 'POST', {...form, add_date: add_date});

      if (data.result === 'exist') {
        const status = 'error';
        const message = data.status ? 'Такой счетчик уже существует' : 'Что-то пошло не так...'
        enqueueSnackbar(message, { variant: status })
      } else {
        const status = data.status ? 'success' : 'error';
        const message = data.status ? 'Счетчик успешно добавлен' : 'Что-то пошло не так...'
        enqueueSnackbar(message, { variant: status })
      }
    } else {
      const data = await request('/parcel/update/counters', 'POST', form);

      const status = data.status ? 'success' : 'error';
      const message = data.status ? 'Счетчик успешно обновлен' : 'Что-то пошло не так...'
      enqueueSnackbar(message, { variant: status })
    }

    getCounters(form.parcel);
    setTimeout(() => {
      close();
      clear();
    }, 100);
  }, [form, close, clear, enqueueSnackbar, getCounters, request, trigger]);

  useEffect(() => {
    if (flag === 'new') {
      return setForm({...form, ...initialState})
    }

    if (trigger) {

      const counter = counters.filter(counter => counter.id === trigger)[0]

      Object.keys(counter).map(idx => {
        if (idx === 'count_phases' || idx === 'count_tariffs') {
          if (counter[idx] === null) {
            counter[idx] = '0'
          } else {
            counter[idx] = counter[idx].toString()
          }
        } else if (idx === 'model') {
          if (counter[idx] === null) {
            counter[idx] = ''
          }
        }
        return true;
      })

      setForm({...form, ...counter})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, flag])

  return (
    <Card className={classes.root}>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={3}
              md={4}
              xs={12}
            >
              <FormControl
                className={`${classes.select} NativeSelect`}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-role-native-simple">Вид прибора учета</InputLabel>
                <Select
                  disabled={user.role !== 'CHAIRMAN'}
                  inputProps={{
                    name: 'name',
                  }}
                  label="Вид прибора учета"
                  name="name"
                  native
                  onChange={inputFormHandler}
                  required
                  value={form.name}
                >
                  <option
                    disabled
                    value=""
                  />
                  <option value="electric">Электричество</option>
                  <option value="water">Вода</option>
                  <option value="gas">Газ</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <FormControlLabel
                className={classes.control}
                control={(
                  <SwitchHandler
                    checked={!!form.auto}
                    disabled={user.role !== 'CHAIRMAN'}
                    name="auto"
                    onChange={checkerFormHandler}
                  />
                )}
                label="Сбор показаний автоматический"
              />
            </Grid>
          </Grid>
          <CardActions />
          <Grid
            container
            spacing={2}
          >
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}
            >
              {
                !!form.auto &&
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    disabled={user.role !== 'CHAIRMAN'}
                    fullWidth
                    label="Серийтный №"
                    name="serial"
                    onChange={inputFormHandler}
                    required
                    value={form.serial}
                    variant="outlined"
                  />
                </Grid>
              }
              <Grid
                item
                xs={12}
              >
                <TextField
                  disabled={user.role !== 'CHAIRMAN'}
                  fullWidth
                  label="Модель"
                  name="model"
                  onChange={inputFormHandler}
                  required
                  value={form.model}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <DatePicker
                  autoOk
                  cancelLabel={'Отмена'}
                  disabled={user.role !== 'CHAIRMAN'}
                  disableFuture
                  format="dd.MM.yyyy"
                  fullWidth
                  inputVariant="outlined"
                  label="Дата установки"
                  name="add_date"
                  onChange={e => dateFormHandler(e, 'add_date')}
                  value={form.add_date}
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
              >
                <DatePicker
                  autoOk
                  cancelLabel={'Отмена'}
                  disabled={user.role !== 'CHAIRMAN'}
                  format="dd.MM.yyyy"
                  fullWidth
                  inputVariant="outlined"
                  label="Дата поверки"
                  minDate={new Date()}
                  name="check_date"
                  onChange={e => dateFormHandler(e, 'check_date')}
                  value={form.check_date}
                />
              </Grid> */}
            </Grid>
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  disabled={user.role !== 'CHAIRMAN'}
                  fullWidth
                  label="Начальные данные (день)"
                  name="init_tariff1"
                  onChange={inputFormHandler}
                  required
                  value={form.init_tariff1}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  disabled={user.role !== 'CHAIRMAN'}
                  fullWidth
                  label="Начальные данные (ночь)"
                  name="init_tariff2"
                  onChange={inputFormHandler}
                  required
                  value={form.init_tariff2}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue="top"
                    disabled={user.role !== 'CHAIRMAN'}
                    name="count_tariffs"
                    onChange={inputFormHandler}
                    row
                    value={form.count_tariffs.toString()}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      disabled={user.role !== 'CHAIRMAN'}
                      label="1-тариф"
                      value="0"
                    />
                    <FormControlLabel 
                      control={<Radio color="primary" />} 
                      disabled={user.role !== 'CHAIRMAN'}
                      label="2х-тариф"
                      value="1"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue="top"
                    name="count_phases"
                    onChange={inputFormHandler}
                    row
                    value={form.count_phases.toString()}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      disabled={user.role !== 'CHAIRMAN'}
                      label="1-фазный"
                      value="0"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      disabled={user.role !== 'CHAIRMAN'}
                      label="3х-фазный"
                      value="1"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {
            user.role === 'CHAIRMAN' &&
            <CardActions>
              <Button
                className={classes.save}
                color="secondary"
                type="submit"
                variant="contained"
              >
                Сохранить
              </Button>
            </CardActions>
          }
        </form>
      </Collapse>
    </Card>
  )
});

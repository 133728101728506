import {combineReducers} from 'redux';
import {chartReducer} from './chartReducer';
import {systemReducer} from './systemReducer';
import {countersReducer} from './countersReducer';
import {plotsReducer} from './plotsReducer';
import {weatherReducer} from './weatherReducer';
import {themeReducer} from './themeReducer';

export const rootReducer = combineReducers({
  system: systemReducer,
  chart: chartReducer,
  counters: countersReducer,
  plots: plotsReducer,
  weather: weatherReducer,
  theme: themeReducer
});

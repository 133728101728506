import React from 'react';

import { SocialProfile } from '../../../../../../components/UserProfiles/SocialProfile';
import { ContactProfile } from '../../../../../../components/UserProfiles/ContactProfile';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

// eslint-disable-next-line react/prop-types
export const PlotUserDetails = ({place, part, name, surname, patronymic, email, phone, owner, watsapp, viber, telegram, skype, vk, instagram}) => {
  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      display: place === 'expansion' ? 'flex' : 'inline-flex'
    },
    green: {
      backgroundColor: '#84be41'
    }
  }));

  const classes = useStyles();

  // eslint-disable-next-line react/no-multi-comp
  const primaryHandler = (percent) => {
    return (
      <span className="containerCol">
        <span>Собственник</span>
        <span>Доля {percent}%</span>
      </span>
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const primaryExpansionHandler = () => {
    return <span className="ExpansionRole">Пользователь</span>;
  };

  // eslint-disable-next-line react/no-multi-comp
  const secondaryHandler = (owner = false, name = null, surname = null) => {
    return (
      <span className="containerCol">
        <span>{name}</span>
        <span>{surname}</span>
        {owner && <span className="textGreen">контактное лицо</span>}
      </span>
    );
  };

  const avatarLetterHandler = () => {
    // eslint-disable-next-line react/prop-types
    if (surname) return surname.split('')[0].toUpperCase();
    // eslint-disable-next-line react/prop-types
    if (name) return name.split('')[0].toUpperCase();
    // eslint-disable-next-line react/prop-types
    if (patronymic) return patronymic.split('')[0].toUpperCase();
    return 'A';
  };

  return (
    <div>
      <List className={`${classes.root} ${place ? ' MuiMinimize' : ''}`}>
        <ListItem className="fixedInfoWidth">
          <ListItemAvatar>
            <Avatar className={classes.green}>{avatarLetterHandler()}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={surname}
            secondary={secondaryHandler(owner === 0, name, patronymic)}
          />
        </ListItem>
        <Divider
          flexItem
          orientation="vertical" />
        <ListItem className="fixedInfoWidth">
          <ListItemText
            className="ListItemTextMobile"
            primary={part ? primaryHandler(part) : primaryExpansionHandler()}
            secondary={!place ? secondaryHandler(true) : null}
          />
        </ListItem>
        <Divider
          flexItem
          orientation="vertical" />
        <ListItem
          className="fixedInfoWidth"
          onClick={(e) => e.stopPropagation()}>
          <ListItemText primary={ContactProfile(phone, email)} />
        </ListItem>
        <Divider
          flexItem
          orientation="vertical" />
        <ListItem
          className="fixedContactWidth"
          onClick={(e) => e.stopPropagation()}>
          <ListItemText
            primary={SocialProfile({
              watsapp,
              viber,
              telegram,
              skype,
              vk,
              instagram
            })}
          />
        </ListItem>
      </List>
    </div>
  );
};

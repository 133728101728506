import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import PropTypes from 'prop-types';

export const DeviceDetails = ({
  id,
  type,
  number,
  house,
  street,
  name,
  checkLabel = false,
  isChecked,
  inputFormHandler
}) => {
  const useStyles = makeStyles(() => ({
    avatar: {
      display: 'flex'
    },
    fl: {
      display: 'flex',
      minWidth: '100%',
      padding: '15px 0',
      '&:not(:first-child)': {
        borderTop: '1px solid #eeeeee'
      }
    }
  }));
  const classes = useStyles();
  const addressHelper = (street, house) => {
    if (!street && !house) return 'Не указан';

    street = street ? `ул. ${street} ` : '';
    house = house ? `участок ${house}` : '';
    return `${street}${house}`;
  };
  return (
    <div
      className={classes.fl}
      key={id}
    >
      <FormControlLabel
        control={
          <div className={classes.avatar}>
            <Avatar className="AvatarBg">
              {type === 1 && <FlashOnOutlinedIcon />}
            </Avatar>
          </div>
        }
        label=""
      />
      <>
        <Grid
          alignItems="center"
          container
          direction="row"
          spacing={2}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Typography
              component="p"
              variant="h5"
            >
              {name}
            </Typography>
            <Typography
              component="p"
              variant="overline"
            >
              <strong>Номер прибора: </strong>
              <Typography
                component="span"
                variant="h6"
              >
                {number}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Typography
              component="p"
              variant="overline"
            >
              <strong>Адрес: </strong>
              <Typography
                component="span"
                variant="h6"
              >
                {addressHelper(street, house)}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </>
      {checkLabel && (
        <FormControlLabel
          control={
            <Checkbox
              checked={!!isChecked}
              name={'devices'}
              onChange={inputFormHandler}
              value={id}
            />
          }
          label=""
        />
      )}
    </div>
  );
};
DeviceDetails.propTypes = {
  checkLabel: PropTypes.boolean,
  house: PropTypes.string,
  id: PropTypes.number,
  inputFormHandler: PropTypes.function,
  isChecked: PropTypes.boolean,
  name: PropTypes.string,
  number: PropTypes.string,
  street: PropTypes.string,
  type: PropTypes.number,
};

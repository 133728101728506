import React from 'react';
import { Hidden } from '@material-ui/core';

import styled from 'styled-components';

const MainLogo = styled.main`
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #2d323e;
    box-sizing: border-box;
    
    @media screen and (max-width: 1279px) {
        width: 100%;
        padding-right: 55px;
    }
    
    & img {
        height: 100%;
    }
    
    & span {
        margin-left: 20px;
        font-weight: 500;
    }
`;

export const Logo = () => {
  return (
    <MainLogo>
      <img
        alt="Главная"
        src="/images/logo.png"
      />
      <Hidden mdDown>
        <span>Садоводство</span>
      </Hidden>
    </MainLogo>
  )
}

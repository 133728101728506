import {CLEAR_FUNC, GET_PLOTS, REDIRECT_PLOTS, SET_PLOTS} from '../types';

const initialState = {
  id: '',
  snt: '',
  plots: [],
  users: [],
  plot: null,
  func: null,
  loading: false,
};

export const plotsReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_PLOTS:
      return {...state, id: action.payload, loading: true };
    case SET_PLOTS:
      return {...state, plots: action.payload, snt: action.payload[0].snt, loading: false};
    case REDIRECT_PLOTS:
      return {...state, func: action.payload}
    case CLEAR_FUNC:
      return {...state, func: null}
    default:
      return state;
  }
};

import React, {useState} from 'react';

import {TariffsCardEdit} from './TariffsCardEdit';
import {TariffsCardView} from './TarifsCardView';

// eslint-disable-next-line react/prop-types
export const TariffsCard = () => {
  const [setup, setSetup] = useState(false)
  const setSetupHandler = () => {
    setSetup(!setup)
  };

  return (
    setup ?
      <TariffsCardEdit trigger={setSetupHandler} /> :
      <TariffsCardView trigger={setSetupHandler} />
  );
};

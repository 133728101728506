export const USER_TOKEN = 'TOKEN';
export const USER_DATA = 'USER_DATA';

export const UPDATE_USER = 'UPDATE_USER';
export const SYSTEM_LOGIN = 'AUTH/SYSTEM_LOGIN';
export const SYSTEM_LOGOUT = 'AUTH/SYSTEM_LOGOUT';

export const GET_CHART = 'DASHBOARD/GET_CHART';
export const SET_CHART = 'DASHBOARD/SET_CHART';
export const CHANGE_DAY = 'DASHBOARD/CHANGE_DAY';
export const CHANGE_PERIOD = 'DASHBOARD/CHANGE_PERIOD';

export const GET_COUNTERS = 'PLOTS/GET_COUNTERS';
export const SET_COUNTERS = 'PLOTS/SET_COUNTERS';
export const UPDATE_COUNTER = 'PLOTS/UPDATE_COUNTER';
export const GET_PLOTS = 'PLOTS/GET_PLOTS';
export const SET_PLOTS = 'PLOTS/SET_PLOTS';
export const REDIRECT_PLOTS = 'PLOTS/REDIRECT_PLOTS';
export const CLEAR_FUNC = 'PLOTS/CLEAR_FUNC';

export const GET_WEATHER = 'ALL/GET_WEATHER';
export const SET_WEATHER = 'ALL/SET_WEATHER';

export const CHANGE_THEME = 'ALL/CHANGE_THEME';

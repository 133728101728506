import React, { useCallback } from 'react';
import { useSelector, connect } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
// import PropTypes from 'prop-types';

import { Page } from 'components';
import {
  General,
  SocialProfiles,
  // Notifications,
  Security
} from './components';
import { PaddingControl } from '../../hooks/padding.hook';
import { InteractiveHeader } from './components/InteractiveHeader/InteractiveHeader';

import { useHttp } from '../../hooks/http.hook';
import { useSnackbar } from 'notistack';
import { systemLogin } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(1)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(1)
  }
}));

const Settings = ({ isPassChanged, systemLogin }) => {
  const classes = useStyles();
  const { tab } = useParams();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { request } = useHttp();
  const user = useSelector((state) => state.system.USER_DATA);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'general', label: 'Основные' },
    { value: 'social', label: 'Социальные профили' },
    // { value: 'notifications', label: 'Уведомления' },
    { value: 'security', label: 'Безопасность' }
  ];

  const updateUserData = useCallback(async () => {
    const data = await request('/login', 'POST', {
      phone: user.phone,
      password: user.password
    });

    const status = data.status ? 'success' : 'error';

    if (!data.status)
      return enqueueSnackbar(data.message, {
        variant: status
      });

    const addrCheck =
      data.user.addrChecker === 1 || data.useraddrChecker === 'Y';
    const contCheck = data.user.contact === 1 || data.usercontact === 'Y';

    const fetchedData = {
      ...data.user,
      addrChecker: addrCheck,
      contact: contCheck
    };
    const newForm = {};

    Object.keys(fetchedData).map((item) => {
      if (fetchedData[item] !== null) {
        return (newForm[item] = fetchedData[item]);
      } else {
        return (newForm[item] = '');
      }
    });

    systemLogin({ TOKEN: data.token, USER_DATA: newForm });
    enqueueSnackbar('Сохранение успешно', { variant: status });
    if (localStorage.getItem('theme')) return;

    const theme = {
      theme: 'WHITE',
      check: true
    };
    localStorage.setItem('theme', JSON.stringify(theme));
  }, [request]);

  if (tab !== 'security' && isPassChanged === false) {
    return <Redirect to="/settings/security" />;
  }

  if (!tab) {
    return <Redirect to="/settings/general" />;
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/error-404" />;
  }

  return (
    <Page title="Настройки">
      {/*<Header />*/}
      <InteractiveHeader />
      <PaddingControl>
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          variant="scrollable">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value} />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === 'general' && <General updateUserData={updateUserData}/>}
          {tab === 'social' && (
            <SocialProfiles updateUserData={updateUserData} />
          )}
          {/*{tab === 'notifications' && <Notifications />}*/}
          {tab === 'security' && <Security isPassChanged={isPassChanged} />}
        </div>
      </PaddingControl>
    </Page>
  );
};

const mapDispatchToProps = {
  systemLogin
};

export default connect(null, mapDispatchToProps)(Settings);

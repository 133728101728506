import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getChart} from '../../../../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    padding: '5px 13px'
  }
}));

// eslint-disable-next-line react/prop-types
export const CounterViewSelect = ({tabs}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const chart = useSelector(state => state.chart);

  const changeSelectHandler = e => {
    dispatch(getChart({...chart, id: e.target.value, day: chart.check ? 'day' : 'night'}))
  }

  return (
    <FormControl
      className={`${classes.formControl} SelectChart`}
      variant="outlined"
    >
      <Select
        inputProps={{
          className: classes.select
        }}
        native
        onChange={changeSelectHandler}
        value={chart.id}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {tabs.map(option => (
          <option
            key={option.id}
            value={option.id}
          >
            {option.serial}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getChart } from '../../../../redux/actions';

import { Chart } from './components';
import { DateChartSelect } from './components/DayChartSelect/DayChartSelect';
import { CounterViewSelect } from './components/CounterSelect/CounterSelect';
import { ControlPanel } from '../ControlPanel/ControlPanel';
import { SwitchHandler } from '../../../../components/Switcher/Switcher';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormControlLabel
} from '@material-ui/core';
import { GroupingPanel } from '../GroupingPanel/GroupingPanel';

import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../../../hooks/http.hook';
import { dateHook } from '../../../../hooks/date.hook';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
}));

export const PerformanceOverTime = () => {
  const { request } = useHttp();
  const classes = useStyles();
  const options = useParams();
  const dispatch = useDispatch();
  const date = dateHook();
  const { id, group, role } = useSelector((state) => state.system.USER_DATA);
  const { check, day, format, data, select } = useSelector(
    (state) => state.chart
  );

  const [tabs, setTabs] = useState([]);
  const [params, setParams] = useState({
    id,
    group,
    role_check: role,
    chart: options.tab,
    format,
    day,
    check,
    select,
    period: date
  });

  const getDevicesHandler = useCallback(async () => {
    const response = await request('/counters/get_devices', 'POST', params);
    if (!response.status) return;

    const { result } = response;
    setTabs(result);
    dispatch(getChart({ ...params, id: result[0].id }));
  }, [request, params, dispatch]);

  const getChartData = useCallback(
    (tab = null, arr = null) => {
      if (role === 'CHAIRMAN') {
        dispatch(getChart(params));
      } else {
        const id = arr.filter((item) => item.serial === tab.textContent)[0].id;
        dispatch(getChart({ ...params, id }));
      }
    },
    [params, dispatch, role]
  );

  const changeDayHandler = (e) => {
    const flag = e.target.checked;
    setParams({ ...params, check: flag, select, day: flag ? 'day' : 'night' });
  };

  const changePeriodHandler = (e) => {
    setParams({ ...params, format: e, select });
  };

  const changeDateHandler = (date, name) => {
    setParams({
      ...params,
      period: {
        ...params.period,
        [name]: date
      }
    });
  };
  const changeGroupingdHandler = (e) => {
    setParams({ ...params, grouping: e.target.value });
  };

  // eslint-disable-next-line react/no-multi-comp
  const controlChartHandler = (roleCheck) => {
    if (roleCheck !== 'CHAIRMAN') return null;

    return (
      <FormControlLabel
        className={classes.control}
        control={
          <SwitchHandler
            checked={params.check}
            name="control"
            onChange={changeDayHandler}
          />
        }
        label={params.check ? 'День' : 'Ночь'}
      />
    );
  };

  useEffect(() => {
    if (role === 'CHAIRMAN') {
      getChartData();
    } else {
      getDevicesHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChartData, getDevicesHandler]);

  return (
    <>
      <ControlPanel
        period={params.period}
        // tabs={role !== 'CHAIRMAN' ? tabs : null}
        update={changeDateHandler}
      />
      {role === 'CHAIRMAN' && <GroupingPanel update={changeGroupingdHandler} />}
      <Card>
        <CardHeader
          action={
            <DateChartSelect
              update={changePeriodHandler}
              value={params.format}
            />
          }
          avatar={role !== 'CHAIRMAN' && <CounterViewSelect tabs={tabs} />}
          className="ChartHeader"
          title={controlChartHandler(role)}
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Chart
                className={classes.chart}
                data={data}
                labels={data.labels}
              />
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </>
  );
};

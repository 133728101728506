import React, { useEffect } from 'react';
import Hls from 'hls.js';

function HLSSource({ src, video, type, getLive }) {
  const hls = new Hls();

  // console.log('src :>> ', src);
  useEffect(() => {
    if (Hls.isSupported()) {
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // video.play();
      });
      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          console.log('data.type :>> ', data.type);
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              console.log('fatal network error encountered, try to recover');
              getLive();
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error encountered, try to recover');
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              hls.destroy();
              break;
          }
        }
      });
    }
    return () => {
      hls.destroy();
    };
  }, [src]);

  return <source
    src={src}
    type={type || 'application/x-mpegURL'} />;
}

export default HLSSource;

import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import CardActions from '@material-ui/core/CardActions';
import { useHttp } from '../../../../hooks/http.hook';
// import {useApi} from '../../../../hooks/api.hook';
import { useSnackbar } from 'notistack';
// import {initialStateSuggestion} from './FormInitialSug'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(() => ({
  container: {
    height: 'fit-content'
  }
}));

export const AddressPage = () => {
  const classes = useStyles();
  const { request } = useHttp();
  // const {api} = useApi();
  // const [sug, setSug] = useState(initialStateSuggestion);
  const { enqueueSnackbar } = useSnackbar();
  const { group, role } = useSelector((state) => state.system.USER_DATA);
  const isDisabled = role !== 'CHAIRMAN';
  const [form, setForm] = useState({
    id: group,
    address_region: '',
    address_district: '',
    address_locality: '',
    address_array: '',
    receiver: '',
    inn: '',
    kpp: '',
    oktmo: '',
    account_name: 'Общий',
    account_number: '',
    bank_name: '',
    bik: '',
    korr_account: '',
    requisites_json: {}
  });

  const getData = useCallback(async () => {
    const response = await request('/snt/get/info', 'POST', { id: group });

    if (!response.status) return;
    const result = response.result[0];
    if (result) {
      setForm({ ...form, ...result });
    }
  }, [request, group]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendData = useCallback(async () => {
    const response = await request('/snt/update/info', 'POST', form);

    const status = response.status ? 'success' : 'error';
    const message = response.status
      ? 'Данные отправлены'
      : 'Что-то пошло не так...';
    enqueueSnackbar(message, { variant: status });

    if (!response.status) return;
    getData();
  }, [request, form, enqueueSnackbar, getData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const updateInfoHandler = (e) => {
    e.preventDefault();
    sendData();
  };

  const inputFormHandler = (e) => {
    if (e !== null) {
      if (
        e.target.name === 'bik' ||
        e.target.name === 'inn' ||
        e.target.name === 'kpp' ||
        e.target.name === 'oktmo' ||
        e.target.name === 'korr_account' ||
        e.target.name === 'account_number'
      )
        e.target.value = e.target.value.replace(/\D+/g, '');

      // апи с ограничением (не для проды)
      // if(e.target.name === 'inn' && e.target.value.length === 10){
      //   innCheck(e.target.name, {inn: e.target.value});
      //   console.log('sug :>> ', sug);
      //   setForm({...form,
      //     receiver: sug.receiver,
      //     kpp: sug.kpp
      //   })
      // }
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  // апи с ограничением (не для проды)
  // const innCheck = useCallback(async (name, val) => {
  //   const res = await api('/fns/egr', 'POST',
  //     {query:val});
  //   console.log('res :>> ', res);

  //   if(res.items !== undefined && res.items.length === 0){
  //     enqueueSnackbar('Инн не найден', { variant: 'error' })
  //     setSug({...sug, isErroredInn: true});
  //   } else {
  //     setSug({...sug, isErroredInn: false});
  //     setForm({
  //       ...form,
  //       isErroredInn: false,
  //       inn:res.items[0]['ЮЛ']['ИНН'],
  //       receiver: res.items[0]['ЮЛ']['НаимСокрЮЛ'],
  //       kpp: res.items[0]['ЮЛ']['КПП']
  //     })
  //     console.log('ИНН  :>> ', res.items[0]['ЮЛ']['ИНН']);
  //     console.log('Наименование получателя :>> ', res.items[0]['ЮЛ']['НаимСокрЮЛ']);
  //     console.log('КПП  :>> ', res.items[0]['ЮЛ']['КПП']);
  //   }
  // },[sug, form])
  const clibBoardTextHelper = (form) => {
    let address = '';
    let requisites = '';
    let text = '';

    const address_region =
      form.address_region !== '' && form.address_region !== null
        ? `Регион: ${form.address_region}\n`
        : '';
    const address_district =
      form.address_district !== '' && form.address_district !== null
        ? `Район: ${form.address_district}\n`
        : '';
    const address_locality =
      form.address_locality !== '' && form.address_locality !== null
        ? `Населенный пункт: ${form.address_locality}\n`
        : '';
    const address_array =
      form.address_array !== '' && form.address_array !== null
        ? `Массив: ${form.address_array}\n`
        : '';
    address = `${address_region}${address_district}${address_locality}${address_array}`;

    const inn = form.inn !== '' && form.inn !== null ? `ИНН: ${form.inn}\n` : '';
    const receiver =
      form.receiver !== '' && form.receiver !== null
        ? `Наименование получателя: ${form.receiver}\n`
        : '';
    const kpp = form.kpp !== '' && form.kpp !== null ? `КПП: ${form.kpp}\n` : '';
    const account_number =
      form.account_number !== '' && form.account_number !== null
        ? `Номер счета получателя: ${form.account_number}\n`
        : '';
    const bank_name =
      form.bank_name !== '' && form.bank_name !== null
        ? `Наименование банка: ${form.bank_name}\n`
        : '';
    const bik = form.bik !== '' && form.bik !== null ? `БИК: ${form.bik}\n` : '';
    const korr_account =
      form.korr_account !== '' && form.korr_account !== null
        ? `Корреспонденский счет: ${form.korr_account}\n`
        : '';

    requisites = `${inn}${receiver}${kpp}${account_number}${bank_name}${bik}${korr_account}`;

    text += address !== '' ? `Адрес\n${address}` : '';
    text += requisites !== '' ? `Реквизиты\n${requisites}` : '';

    return text;
  };
  const copyHandler = () => {
    enqueueSnackbar('Текст скопирован в буфер обмена', { variant: 'success' });
  };

  return (
    <Card className={`${clsx(classes.root)} mb40`}>
      <form
        className="AddrForm"
        onSubmit={updateInfoHandler}>
        <CardContent>
          <Grid
            container
            spacing={2}>
            <Grid
              className={`${classes.container} max959`}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}>
              <Grid
                className="TextCenter max959"
                item
                xs={12}>
                <Typography variant="h5">Адрес</Typography>
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Регион"
                  name="address_region"
                  onChange={inputFormHandler}
                  required
                  value={form.address_region}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Район"
                  name="address_district"
                  onChange={inputFormHandler}
                  required
                  value={form.address_district}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Населенный пункт"
                  name="address_locality"
                  onChange={inputFormHandler}
                  required
                  value={form.address_locality}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Массив"
                  name="address_array"
                  onChange={inputFormHandler}
                  value={form.address_array}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid
              className={`${classes.container} max959`}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}>
              <Grid
                className="TextCenter max959"
                item
                xs={12}>
                <Typography variant="h5">Реквизиты</Typography>
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  // error={sug.isErroredInn}
                  inputProps={{ maxLength: 10 }}
                  label="ИНН"
                  name="inn"
                  onChange={inputFormHandler}
                  required
                  value={form.inn}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Наименование получателя"
                  name="receiver"
                  onChange={inputFormHandler}
                  required
                  value={form.receiver}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="КПП"
                  name="kpp"
                  onChange={inputFormHandler}
                  required
                  value={form.kpp}
                  variant="outlined"
                />
              </Grid>
              {/* <Grid
                className="max959"
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="ОКТМО"
                  name="oktmo"
                  onChange={inputFormHandler}
                  required
                  value={form.oktmo}
                  variant="outlined"
                />
              </Grid> */}
              {/* <Grid
                className="max959"
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText="для выбора при назначении платежей"
                  label="Название счета"
                  name="account_name"
                  onChange={inputFormHandler}
                  required
                  value={form.account_name}
                  variant="outlined"
                />
              </Grid> */}
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Номер счета получателя"
                  name="account_number"
                  onChange={inputFormHandler}
                  required
                  value={form.account_number}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Наименование банка"
                  name="bank_name"
                  onChange={inputFormHandler}
                  required
                  value={form.bank_name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="БИК"
                  name="bik"
                  onChange={inputFormHandler}
                  required
                  value={form.bik}
                  variant="outlined"
                />
              </Grid>
              <Grid
                className="max959"
                item
                xs={12}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Корреспондентский счет"
                  name="korr_account"
                  onChange={inputFormHandler}
                  required
                  value={form.korr_account}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className="max959">
          {!isDisabled && (
            <Button
              color="secondary"
              type="submit"
              variant="contained">
              Сохранить
            </Button>
          )}
          <CopyToClipboard
            onCopy={copyHandler}
            text={clibBoardTextHelper(form)}>
            <Button
              color="secondary"
              startIcon={<FileCopyIcon />}
              type="button"
              variant="contained">
              Скопировать
            </Button>
          </CopyToClipboard>
        </CardActions>
      </form>
    </Card>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Grid
  // Typography
} from '@material-ui/core';

import Page from '../../components/Page';
import { NewsSkeleton } from './components/NewsSkeleton';
import { NewsCard } from './components/NewsCard';
import { WeatherCard } from '../../components/WeatherCard/WeatherCard';
// import axios from 'axios';
import { PaddingControl } from '../../hooks/padding.hook';
import { useHttp } from '../../hooks/http.hook';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2)
  }
}));

const Home = () => {
  const classes = useStyles();
  const [skeleton, setSkeleton] = useState([]);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.system.USER_DATA);
  const { request } = useHttp();

  const setSkeletonHandler = useCallback(() => {
    // noinspection LoopStatementThatDoesntLoopJS
    const children = [];
    for (let i = 0; i < 20; i++) {
      children.push(
        <Grid
          item
          key={i}
          lg={4}
          sm={6}
          xs={12}>
          <NewsSkeleton />
        </Grid>
      );
    }
    setSkeleton([...children]);
  }, []);

  const getData = useCallback(async () => {
    const response = await request('/news/all', 'POST', { id: user.group });
    const res = response.result;

    if (!response.status) {
      setNews([]);
      setLoading(false);
      return;
    }

    setNews([...res]);
    setLoading(false);
  }, [user, request]);

  // const getData = useCallback(() => {
  //   axios.post('/news/all').then((res) => {
  //     const data = res.data;
  //     setNews(data);
  //     setLoading(false);
  //   });

  // navigator.geolocation.getCurrentPosition(position => {
  //   const {latitude, longitude} = position.coords
  //   setCoords({...coords, latitude, longitude})
  //   getWeather({latitude, longitude})
  // })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setSkeletonHandler();
    getData();
  }, [setSkeletonHandler, getData]);

  return (
    <Page title="Главная">
      <PaddingControl>
        <Grid
          className={classes.container}
          container
          spacing={3}>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}>
            <WeatherCard />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12} />
          <Grid
            item
            lg={4}
            md={12}
            xs={12} />
          {loading
            ? skeleton.map((item) => item)
            : news.map((item) => {
              return (
                <Grid
                  item
                  key={item.id}
                  lg={4}
                  sm={6}
                  xs={12}>
                  <NewsCard
                    date={item.created_at}
                    description={item.body}
                    id={item.id}
                    img={item.img_link}
                    title={item.header}
                    // url={item.url}
                  />
                </Grid>
              );
            })}
          {/* {news.length == 0 && (
            <Grid
              item
              xs={12}>
              <Typography
                align="center"
                color="textSecondary"
                variant="h5">
                Новостей нет
              </Typography>
            </Grid>
          )} */}
        </Grid>
      </PaddingControl>
    </Page>
  );
};

export default Home;

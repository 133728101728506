import React from 'react';
import axios from 'axios';
import { phoneView } from '../hooks/phone.hook';

import { IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  CHANGE_DAY,
  CHANGE_PERIOD,
  GET_COUNTERS,
  SET_PLOTS,
  SET_COUNTERS,
  SYSTEM_LOGIN,
  SYSTEM_LOGOUT,
  UPDATE_COUNTER,
  UPDATE_USER,
  GET_PLOTS,
  REDIRECT_PLOTS,
  CLEAR_FUNC,
  GET_WEATHER,
  SET_WEATHER,
  SET_CHART,
  GET_CHART,
  CHANGE_THEME
} from './types';

export function systemLogin(token) {
  return {
    type: SYSTEM_LOGIN,
    payload: token
  };
}

export function systemLogout(token) {
  return {
    type: SYSTEM_LOGOUT,
    payload: token
  };
}

export function changeUserData(user) {
  return {
    type: UPDATE_USER,
    payload: user
  };
}

export function getCounters(id) {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTERS,
      payload: id
    });

    axios
      .post('/parcel/get/counters', { id })
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: SET_COUNTERS,
          payload: data.result
        });
      });
  };
}

export function updateCounter(id) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COUNTER,
      payload: id
    });
  };
}

export function getChart(options) {
  return (dispatch) => {
    dispatch({
      type: GET_CHART,
      payload: options
    });

    const day = options.check ? 'day' : 'night';

    axios
      .post(
        `/counters/chart${options.role_check !== 'CHAIRMAN' ? '/id' : ''}`,
        { ...options, day }
      )
      .then((response) => response.data)
      .then((data) => {
        if (!data.status) return;
        const { result } = data;

        dispatch({
          type: CHANGE_DAY,
          payload: options.check
        });

        dispatch({
          type: CHANGE_PERIOD,
          payload: options.period
        });

        const filtered = result.labels.map((item) => {
          switch (options.format) {
            case 'month':
              return `${new Date(item).toLocaleString('ru', {
                day: 'numeric',
                month: 'short'
              })}`;
            case 'year':
              return `${new Date(item).toLocaleString('ru', {
                month: 'short'
              })} ${new Date(item).getFullYear()}`;
            default:
              return true;
          }
        });

        dispatch({
          type: SET_CHART,
          payload: { ...result, labels: filtered }
        });
      });
  };
}

export function getWeather(coords) {
  return (dispatch) => {
    dispatch({
      type: GET_WEATHER,
      payload: coords
    });

    axios
      .post('/weather/now', coords)
      .then((response) => response.data)
      .then((data) => {
        const weather = {
          date: data.now_dt,
          temp: data.fact.temp,
          feels: data.fact.feels_like,
          pressure: data.fact.pressure_mm,
          humidity: data.fact.humidity,
          wind: data.fact.wind_speed,
          condition: data.fact.condition,
          sunrise: data.forecasts[0].sunrise,
          sunset: data.forecasts[0].sunset
        };

        dispatch({
          type: SET_WEATHER,
          payload: weather
        });
      });
  };
}

export function setPlots({ id, role }) {
  return (dispatch) => {
    dispatch({
      type: GET_PLOTS,
      payload: id
    });

    axios
      .post('/parcels/all', { id, role_check: role })
      .then((response) => response.data)
      .then((data) => {
        if (data) {
          const rows = [];
          const editPlotHandler = (id, plot) => {
            dispatch({
              type: REDIRECT_PLOTS,
              payload: { id, plot }
            });
          };

          const editBtn = (id, plot) => {
            return (
              <IconButton onClick={() => editPlotHandler(id, plot)}>
                <ArrowForwardIcon />
              </IconButton>
            );
          };

          data.map((plot) => {
            const named = `${plot.last_name ? plot.last_name : ''} ${
              plot.first_name ? plot.first_name : ''
            } ${plot.second_name ? plot.second_name : ''}`;
            return rows.push({
              id: plot.id,
              // plot: !isNaN(+plot.address_house) ? +plot.address_house : plot.address_house,
              plot: plot.address_house,
              name: named.trim().length ? named : 'не задано',
              phone: phoneView(plot.contact_phone) || 'не задано',
              actions: editBtn(plot.id, plot.address_house),
              square: plot.square,
              position: plot.position,
              region: plot.address_region,
              district: plot.address_district,
              locality: plot.address_locality,
              array: plot.address_array,
              snt: plot.address_snt,
              street: plot.address_street,
              house: plot.address_house,
              parcel: plot.parcel
            });
          });

          dispatch({
            type: SET_PLOTS,
            // payload: rows
            //   .sort(
            //     (a, b) =>
            //       a.plot.replace(/[^\d]/g, '') - b.plot.replace(/[^\d]/g, '')
            //   )
            //   .map((el) => ({
            //     ...el,
            //     plot: !isNaN(el.plot) ? +el.plot : el.plot
            //   }))
            payload: rows
          });
        }
      });
  };
}

export function clearFunc() {
  return {
    type: CLEAR_FUNC
  };
}

export function changeTheme(theme) {
  return {
    type: CHANGE_THEME,
    payload: theme
  };
}

import React from 'react';

import styled from '@react-pdf/styled-components';
import arial from '../../../../../assets/pdf_font/arial.ttf';
import arialI from  '../../../../../assets/pdf_font/arialI.ttf';
import arialB from '../../../../../assets/pdf_font/arialB.ttf';

// noinspection ES6CheckImport
import {
  StyleSheet,
  Document,
  Page,
  Font
} from '@react-pdf/renderer';

Font.register({ family: 'Arial', fonts: [
  { src: arial }, // font-style: normal, font-weight: normal
  { src: arialI, fontStyle: 'italic' },
  { src: arialB, fontWeight: 'bold' },
]});


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontFamily: 'Arial',
    fontWeight: 300,
    fontSize: 8,
    color: '#000',
  }
});

const MainWrap = styled.View`
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 20px;
  width: 100%; 
  min-height: 440px;
  border: 2px solid #000;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`;

const LeftSide = styled.View`
  height: 100%;
  width: 190px;
  display: flex;
  flex-direction: column;
`;

const RightSide = styled.View`
  height: 100%;
  width: 100%;
  border-left: 2px solid #000;
  display: flex;
  flex-direction: column;
`;

const LeftTop = styled.View`
  border-bottom: 1px solid #000;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const LeftBot = styled.View`
  border-top: 1px solid #000;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const RightTop = styled.View`
  padding: 10px 7px;
  border-bottom: 1px solid #000;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

const RightBot = styled.View`
  padding: 10px 7px;
  border-top: 1px solid #000;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

const QRImage = styled.Image`
  display: block;
  padding: 10px;
`

const LeftTopText = styled.Text`
  color: #8C848C;
  padding-bottom: 30px;
  font-size: 10px;
`;

const LeftBotText = styled.Text`
  color: #8C848C;
  padding-bottom: 15px;
  font-size: 10px;
`;

// const LeftFakeBorder = styled.View`
//   border-right: 1px;
//   border-bottom: 1px;
//   position: absolute;
//   width: 75px;
//   height: 75px;
//   top: 113px;
//   right: 5px;
// `;

const LeftBotSub = styled.Text`
  color: #8C848C;
  font-size: 10px;
  padding-bottom: 10px;
`;

const FormNameText = styled.Text`
  position: absolute;
  top: 1px;
  right: 7px;
  font-size: 7px;
  font-style: italic;
`;

const BillName = styled.Text`
  display: block;
  border-bottom: 1px solid #000;
  width: 100%;
  font-weight: bold;
`;

const BillSub = styled.Text`
  font-size: 6px;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
`;

const SqrWrap = styled.View`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
`;

const SqrCol = styled.View`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SqrInline = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  border-left: 1px solid #000;
  width: auto;
`;

const SqrNumbers = styled.Text`
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  box-sizing: border-box;
  width: 10px;
  height: 12px;
  text-align: center;
  padding-top: 1.6px;
`

const BicCol = styled.View`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 125px;
  margin-left: 30px;
`;

const KorInline = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const InlineText = styled.Text`
  display: block;
  margin-right: 1px;
`;

const CredentialsInline = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
`;

const CredentialsText = styled.Text`
  display: block;
  width: 100px;
  margin-right: 1px;
  text-align: right;
`;

const SumText = styled.Text`
  display: block;
  width: 150px;
  margin-right: 1px;
  text-align: right;
`;

const SumServicesText = styled.Text`
  display: block;
  width: 170px;
  margin-left: 8px;
  margin-right: 1px;
`;

const SumName = styled.Text`
  display: block;
  border-bottom: 1px solid #000;
  width: 190px;
  text-align: center;
  font-weight: bold;
`;

const FinalText = styled.Text`
  display: block;
  width: 120px;
  margin-right: 1px;
  text-align: right;
  font-weight: bold;
`;

const FinalSum = styled.Text`
  display: block;
  border-bottom: 1px solid #000;
  width: 150px;
  text-align: center;
  font-weight: bold;
`;

const FinalDate = styled.Text`
  display: block;
  width: 32px;
  margin-left: 8px;
  margin-right: 1px;
  font-weight: bold;
`;

const DateBordered = styled.Text`
  display: block;
  border-bottom: 1px solid #000;
  width: 250px;
  text-align: center;
  font-weight: bold;
`;

const Agreement = styled.Text`
  font-size: 6px;
  padding-bottom: 5px;
`;

const PayerText = styled.Text`
  display: block;
  width: 350px;
  margin-right: 1px;
  font-weight: bold;
  text-align: right;
`;

const squareCreateHandler = arr => {
  return arr.map((item, idx) => <SqrNumbers key={idx}>{item}</SqrNumbers>)
}

// eslint-disable-next-line react/prop-types,react/no-multi-comp
export const MyDocument = ({name = ' ', bank = ' ', inn, number, bic, kor, bill = ' ', payer = ' ', address = ' ', sum = ' ', date = ' ', img = null}) => {
  const bicNull = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']
  const innNull = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']
  const korNull = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']
  const numberNull = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']

  const LeftSub = styled.Text`
    font-size: 6px;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 6px;
    margin-left: ${inn ? '-43px' : '-33px'};
  `;

  return (
    <Document
      title="Квитанция (Форма № ПД-4)"
    >
      <Page
        size="A4"
        style={styles.page}
      >
        <MainWrap>
          <LeftSide>
            <LeftTop>
              <LeftTopText>
                Извещение
              </LeftTopText>
              {img &&
                <QRImage
                  source={img}
                />
              }
              <LeftBotText>
                Кассир
              </LeftBotText>
            </LeftTop>
            <LeftBot>
              <LeftBotSub>
                Квитанция
              </LeftBotSub>
              <LeftBotSub>
                Кассир
              </LeftBotSub>
            </LeftBot>
          </LeftSide>

          <RightSide>
            <RightTop>
              <FormNameText>Форма № ПД-4</FormNameText>

              <BillName>{name}</BillName>
              <BillSub>(наименование получателя платежа)</BillSub>

              <SqrWrap>
                <SqrCol>
                  <SqrInline>
                    {
                      inn ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(inn.split('')) :
                        squareCreateHandler(innNull)
                    }
                  </SqrInline>
                  <LeftSub>(ИНН получателя платежа)</LeftSub>
                </SqrCol>
                <SqrCol>
                  <SqrInline>
                    {
                      number ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(number.split('')) :
                        squareCreateHandler(numberNull)
                    }
                  </SqrInline>
                  <BillSub>(номер счета получателя платежа)</BillSub>
                </SqrCol>
              </SqrWrap>
              <SqrWrap>
                <SqrCol>
                  <BillName>{bank}</BillName>
                  <BillSub>(наименование банка)</BillSub>
                </SqrCol>
                <BicCol>
                  <SqrInline>
                    {
                      bic ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(bic.split('')) :
                        squareCreateHandler(bicNull)
                    }
                  </SqrInline>
                  <BillSub>(БИК)</BillSub>
                </BicCol>
              </SqrWrap>
              <SqrWrap>
                <KorInline>
                  <InlineText>Кор./сч.:</InlineText>
                  <SqrInline>
                    {
                      kor ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(kor.split('')) :
                        squareCreateHandler(korNull)
                    }
                  </SqrInline>
                </KorInline>
              </SqrWrap>

              <BillName>{bill}</BillName>
              <BillSub>(наименование платежа)</BillSub>

              <CredentialsInline>
                <CredentialsText>Ф.И.О. плательщика:</CredentialsText>
                <BillName>{payer}</BillName>
              </CredentialsInline>

              <CredentialsInline>
                <CredentialsText>Адрес плательщика:</CredentialsText>
                <BillName>{address}</BillName>
              </CredentialsInline>

              <CredentialsInline>
                <SumText>Сумма платежа:</SumText>
                <SumName>{sum}</SumName>
                <SumServicesText>Сумма платы за услуги:</SumServicesText>
                <SumName>{sum}</SumName>
              </CredentialsInline>

              <CredentialsInline>
                <FinalText>Итого:</FinalText>
                <FinalSum>{sum}</FinalSum>
                <FinalDate>Дата:</FinalDate>
                <DateBordered>{date}</DateBordered>
              </CredentialsInline>

              <Agreement>
                С условиями приема указанной в платежном документе суммы, в т.ч. с суммой взимаемой платы за услуги
                банка ознакомлен и согласен.
              </Agreement>

              <CredentialsInline>
                <PayerText>Плательщик (подпись):</PayerText>
                <DateBordered>&nbsp;</DateBordered>
              </CredentialsInline>
            </RightTop>
            <RightBot>
              <BillName>{name}</BillName>
              <BillSub>(наименование получателя платежа)</BillSub>

              <SqrWrap>
                <SqrCol>
                  <SqrInline>
                    {
                      inn ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(inn.split('')) :
                        squareCreateHandler(innNull)
                    }
                  </SqrInline>
                  <LeftSub>(ИНН получателя платежа)</LeftSub>
                </SqrCol>
                <SqrCol>
                  <SqrInline>
                    {
                      number ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(number.split('')) :
                        squareCreateHandler(numberNull)
                    }
                  </SqrInline>
                  <BillSub>(номер счета получателя платежа)</BillSub>
                </SqrCol>
              </SqrWrap>
              <SqrWrap>
                <SqrCol>
                  <BillName>{bank}</BillName>
                  <BillSub>(наименование банка)</BillSub>
                </SqrCol>
                <BicCol>
                  <SqrInline>
                    {
                      bic ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(bic.split('')) :
                        squareCreateHandler(bicNull)
                    }
                  </SqrInline>
                  <BillSub>(БИК)</BillSub>
                </BicCol>
              </SqrWrap>
              <SqrWrap>
                <KorInline>
                  <InlineText>Кор./сч.:</InlineText>
                  <SqrInline>
                    {
                      kor ?
                        // eslint-disable-next-line react/prop-types
                        squareCreateHandler(kor.split('')) :
                        squareCreateHandler(korNull)
                    }
                  </SqrInline>
                </KorInline>
              </SqrWrap>

              <BillName>{bill}</BillName>
              <BillSub>(наименование платежа)</BillSub>

              <CredentialsInline>
                <CredentialsText>Ф.И.О. плательщика:</CredentialsText>
                <BillName>{payer}</BillName>
              </CredentialsInline>

              <CredentialsInline>
                <CredentialsText>Адрес плательщика:</CredentialsText>
                <BillName>{address}</BillName>
              </CredentialsInline>

              <CredentialsInline>
                <SumText>Сумма платежа:</SumText>
                <SumName>{sum}</SumName>
                <SumServicesText>Сумма платы за услуги:</SumServicesText>
                <SumName>{sum}</SumName>
              </CredentialsInline>

              <CredentialsInline>
                <FinalText>Итого:</FinalText>
                <FinalSum>{sum}</FinalSum>
                <FinalDate>Дата:</FinalDate>
                <DateBordered>{date}</DateBordered>
              </CredentialsInline>

              <Agreement>
                С условиями приема указанной в платежном документе суммы, в т.ч. с суммой взимаемой платы за услуги
                банка ознакомлен и согласен.
              </Agreement>

              <CredentialsInline>
                <PayerText>Плательщик (подпись):</PayerText>
                <DateBordered>&nbsp;</DateBordered>
              </CredentialsInline>
            </RightBot>
          </RightSide>
        </MainWrap>
      </Page>
    </Document>
  )
};



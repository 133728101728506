import React from 'react';

import { useParams, Redirect, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import Aux from '../../layouts/Auxilary';
import NewMain from './components/NewMain';
import NewExtra from './components/NewExtra';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const AddNews = () => {
  const classes = useStyles();
  const { tab, id } = useParams();
  const history = useHistory();
  const tabs = [
    { value: 'main', label: 'Основное' },
    { value: 'extras', label: 'Дополнительно' }
  ];
  if (!tab) {
    return <Redirect to="new/configure/main" />;
  }
  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }
  return (
    <Aux
      description={id ? 'Редактирование новости' : 'Создание новости'}
      name="Новости">
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map((tab, idx) => (
          <Tab
            disabled={idx > 0 && !id}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'main' && <NewMain id={id} />}
        {tab === 'extras' && <NewExtra id={id} />}
      </div>
    </Aux>
  );
};
export default AddNews;

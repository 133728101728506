import React from 'react';
import { Typography } from '@material-ui/core';

import { GroupDetails } from './components/GroupDetails';

export const GroupsWrapper = ({
  groups,
  devices,
  getDevicesDataHandler,
  handleClickDelete,
  getGroupsDataHandler
}) => {
  if (groups.length == 0) {
    return (
      <Typography
        align="center"
        color="textSecondary"
        variant="h5">
        Создавайте группы приборов, чтобы вам было легче следить за учетом и
        оплатами.
        <br />
        Например, создайте группы приборов по улицам или линиям КТП.
      </Typography>
    );
  }
  return groups.map((group) => {
    return (
      <GroupDetails
        devices={devices}
        getDevicesDataHandler={getDevicesDataHandler}
        getGroupsDataHandler={getGroupsDataHandler}
        group={group}
        handleClickDelete={handleClickDelete}
      />
    );
  });
};

import React from 'react';
import {useHistory} from 'react-router-dom';
import {BreadcrumbsSetup} from './components/Breadcrumbs/Breadcrumbs.settings';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  startDateButton: {
    marginRight: theme.spacing(1)
  },
  endDateButton: {
    marginLeft: theme.spacing(1)
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

export const PageHeader = props => {
  // eslint-disable-next-line react/prop-types
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { description, className, ...rest } = props;
  const history = useHistory();
  const {pathname} = history.location


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component="h2"
        gutterBottom
        variant="overline"
      >
        <BreadcrumbsSetup location={pathname} />
      </Typography>
      <Typography
        component="h1"
        gutterBottom
        variant="h3"
      >
        { description }
      </Typography>
    </div>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string
};

PageHeader.defaultProps = {};

import styled from 'styled-components'

export const PlotDetailsWrapper = styled.main`
    padding: 16px;
    display: inline-flex;
`

export const PlotDetailsInfo = styled.main`
    display: flex;
    flex-direction: column;
    padding-left: 14px;
    justify-content: space-around;
`

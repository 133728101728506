import React from 'react';
import styled from 'styled-components';
import { PlotUserDetails } from './PlotUserDetails/PlotUserDetails';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FullScreenDialog } from './FullScreenDialog/FullScreenDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  panel: {
    paddingLeft: 0,
    paddingRight: 16
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const EditWrap = styled.main`
  display: flex;
  align-items: center;

  @media screen and (max-width: 930px) {
    align-items: flex-start;
    padding-top: 31px;
  }
`;

const parsedDate = (nowDate) => {
  const _ = new Date(nowDate);
  return _.toLocaleString('ru').split(',')[0];
};

// eslint-disable-next-line react/prop-types
export const PlotUsersTable = ({ loading, peoples, update }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading ? (
        <div>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
      ) : (
        // eslint-disable-next-line react/prop-types
        peoples.map((user) => {
          return (
            <ExpansionPanel key={user.id}>
              <ExpansionPanelSummary
                className={`${classes.panel} MuiReverse`}
                expandIcon={<ExpandMoreIcon />}>
                <PlotUserDetails
                  email={user.email}
                  instagram={user.instagram}
                  name={user.first_name}
                  onClick={(event) => event.stopPropagation()}
                  owner={user.owner}
                  part={user.part}
                  patronymic={user.second_name}
                  phone={user.phone}
                  place="expansion"
                  skype={user.skype}
                  surname={user.last_name}
                  telegram={user.telegram}
                  viber={user.viber}
                  vk={user.vk}
                  watsapp={user.watsapp}
                />
                <EditWrap onClick={(event) => event.stopPropagation()}>
                  <FullScreenDialog
                    update={update}
                    userId={user.id}
                    userRole={user.owner}
                  />
                </EditWrap>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      Дата рождения:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {parsedDate(user.birthDate) || 'нет данных'}
                    </Typography>
                  </Grid>
                  <Divider
                    flexItem
                    orientation="vertical" />
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      ИНН:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.inn || 'нет данных'}
                    </Typography>
                  </Grid>
                  <Divider
                    flexItem
                    orientation="vertical" />
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      СНИЛС:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.snils || 'нет данных'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      Паспорт:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.passportNumber
                        ? `${user.passportSeries} ${user.passportNumber}`
                        : 'нет данных'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      Выдан:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.issued || 'нет данных'}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.issuedDate && (
                        <Typography
                          component="span"
                          variant="h5">
                          &nbsp; | &nbsp;
                          {parsedDate(user.issuedDate) || 'нет данных'}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      Адрес прописки:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.city ? (
                        <>
                          {user.city ? `${user.region}, ` : user.region}
                          {user.street ? `${user.city}, ` : user.city}
                          {user.house ? `${user.street}, ` : user.street}
                          {user.apartments ? `${user.house}, ` : user.house}
                          {user.apartments}
                        </>
                      ) : (
                        'нет данных'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}>
                    <Typography
                      component="span"
                      variant="body1">
                      Адрес проживания:{' '}
                    </Typography>
                    <Typography
                      component="span"
                      variant="h5">
                      {user.cityFact ? (
                        <>
                          {user.cityFact
                            ? `${user.regionFact}, `
                            : user.regionFact}
                          {user.streetFact
                            ? `${user.cityFact}, `
                            : user.cityFact}
                          {user.houseFact
                            ? `${user.streetFact}, `
                            : user.streetFact}
                          {user.apartmentsFact
                            ? `${user.houseFact}, `
                            : user.houseFact}
                          {user.apartmentsFact}
                        </>
                      ) : (
                        'нет данных'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })
      )}
    </div>
  );
};

import { createMuiTheme } from '@material-ui/core';
import { colors } from '@material-ui/core';

import typography from './typography';
import overrides from './overrides';


const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#84be41',
    main: '#84be41',
    light: '#84be41'
  },
  secondary: {
    contrastText: white,
    dark: '#84be41',
    main: '#84be41',
    light: '#84be41'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#e6e5e8',
    secondary: '#adb0bb',
    link: '#84be41'
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#f4f6f8',
    paper: '#282C34'
  },
  divider: 'rgba(255, 255, 255, 0.12)'
};

export const blackTheme = createMuiTheme({
  palette,
  typography,
  overrides
});


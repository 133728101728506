/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Link, Typography } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';

import { useSnackbar } from 'notistack';
import { useHttp } from '../../../../hooks/http.hook';
import theme from '../../../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 60
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%'
  }
}));

// eslint-disable-next-line react/prop-types
const ResetForm = () => {
  const classes = useStyles();

  const { loading, request } = useHttp();
  const { enqueueSnackbar } = useSnackbar();
  const [width, setWitdh] = useState(window.innerWidth);

  useEffect(() => {
    setWitdh(window.innerWidth);
  }, [window.innerWidth]);

  const [form, setForm] = useState({
    phone: ''
  });

  const changeFormHandler = (value, name) => {
    if (name === 'phone') value = value.replace(/\D+/g, '');
    setForm({ ...form, [name]: value });
  };

  const resetHandler = async () => {
    const data = await request('/mail/reset/password', 'POST', { ...form });
    const status = data.status ? 'success' : 'warning';

    if (data.message) {
      let message = data.message || 'Что-то пошло не так...';
      return enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: width > 500 ? 'bottom' : 'top',
          horizontal: 'left'
        }
      });
    }

    enqueueSnackbar(data.result, {
      variant: status,
      anchorOrigin: {
        vertical: width > 500 ? 'bottom' : 'top',
        horizontal: 'left'
      }
    });

    if (localStorage.getItem('theme')) return;

    const theme = {
      theme: 'WHITE',
      check: true
    };
    localStorage.setItem('theme', JSON.stringify(theme));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    resetHandler();
  };

  return (
    <form
      className={clsx(classes.root)}
      onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <PhoneInput
          country={'ru'}
          countryCodeEditable={false}
          disableDropdown
          inputProps={{
            required: true,
            pattern: '.{18,}'
          }}
          onChange={(e) => changeFormHandler(e, 'phone')}
          value={form.phone}
        />
      </div>
      <Button
        className={classes.submitButton}
        color="secondary"
        disabled={loading}
        size="large"
        type="submit"
        variant="contained">
        Отправить
      </Button>
      <Typography gutterBottom>
        Для сброса пароля введите ваш номер телефона. Инструкции будут
        отправлены на указанную в вашем аккаунте почту.
      </Typography>
      <Link
        component={RouterLink}
        to="/"
        underline="hover">
        Назад
      </Link>
    </form>
  );
};

ResetForm.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(ResetForm);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide
} from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  img:{
    height: '100%',
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props} />;
});

const Gallery = ({ tileData }) => {
  const classes = useStyles();
  const [selectedTile, setSelectedTile] = React.useState(null);

  const handleClickOpen = (tile) => {
    setSelectedTile(tile);
  };
  const handleClose = () => {
    setSelectedTile(null);
  };

  return (
    <div className={classes.root}>
      <GridList
        className={classes.gridList}
        cols={2.5}>
        {tileData.map((tile) => (
          <GridListTile
            key={tile}
            onClick={() => handleClickOpen(tile)}>
            <img src={tile} />
            {/* <GridListTileBar
              
            /> */}
          </GridListTile>
        ))}
      </GridList>
      <Dialog
        fullScreen
        onClose={handleClose}
        open={selectedTile !== null}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="close"
              color="inherit"
              edge="start"
              onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {selectedTile && <img
          className={classes.img}
          src={selectedTile} />}
        {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}
      </Dialog>
    </div>
  );
};
export default Gallery;

import React, {
  useState,
  // useEffect,
  useCallback
} from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useHttp } from '../../../../../../hooks/http.hook';
import { makeStyles } from '@material-ui/core/styles';
import { PDFViewer } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';

// import { PdfDialog } from '../pdfDialog';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props} />;
});

const PDFButton = ({ eui }) => {
  const classes = useStyles();
  const { request, loading } = useHttp();
  const [file, setFile] = useState('');
  const chart = useSelector((state) => state.chart);
  const { enqueueSnackbar } = useSnackbar();

  const getPdf = useCallback(async () => {
    const response = await request('/create/pdf/period', 'POST', {
      eui,
      chart
    });
    const {result, status} = response;
    if(status && result.length !== 0){
      setFile(response.result[0].split('/').splice(7).join('/'));
    }
    return result.length !== 0
  }, [eui, request, chart]); 

  const [open, setOpen] = useState(false);
  const handleOpen = async () => {
    const isPdf = await getPdf();
    if(isPdf){
      setOpen((state) => !state);
    } else {
      enqueueSnackbar('Нет данных!', {
        variant: 'error',
      })
    }
  };
  const theme = JSON.parse(localStorage.getItem('theme'));
  const setTheme = (set) => {
    if (set && set.theme === 'DARK') return 'DARK';
    return '';
  };
  return (
    <>
      {loading ? (
        <IconButton disabled>
          <CircularProgress size={24} />
        </IconButton>
      ) : (
        <IconButton onClick={handleOpen}>
          <ReceiptIcon />
        </IconButton>
      )}
      {!loading && (
        <Dialog
          className={setTheme(theme)}
          fullScreen
          onClose={() => setOpen(!open)}
          open={open}
          TransitionComponent={Transition}>
          <AppBar className={`${classes.appBar} pdfBar`}>
            <Toolbar>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={() => setOpen(!open)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <PDFViewer src={`/${file}`} />
        </Dialog>
      )}
    </>
  );
};
PDFButton.propTypes = {
  eui: PropTypes.string,
  chart: PropTypes.object
};

export default PDFButton;

import React, { useState, useCallback, useEffect, createRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { DokaModal } from '../../../../plugins/react-doka';

import { useSnackbar } from 'notistack';

import {
  Grid,
  Card,
  CardContent,
  Avatar,
  Typography,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CardActions,
  Button,
  Tooltip
} from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { useHttp } from '../../../../hooks/http.hook';
import { uploadImage } from '../../../../hooks/uploader.hook';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  error: {
    margin: '0 14px 0 14px',
    color: '#e53935'
  }
}));

// eslint-disable-next-line react/prop-types
export const PlotInfo = ({ id }) => {
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { request } = useHttp();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.system.USER_DATA);

  const [state, setState] = useState({
    mask: (root, setInnerHTML) => {
      setInnerHTML(
        root,
        `
                    <mask id="my-mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                    </mask>
                `
      );
    },
    profile: {
      enabled: false,
      image: null,
      src: '',
      crop: {
        aspectRatio: 0.55,
        center: {
          x: 0.5378,
          y: 0.355
        }
      }
    }
  });

  const handleToggleProfileEditor = (input) => {
    console.log('Toggle Doka Profile Modal');

    setState({
      ...state,
      profile: {
        ...state.profile,
        src: input,
        enabled: !state.profile.enabled
      }
    });
  };

  const handleDokaConfirmProfile = (output) => {
    console.log('Confirmed Doka Profile Modal', output);
    setState({
      ...state,
      profile: {
        ...state.profile,
        enabled: false,
        image: output.file,
        crop: output.data.crop
      }
    });

    uploadImage(params.id, 'plot', user.role, output.file, dispatch);
  };

  const handleDokaCancelProfile = () => {
    console.log('Cancelled Doka Profile Modal');

    setState({
      ...state,
      profile: {
        ...state.profile,
        enabled: false
      }
    });
  };

  const handleAvatarUpload = createRef();
  const clickUploadHandler = () => {
    return handleAvatarUpload.current.click();
  };

  const sourceFileHandler = (e) => {
    return handleToggleProfileEditor(e.target.files[0]);
  };

  const { profile, mask } = state;

  const [form, setForm] = useState({
    id,
    role_check: user.role,
    address_street: '',
    address_house: '',
    survey: 0,
    kad_number: '',
    position: '',
    square: '',
    image: ''
  });
  const [oldForm, setOldForm] = useState({});

  const getPrevDataHandler = useCallback(
    async (id) => {
      const userId = user.role === 'CHAIRMAN' ? user.group : user.id;
      const response = await request('/parcels/all', 'POST', {
        id: userId,
        role_check: user.role
      });
      const responseOld = await request('/parcel/get/user_info', 'POST', {
        id
      });
      const fetchedPlot = response.filter((plot) => +plot.id === +id)[0];

      Object.keys(fetchedPlot).map((idx) => {
        if (idx === 'survey' && fetchedPlot.survey === null) {
          fetchedPlot.survey = 0;
        }
        if (fetchedPlot[idx] === '' || fetchedPlot[idx] === null) {
          fetchedPlot[idx] = '';
        }
        return true;
      });
      const fetchedPlotOld = responseOld.result[responseOld.result.length - 1];
      if (fetchedPlotOld) {
        Object.keys(fetchedPlotOld).map((idx) => {
          if (idx === 'survey' && fetchedPlotOld.survey === null) {
            fetchedPlotOld.survey = 0;
          }
          if (fetchedPlotOld[idx] === '' || fetchedPlotOld[idx] === null) {
            fetchedPlotOld[idx] = '';
          }
          return true;
        });
      }
      if (user.role === 'CHAIRMAN') {
        if (fetchedPlot) {
          setForm({ ...form, ...fetchedPlot });
          setOldForm({ ...form, ...fetchedPlotOld });
        } else {
          setForm({ ...form, ...fetchedPlotOld });
          setOldForm({ ...form, ...fetchedPlotOld });
        }
      } else {
        if (fetchedPlotOld) {
          setForm({ ...form, ...fetchedPlotOld });
          setOldForm({ ...form, ...fetchedPlotOld });
        } else {
          setForm({ ...form, ...fetchedPlot });
          setOldForm({ ...form, ...fetchedPlot });
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [user, request]
  );

  useEffect(() => {
    if (id) {
      getPrevDataHandler(id);
    }
  }, [getPrevDataHandler, id]);

  const formChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const sendFormHandler = useCallback(async () => {
    const data = await request('/parcel/update', 'POST', {
      ...form,
      id,
      group: user.group
    });
    const status = data.status ? 'success' : 'error';
    let message = data.status ? 'Успешно сохранено' : 'Что-то пошло не так!';
    if (!data.status && data.message !== undefined) message = data.message;
    enqueueSnackbar(message, { variant: status });

    if (!data.status) return;
    const insertId = data.result[0].id;
    if (insertId) {
      history.push(`/plot/${insertId}/configure/info`);
    }
    getPrevDataHandler(insertId);
  }, [form, enqueueSnackbar, user, history, id, request, getPrevDataHandler]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormHandler();
  };
  const helperTextHandler = (oldText, newText, flag) => {
    if (oldText !== newText) {
      if (flag === 'survey') {
        switch (Number(oldText)) {
          case 0:
            return 'Не задано';
          case 1:
            return 'Нет';
          case 2:
            return 'Да';
          default:
            return oldText;
        }
      } else {
        return oldText;
      }
    }
  };

  return (
    <Card className="mb40">
      {/* {console.log(form.image)} */}
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography variant="subtitle1">Фотография участка</Typography>
              <Tooltip arrow title="Изменить">
                <Avatar
                  className="PlotInfoAvatar"
                  onClick={clickUploadHandler}
                  src={`/${form.image}`}
                  variant="square">
                  <PhotoCameraOutlinedIcon />
                </Avatar>
              </Tooltip>
              {profile.enabled && (
                <DokaModal
                  crop={profile.crop}
                  cropMask={mask}
                  onCancel={handleDokaCancelProfile}
                  onConfirm={handleDokaConfirmProfile}
                  outputData
                  src={profile.src}
                  utils={['crop', 'filter', 'color']}
                />
              )}
              <input
                accept=".jpg, .jpeg, .png"
                className="inputFile"
                name="avatar"
                onChange={sourceFileHandler}
                ref={handleAvatarUpload}
                type="file"
              />
            </Grid>
            <Grid container item md={8} spacing={2} xs={12}>
              <Grid className="row" item xs={12}>
                <Typography variant="h5">Адрес:</Typography>
                &nbsp;
                {user.address ? (
                  user.address
                ) : (
                  <>
                    {form.address_district
                      ? `${form.address_region}, `
                      : form.address_region}
                    {form.address_locality
                      ? `${form.address_district}, `
                      : form.address_district}
                    {form.address_array
                      ? `${form.address_locality}, `
                      : form.address_locality}
                    {form.address_snt
                      ? `${form.address_array}, `
                      : form.address_array}
                    {form.address_snt}
                  </>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Улица"
                  name="address_street"
                  onChange={formChangeHandler}
                  value={form.address_street}
                  variant="outlined"
                />
                <Typography className={classes.error} variant="caption">
                  {helperTextHandler(oldForm.address_street, form.address_street)}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Дом"
                  name="address_house"
                  onChange={formChangeHandler}
                  required
                  value={form.address_house}
                  variant="outlined"
                />
                <Typography className={classes.error} variant="caption">
                  {helperTextHandler(oldForm.address_house, form.address_house)}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Кад. номер"
                  name="kad_number"
                  onChange={formChangeHandler}
                  value={form.kad_number}
                  variant="outlined"
                />
                <Typography className={classes.error} variant="caption">
                  {helperTextHandler(oldForm.kad_number, form.kad_number)}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  helperText="через “:”"
                  label="Координаты"
                  name="position"
                  onChange={formChangeHandler}
                  value={form.position}
                  variant="outlined"
                />
                <Typography className={classes.error} variant="caption">
                  {helperTextHandler(oldForm.position, form.position)}
                </Typography>
              </Grid>
              <Grid item md={3} xs={9}>
                <TextField
                  fullWidth
                  label="Площадь"
                  name="square"
                  onChange={formChangeHandler}
                  type="number"
                  value={form.square}
                  variant="outlined"
                />
                <Typography className={classes.error} variant="caption">
                  {helperTextHandler(oldForm.square, form.square)}
                </Typography>
              </Grid>
              <Grid className="alignCenter" item md={9} xs={3}>
                <Typography variant="h5">
                  M<sup>2</sup>
                </Typography>
              </Grid>
              <Grid className="alignCenter" item md={3} xs={12}>
                <Typography variant="h5">Межевание:</Typography>
                <Typography className={classes.error} variant="caption">
                  {helperTextHandler(oldForm.survey, form.survey, 'survey')}
                </Typography>
              </Grid>
              <Grid item md={9} xs={12}>
                <FormControl
                  className="InlineControl"
                  component="fieldset"
                  fullWidth>
                  <RadioGroup
                    name="survey"
                    onChange={formChangeHandler}
                    value={form.survey.toString() || 0}>
                    <FormControlLabel
                      control={<Radio />}
                      label="Не задано"
                      value="0"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Да"
                      value="2"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Нет"
                      value="1"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="secondary" type="submit" variant="contained">
            Сохранить изменения
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

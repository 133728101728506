import React from 'react';
import {phoneView} from '../../hooks/phone.hook';
import {Typography} from '@material-ui/core';
import { GmailIcon, PhoneIcon } from '../SocialIcons/SocialIcons';

export const ContactProfile = (phone, email) => {
  return (
    <main>
      <div className="containerInline">
        <PhoneIcon />
        <Typography
          component={'span'}
          variant="subtitle2"
        >
            &nbsp;
          { 
            phone ?
              <a href={`tel:+${phone}`}>
                {phoneView(phone)}
              </a> :
              'Нет данных'
          }
        </Typography>
      </div>
      <div className="containerInline">
        <GmailIcon />
        <Typography
          component={'span'}
          variant="subtitle2"
        >
          &nbsp;
          {
            email ?
              <a href={`mailto:${email}`}>{email}</a> :
              'Нет данных'
          }
        </Typography>
      </div>
    </main>
  )
};

import styled from 'styled-components';

export const ControlPanelWrap = styled.main`
  margin-bottom: 5px;
`;

export const ContentWrap = styled.main`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  
  &:first-of-type {
  margin-bottom: 7px;
  }
  &:nth-child(3) {
    margin-top: 7px;
  }
`;

export const PickerWrap = styled.main`
  display: inline-flex;
`;

export const CalendarWrap = styled.main`
  display: inline-flex;
  align-items: center;
  width: max-content;
`;

export const ArrowWrap = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 7px;
  padding-top: 3px;
`;

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import CodeIcon from '@material-ui/icons/Code';
// import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
// import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
// import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
// import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';


export const DEFAULT_BLOCK_TYPES = [
  {
    blockType: 'header-one',
    tooltip: 'Heading 1',
    text: 'H1'
  },
  {
    blockType: 'header-two',
    tooltip: 'Heading 2',
    text: 'H2'
  },
  {
    blockType: 'header-three',
    tooltip: 'Heading 3',
    text: 'H3'
  },
  {
    blockType: 'header-four',
    tooltip: 'Heading 4',
    text: 'H4'
  },
  {
    blockType: 'header-five',
    tooltip: 'Heading 5',
    text: 'H5'
  },
  {
    blockType: 'header-six',
    tooltip: 'Heading 6',
    text: 'H6'
  },
  {
    blockType: 'blockquote',
    tooltip: 'Blockquote',
    icon: FormatQuoteIcon
  },
  {
    blockType: 'unordered-list-item',
    tooltip: 'Unordered list',
    icon: FormatListBulletedIcon
  },
  {
    blockType: 'ordered-list-item',
    tooltip: 'Ordered list',
    icon: FormatListNumberedIcon
  },
  {
    blockType: 'code-block',
    tooltip: 'Code Block',
    icon: CodeIcon
  },
//   {
//     blockType: 'left',
//     tooltip: 'Align left',
//     icon: FormatAlignLeftIcon
//   },
//   {
//     blockType: 'center',
//     tooltip: 'Align center',
//     icon: FormatAlignCenterIcon
//   },
//   {
//     blockType: 'right',
//     tooltip: 'Align right',
//     icon: FormatAlignRightIcon
//   },
//   {
//     blockType: 'justify',
//     tooltip: 'Justify',
//     icon: FormatAlignJustifyIcon
//   }
];

import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import gradients from '../../../../utils/gradients';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import {useHttp} from '../../../../hooks/http.hook';
import {useSnackbar} from 'notistack';
import {changeUserData} from '../../../../redux/actions';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
  },
  header: {
    paddingBottom: 0,
  },
  auto: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0'
  },
  metrics: {
    display: 'inline-flex',
    alignItems: 'flex-end'
  },
  flow: {
    textAlign: 'center',
  },
  avatar: {
    backgroundImage: gradients.green,
  },
}));

// eslint-disable-next-line react/prop-types
export const TariffsCardEdit = ({trigger}) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(state => state.system.USER_DATA)
  const dispatch = useDispatch();
  const {request} = useHttp();
  const classes = useStyles();

  const [form, setForm] = useState({
    day_date: new Date(),
    price_tarif_1: user.price_tarif_1,
    price_tarif_2: user.price_tarif_2,
    price_tarif_3: user.price_tarif_3,
    price_tarif_4: user.price_tarif_4,
    id: user.group,
  });

  const changeFormHandler = e => {
    const value = e.target.value.replace(/,/,'.').replace(/[^.\d]+/g,'').replace( /^([^.]*\.)|\./g, '$1' )
    setForm({...form , [e.target.name]: value})
  };

  const uploadDatHandler = useCallback(async () => {
    const response = await request('/snt/update/tariff', 'POST', form);
    trigger()

    const status = response.status ? 'success' : 'error';
    const message = response.status ? 'Тарифы обновлены' : 'Что-то пошло не так...'
    enqueueSnackbar(message, { variant: status })

    if (!response.status) return;

    dispatch(changeUserData({
      ...user,
      day_date: new Date(),
      price_tarif_1: form.price_tarif_1,
      price_tarif_2: form.price_tarif_2,
      price_tarif_3: form.price_tarif_3,
      price_tarif_4: form.price_tarif_4
    }));

  }, [form, dispatch, enqueueSnackbar, request, trigger, user]);

  const submitFormHandler = (e) => {
    e.preventDefault();
    uploadDatHandler();
  };

  const visualDateHandler = date => {
    const _ = new Date(date);
    return _.toLocaleDateString('ru')
  }

  return (
    <form
      onSubmit={submitFormHandler}
    >
      <Card>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
            >
              <FlashOnOutlinedIcon />
            </Avatar>
          }
          className={classes.header}
          title={
            <Typography
              color="textSecondary"
              variant="h5"
            >
            Электроэнергия. Тарифы
            </Typography>
          }
        />
        <CardContent
          className={classes.root}
        >
          <Grid
            container
            justify="center"
            spacing={2}
          >
            <Grid
              className={classes.flow}
              item
              xs={12}
            >
              <Typography
                color="secondary"
                variant="h4"
              >
              на сегодня
              </Typography>
            </Grid>
            <Grid
              className={classes.flow}
              item
              xs={12}
            >
              <Typography variant="body1">Действует с {visualDateHandler(user.reg_date)}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="День"
                name="price_tarif_1"
                onChange={changeFormHandler}
                required
                value={form.price_tarif_1}
                variant="outlined"
              />

            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="Ночь"
                name="price_tarif_2"
                onChange={changeFormHandler}
                required
                value={form.price_tarif_2}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Сутки"
                  name="price_tarif_3"
                  onChange={changeFormHandler}
                  required
                  value={form.price_tarif_3}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                color="primary"
                type="submit"
                variant="contained"
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

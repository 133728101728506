import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Slide,
  Fab,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  CardActions,
  Button,
  Divider
  // MenuItem,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from '@material-ui/lab/Skeleton';

// import { DeviceDetails } from '../DeviceDetails/DeviceDetails';
import { DeviceWrapper } from '../DeviceWrapper/DeviceWrapper';
import { initialStateDialog } from './FormInitial';
import { useHttp } from '../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff'
  },
  select: {
    width: '100%',
  },
  control: {
    height: 53,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    height: 'fit-content'
  },
  row: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  error: {
    margin: '0 14px 0 14px',
    color: '#e53935'
  },
  fl: {
    display: 'flex',
    marginBottom: '20px'
  },
  width: {
    minWidth: '100%',
    backgroundColor:'#fff'
  }
}));
// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props} />;
});
const mapStateToProps = (state) => {
  return {
    plotGroupId: state.system.USER_DATA.group
  };
};

let FullScreenDialog = connect(
  mapStateToProps,
  null
)(({ groupId, group, updateGroups, updateDevices, plotGroupId, searchGroupsHandler }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { request, loading } = useHttp();
  const [form, setForm] = useState(initialStateDialog);
  const [oldForm, setOldForm] = useState(initialStateDialog);

  const [devices, setDevices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = (id) => {
    setOpen(true);
    if (id == groupId) {
      setForm(initialStateDialog);
      setOldForm(initialStateDialog);
      getGroupDataHandler();
      getDevicesDataHandler(id, group.id_type);
    }
  };
  const handleClose = () => {
    setForm(initialStateDialog);
    setOldForm(initialStateDialog);
    setOpen(false);
    setDevices([]);
  };
  const getGroupDataHandler = useCallback(async () => {
    if (!groupId) return;
    const groupsData = await request('/counters/get/grouping', 'POST', {
      id: plotGroupId
    });
    const group = groupsData.result.find((el) => el.id === groupId);
    if (group) {
      setForm({ ...form, ...group });
      setOldForm({ ...oldForm, ...group });
    }
  }, [groupId, group, request, form, oldForm]);

  const getDevicesDataHandler = async (groupId, typeId) => {
    console.log('groupId:>> ', groupId);
    console.log('typeId:>> ', typeId);
    if (!groupId && groupId !== 0) return;
    const devicesData = await request(
      '/counters/get/grouping/devices/all',
      'POST',
      {
        id: plotGroupId,
        grouping: groupId,
        type: typeId
      }
    );
    const devices = devicesData.result;
    if (devices) {
      setDevices([...devices]);
    }
  };

  const inputFormHandler = (e) => {
    if (e.target.name == 'id_type') {
      getDevicesDataHandler(groupId ? groupId : 0, e.target.value);
    }
    if (e.target.name == 'devices') {
      setDevices(
        devices.map((el) => {
          if (el.id == e.target.value) {
            el.checked = !el.checked;
          }
          return el;
        })
      );

      setForm({
        ...form,
        devices: devices.filter((el) => el.checked).map((el) => el.id)
      });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };
  const [filteredDevices, setFilteredDevices] = useState([]);

  const searchDeviceHandler = (e) => {
    const value = e.target.value;
    const filteredDevices = devices.filter((device) => {
      const serial = device.serial.toString().toLowerCase();
      const name = device.name.split(' ').join('').toLowerCase();
      const street = device.address_street
        ? `ул. ${device.address_street} `.split(' ').join('').toLowerCase()
        : '';
      const house = device.address_house
        ? `участок ${device.address_house}`.split(' ').join('').toLowerCase()
        : '';

      const search = value.toString().split(' ').join('').toLowerCase();

      const str = `${serial}${name}${street}${house}`;

      return str.includes(search);
    });

    setFilteredDevices(filteredDevices);
  };
  
  const groupInfo = useCallback(async () => {
    if (!groupId && groupId !== 0) {
      const result = await request('/counters/add/grouping', 'POST', {
        id: plotGroupId,
        name: form.name,
        type: form.id_type,
        counters: [...form.devices]
      });

      const status = result.status ? 'success' : 'error';
      if (!result.status && result.error != undefined) {
        enqueueSnackbar(result.error, { variant: status });
        return;
      }
      const message = result.status
        ? 'Группа успешно создана'
        : 'Что-то пошло не так...';
      enqueueSnackbar(message, { variant: status });
      setForm(initialStateDialog);
      setOpen(false);
      setDevices([]);
    } else {
      const result = await request('/counters/edit/grouping', 'POST', {
        name: form.name === oldForm.name ? '' : form.name,
        grouping: form.id,
        counters: [...form.devices]
      });

      const status = result.status ? 'success' : 'error';

      if (!result.status && result.error != undefined) {
        enqueueSnackbar(result.error, { variant: status });
        return;
      }
      const message = result.status
        ? 'Группа успешно обновлена'
        : 'Что-то пошло не так...';
      updateDevices(form.id, form.id_type);
      enqueueSnackbar(message, { variant: status });
      setForm(initialStateDialog);
      setOpen(false);
      setDevices([]);
    }
    updateGroups();
  }, [form, groupId, enqueueSnackbar, request]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.devices.length <= 0) {
      const message = !groupId
        ? 'Выберите хотя бы один счетчик!'
        : 'Удалите или добавьте счетчик в группу!';
      enqueueSnackbar(message, { variant: 'error' });
      return;
    }
    groupInfo();
  };
  const theme = JSON.parse(localStorage.getItem('theme'));

  const setTheme = (set) => {
    if (set && set.theme === 'DARK') return 'DARK';
    return '';
  };
  return (
    <div>
      {groupId ? (
        <span>
          <Fab
            aria-label="edit"
            className="BtnGreen extraSmall"
            color="secondary"
            onClick={() => handleClickOpen(groupId)}
            size="small">
            <EditIcon />
          </Fab>
        </span>
      ) : (
        <Grid
          container
          spacing={2}>
          <Grid
            item
            md={6}
            xs={12}>
            <span>
              <Fab
                aria-label="add"
                className="BtnGreen"
                onClick={handleClickOpen}
                size="small">
                <AddIcon />
              </Fab>
              <span className="ButtonTitle">Добавить группу</span>
            </span>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}>
            <TextField
              className={classes.width}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                placeholder: 'Найти...'
              }}
              onChange={searchGroupsHandler}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
      {
        <Dialog
          className={setTheme(theme)}
          fullScreen
          onClose={handleClose}
          open={open}
          TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography
                className={classes.title}
                variant="h5">
                Создание группы
              </Typography>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <form onSubmit={handleSubmit}>
            <CardActions>
              <Button
                color="secondary"
                type="submit"
                variant="contained">
                Сохранить изменения
              </Button>
            </CardActions>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={2}>
                <Grid
                  className={classes.container}
                  container
                  item
                  lg={12}
                  md={12}
                  spacing={2}
                  xs={12}>
                  <Grid
                    item
                    md={6}
                    xs={12}>
                    <TextField
                      fullWidth
                      label="Название"
                      name="name"
                      onChange={inputFormHandler}
                      required
                      value={form.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}>
                    <FormControl
                      className={`${classes.select} NativeSelect`}
                      variant="outlined">
                      <InputLabel htmlFor="outlined-type-native-simple">
                        Тип прибора учета
                      </InputLabel>
                      <Select
                        disabled={!groupId ? false : true}
                        inputProps={{
                          name: 'id_type'
                        }}
                        label="Тип прибора учета"
                        name="id_type"
                        native
                        onChange={inputFormHandler}
                        required
                        value={form.id_type}>
                        <option
                          disabled
                          value="" />
                        <option value={1}>Электричество</option>
                        {/* <option value={2}>Пользователь</option> */}
                        {/* <option value={3}>Пользователь</option> */}
                        {/* <option value={4}>Пользователь</option> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}>
                    <TextField
                      className={classes.width}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        placeholder: 'Найти...'
                      }}
                      onChange={searchDeviceHandler}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  className={classes.container}
                  container
                  item
                  lg={12}
                  md={12}
                  xs={12}>
                  {loading ? (
                    <Grid
                      item
                      xs={12}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </Grid>
                  ) : (
                    <DeviceWrapper
                      arr={filteredDevices.length > 0 ? filteredDevices : devices}
                      inputFormHandler={inputFormHandler}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Dialog>
      }
    </div>
  );
});
export default FullScreenDialog;

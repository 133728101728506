import React from 'react'; // useState
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
    backgroundColor: '#D16448',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 'initial',
      minWidth: 344
    }
  },
  typography: {
    fontWeight: 'bold',
    color: '#fff'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px'
  },
  icons: {
    marginLeft: 'auto'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#fff',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  collapse: {
    padding: 16
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: 'none'
  },
  root: {
    padding: '16px',
    paddingBottom: '16px'
  }
}));
// eslint-disable-next-line 
const SnackMessage = React.forwardRef((props, ref) => {
  const { message, key, id, action } = props;
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = () => {
    closeSnackbar(key);
  };
  const handleAction = () => {
    action(id);
    closeSnackbar(key);
  };

  return (
    <Card
      className={classes.card}
      ref={ref}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <Typography
          className={classes.typography}
          variant="subtitle2">
          {message}
        </Typography>
        <div className={classes.icons}>
          <IconButton
            className={classes.expand}
            onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </div>
      </CardActions>
      <CardContent className={classes.root}>
        <Button
          className={classes.button}
          onClick={handleAction}
          size="small">
          <Typography
            className={classes.typography}
            variant="subtitle1">
            Удалить
          </Typography>
        </Button>
      </CardContent>
    </Card>
  );
});

SnackMessage.propTypes = {
  action: PropTypes.function,
  id: PropTypes.number.isRequired,
  key: PropTypes.number,
  message: PropTypes.string,
};

export default SnackMessage;

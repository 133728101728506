import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  Typography,
  FormControlLabel,
  Tooltip,
  Avatar,
  Hidden
} from '@material-ui/core';
import Label from '@material-ui/icons/Label';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      //   backgroundColor: theme.palette.action.hover
    },
    '&:focus > $content, &$selected > $content': {
      color: 'black'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    color: '#84be40'
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  formControl: {
    marginLeft: 'auto!important'
  },
  disabled: {
    background: '#bdbdbd!IMPORTANT'
  }
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const user = useSelector((state) => state.system.USER_DATA);
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    type,
    node,
    id,
    handleChange,
    handleClickDelete,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const download = createRef();
  return (
    <TreeItem
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon
            className={classes.labelIcon}
            color="inherit" />
          <Typography
            component="p"
            variant="h4">
            {labelText}
            <br />
            <Typography
              component="span"
              variant="body2">
              {!labelInfo ? null : new Date(labelInfo).toLocaleString('ru')}
            </Typography>
          </Typography>
          <div className={classes.formControl} />
          <Hidden only="xs">
            {type === 'file' && (
              <FormControlLabel
                control={
                  <Tooltip
                    arrow
                    title="Скачать">
                    <div className={classes.avatar}>
                      <Avatar className="AvatarBg">
                        <Link
                          download
                          ref={download}
                          target="_blank"
                          to={node.uri}>
                          <GetAppOutlinedIcon />
                        </Link>
                      </Avatar>
                    </div>
                  </Tooltip>
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
            {user.role === 'CHAIRMAN' && (
              <>
                <FormControlLabel
                  control={
                    <Tooltip
                      arrow
                      title="Редактировать">
                      <div className={classes.avatar}>
                        <Avatar className="AvatarBg">
                          <EditIcon />
                        </Avatar>
                      </div>
                    </Tooltip>
                  }
                  onClick={(e) => {
                    handleChange(e, node, type);
                  }}
                />
                <FormControlLabel
                  control={
                    <Tooltip
                      arrow
                      title="Удалить">
                      <div>
                        <Avatar
                          className={`AvatarBg ${
                            node.children !== undefined &&
                            node.children.length > 0
                              ? classes.disabled
                              : ''
                          }`}>
                          <DeleteOutlineOutlinedIcon />
                        </Avatar>
                      </div>
                    </Tooltip>
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      node.children !== undefined &&
                      node.children.length > 0
                    ) {
                      return enqueueSnackbar(
                        'В разделе есть сохраненные элементы!',
                        {
                          variant: 'error'
                        }
                      );
                    }
                    console.log('id :>> ', id);
                    handleClickDelete(id, node.name, type, node.uri);
                  }}
                />
              </>
            )}
          </Hidden>
          {(type === 'file' || user.role === 'CHAIRMAN') && (
            <Hidden smUp>
              <IconButton
                aria-controls="long-menu"
                aria-haspopup="true"
                aria-label="more"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickMenu(e);
                }}
                style={{ color: '#84be40' }}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="long-menu"
                keepMounted
                onClose={handleCloseMenu}
                open={open}>
                {type === 'file' && (
                  <MenuItem
                    key={1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCloseMenu();
                    }}>
                    Скачать
                  </MenuItem>
                )}

                {user.role === 'CHAIRMAN' && (
                  <MenuItem
                    key={2}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(e, node, type);
                      handleCloseMenu();
                    }}>
                    Редактировать
                  </MenuItem>
                )}
                {user.role === 'CHAIRMAN' && (
                  <MenuItem
                    key={3}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCloseMenu();
                      if (node.children.length > 0) {
                        return enqueueSnackbar(
                          'В разделе есть сохраненные элементы!',
                          {
                            variant: 'error'
                          }
                        );
                      }
                      handleClickDelete(id, node.name, type, node.uri);
                    }}>
                    Удалить
                  </MenuItem>
                )}
              </Menu>
            </Hidden>
          )}
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

export default ({ data, trigger, edit, close, open, handleClickDelete }) => {
  const classes = useStyles();

  const handleChange = (e, panel, type) => {
    edit(panel, type);
    trigger ? close() : open(type);
  };

  const renderTree = (nodes, handleChange, handleClickDelete) => {
    nodes.type = !nodes.uri ? 'dir' : 'file';
    if (!Array.isArray(nodes)) {
      switch (nodes.type) {
        case 'dir':
          return (
            <StyledTreeItem
              handleChange={handleChange}
              handleClickDelete={handleClickDelete}
              id={nodes.id}
              key={nodes.id}
              labelIcon={Label}
              labelText={nodes.name}
              node={nodes}
              nodeId={`dir_${nodes.id}`}
              type={nodes.type}>
              {Array.isArray(nodes.children)
                ? nodes.children.map((nodes) =>
                  renderTree(nodes, handleChange, handleClickDelete)
                )
                : null}
            </StyledTreeItem>
          );
        case 'file':
          return (
            <StyledTreeItem
              color="#1a73e8"
              handleChange={handleChange}
              handleClickDelete={handleClickDelete}
              id={nodes.doc_id}
              key={nodes.doc_id}
              labelIcon={DescriptionIcon}
              labelInfo={nodes.date}
              labelText={nodes.name}
              node={nodes}
              nodeId={`file_${nodes.doc_id}`}
              type={nodes.type}
            />
          );

        default:
          return;
      }
    }

    return nodes.map((node) => {
      node.type = !node.uri ? 'dir' : 'file';
      switch (node.type) {
        case 'dir':
          return (
            <StyledTreeItem
              handleChange={handleChange}
              handleClickDelete={handleClickDelete}
              id={node.id}
              key={node.id}
              labelIcon={Label}
              labelText={node.name}
              node={node}
              nodeId={`dir_${node.id}`}
              type={node.type}>
              {Array.isArray(node.children)
                ? node.children.map((node) =>
                  renderTree(node, handleChange, handleClickDelete)
                )
                : null}
            </StyledTreeItem>
          );
        case 'file':
          return (
            <StyledTreeItem
              color="#1a73e8"
              handleChange={handleChange}
              handleClickDelete={handleClickDelete}
              id={node.doc_id}
              key={node.doc_id}
              labelIcon={DescriptionIcon}
              labelInfo={node.date}
              labelText={node.name}
              node={node}
              nodeId={`file_${node.doc_id}`}
              type={node.type}
            />
          );

        default:
          return;
      }
    });
  };
  return (
    <>
      {(data.length == 0 || data == 'no docs') ? (
        <Typography
          align="center"
          color="textSecondary"
          variant="h5">
          Прикрепляйте файлы и делитесь ими с участниками садоводства
        </Typography>
      ) : (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          defaultExpandIcon={<ArrowRightIcon />}>
          {renderTree(data, handleChange, handleClickDelete)}
        </TreeView>
      )}
    </>
  );
};

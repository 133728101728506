import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import Avatar from '@material-ui/core/Avatar'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'
import { PlotDetailsWrapper, PlotDetailsInfo } from './PlotDetails.style'
import {DetailsContext} from '../../PlotsPage';

export const PlotDetails = () => {
  const plots = useSelector(state => state.plots.plots)


  const value = useContext(DetailsContext);
  const [plot, setPlot] = useState({})

  useEffect(() => {
    // const id = value.plot;
    // const plot = plots.filter(plot => +plot.house === +id)[0]
    const id = value.id;
    const plot = plots.filter(plot => +plot.id === +id)[0]
    setPlot(plot)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <PlotDetailsWrapper>
      <Avatar
        className="plotAvatar"
        src=""
        variant="square"
      >
        <PhotoCameraOutlinedIcon />
      </Avatar>
      <PlotDetailsInfo>
        <span><strong>Адрес:</strong> {plot.parcel || 'нет данных'} </span>
        <span><strong>Координаты:</strong> {plot.position || 'нет данных'} </span>
        {plot.square ?
          <span><strong>Площадь:</strong> {plot.square}м<sup>2</sup> </span> :
          <span><strong>Площадь:</strong> нет данных </span>
        }
      </PlotDetailsInfo>
    </PlotDetailsWrapper>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Collapse,
  Grid,
  TextField,
  CardActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useHttp } from '../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  form: {
    paddingTop: 20,
    paddingBottom: 30
  },
  close: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: '#84be41'
  },
  save: {
    marginLeft: '-24px'
  }
}));

const initialState = { name: '', assets: null, id_parent: '0' };

const DirAdd = ({ open, flag, trigger, clear, update, close, dirs }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { request } = useHttp();
  const user = useSelector((state) => state.system.USER_DATA);
  //   const dispatch = useDispatch();
  const [form, setForm] = useState({ name: '', assets: null, id_parent:'0' });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const sentFilesAllData = useCallback(async () => {
    if (!form.id) {
      const data = await request('/documents/add/group', 'POST', {
        name: form.name,
        user_id: user.id,
        group: user.group,
        id_parent: form.id_parent,
        level: form.id_parent == 0 ? 0 : dirs.filter(el => el.id == form.id_parent)[0].level
      });
      if (!data.result) {
        enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
      }
      enqueueSnackbar('Файл успешно добавлен', { variant: 'success' });
    } else {
      const data = await request('/documents/update/group ', 'POST', {
        id: form.id,
        name: form.name,
        user_id: user.id,
        group: user.group,
        id_parent: form.id_parent,
        level: form.id_parent == 0 ? 0 : dirs.filter(el => el.id == form.id_parent)[0].level
      });
      if (!data.result) {
        enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
      }
      enqueueSnackbar('Раздел успешно отредактирован', { variant: 'success' });
    }
    update();

    setTimeout(() => {
      close();
      clear();
    }, 100);
  }, [form, close, clear, trigger]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sentFilesAllData();
  };
  useEffect(() => {
    if (flag === 'dir') {
      return setForm({ ...initialState });
    }

    if (trigger && open) {
      const dir = { ...trigger };

      setForm({
        ...dir
      });
    }
  }, [trigger, flag, open]);
  return (
    <Card className={classes.root}>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit>
        <form
          className={classes.form}
          onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              md={4}
              xs={12}>
              <TextField
                fullWidth
                label="Название раздела"
                name="name"
                onChange={handleChange}
                required
                value={form.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}>
              <FormControl
                className={classes.formControl}
                fullWidth
                variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Подраздел
                </InputLabel>
                <Select
                  fullWidth
                  id="demo-simple-select-outlined"
                  label="Сделать подразделом"
                  name="id_parent"
                  onChange={handleChange}
                  value={form.id_parent + ''}>
                  <MenuItem value={0}>
                    <em>Без раздела</em>
                  </MenuItem>
                  {dirs.map((el) => {
                    if (el.level < 2 && el.id !== form.id) return <MenuItem
                      key={el.id}
                      value={el.id + ''}>{el.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {user.role === 'CHAIRMAN' && (
            <CardActions>
              <Button
                className={classes.save}
                color="secondary"
                type="submit"
                variant="contained">
                Сохранить
              </Button>
            </CardActions>
          )}
        </form>
      </Collapse>
    </Card>
  );
};

export default DirAdd;

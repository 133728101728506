import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';

import Aux from '../../layouts/Auxilary';

import { AddressPage } from './components/AddressPage/AddressPage';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

export const GardenerPage = () => {
  const user = useSelector((state) => state.system.USER_DATA);
  const classes = useStyles();
  const { tab, id } = useParams();
  const history = useHistory();

  const handleTabsChange = (value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'address', label: 'Адрес/Реквизиты' },
    // { value: 'types', label: 'Виды Счетчиков' },
    // { value: 'counters', label: 'Счетчики садоводства' },
    // { value: 'tariffs', label: 'Предоставляемые тарифы' }
  ];
  if (user.role === 'CHAIRMAN') {
    tabs.push(
      { value: 'types', label: 'Виды Счетчиков' },
      { value: 'counters', label: 'Счетчики садоводства' },
      { value: 'tariffs', label: 'Предоставляемые тарифы' }
    );
  }

  if (!tab) {
    return <Redirect to="/garden/address" />;
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Aux
      description="Настройки садоводства"
      name="Садоводство">
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map((tab, idx) => (
          <Tab
            disabled={idx > 0 && !id}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'address' && <AddressPage />}
      </div>
    </Aux>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Aux from '../../layouts/Auxilary';
import { MarginWrap } from './DashboardGroups.style';

import FullScreenDialog from './components/FullScreenDialog/FullScreenDialog';

import { useHttp } from '../../hooks/http.hook';
import { GroupsWrapper } from './components/GroupsWrapper/GroupsWrapper';
import { useSnackbar } from 'notistack';
import SnackMessage from './components/SnackMessage';

const mapStateToProps = (state) => {
  return {
    plotGroupId: state.system.USER_DATA.group,
    role: state.system.USER_DATA.role
  };
};
const DashboardGroup = connect(
  mapStateToProps,
  null
)(({ role, plotGroupId }) => {
  if (role !== 'CHAIRMAN') return <Redirect to="/error-404" />;
  const { enqueueSnackbar } = useSnackbar();

  const { request } = useHttp();

  const [groups, setGroups] = useState([]);
  // const [type, setType] = useState([]);
  const [devices, setDevices] = useState({});
  const [filteredGroups, setFilteredGroups] = useState({});

  const getGroupsDataHandler = useCallback(async () => {
    const res = await request('/counters/get/grouping', 'POST', {
      id: plotGroupId
    });
    if (!res.status) return;

    setGroups(res.result);
    setFilteredGroups({});
  }, []);

  const deleteGroupHandler = async (id) => {
    const res = await request('/counters/delete/grouping', 'POST', {
      grouping: id
    });
    if (!res.status) {
      enqueueSnackbar('Что-то пошло не так...', {
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Группа успешно удалена!', {
        variant: 'success'
      });
    }
    getGroupsDataHandler();
  };

  const searchGroupsHandler = (e) => {
    const value = e.target.value;
    const filteredGroups = groups.filter((group) => {
      const name = group.name.split(' ').join('').toLowerCase();

      const search = value.toString().split(' ').join('').toLowerCase();

      const str = `${name}`;

      return str.includes(search);
    });

    setFilteredGroups(filteredGroups);
  };
  // const getTypesDataHandler = useCallback(async () => {
  //   const res = await request('/counters/get/types ', 'POST', {});
  //   if (!res.status) return;

  //   setType(res.result);
  // }, []);
  const getDevicesDataHandler = async (grouping, type) => {
    const res = await request('/counters/get/grouping/devices', 'POST', {
      id: plotGroupId,
      grouping,
      type
    });
    if (!res.status) return;
    setDevices({ ...devices, [grouping]: res.result });
  };

  const handleClickDelete = (id, name) => {
    const message = `Вы действительно хотите удалить группу "${name.toUpperCase()}"`;

    const action = (id) => {
      deleteGroupHandler(id);
    };
    enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: 3000,
      // eslint-disable-next-line
      content: (key, message) => (
        <SnackMessage
          action={action}
          id={id}
          key={key}
          message={message} />
      )
    });
  };

  useEffect(() => {
    getGroupsDataHandler();
    // getTypesDataHandler();
  }, [getGroupsDataHandler]);
  return (
    <Aux
      description="Управление группами"
      name="Управление группами">
      <MarginWrap>
        <FullScreenDialog
          searchGroupsHandler={searchGroupsHandler}
          updateGroups={getGroupsDataHandler}
        />
      </MarginWrap>
      <MarginWrap>
        <GroupsWrapper
          devices={devices}
          getDevicesDataHandler={getDevicesDataHandler}
          getGroupsDataHandler={getGroupsDataHandler}
          groups={filteredGroups.length > 0 ? filteredGroups : groups}
          handleClickDelete={handleClickDelete}
        />
      </MarginWrap>
    </Aux>
  );
});
export default DashboardGroup;

import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getChart} from '../../../../../../redux/actions';
import {SelectContext} from '../../../../../DashboardCounter/DashboardCounter';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    padding: '5px 13px'
  }
})); 

// eslint-disable-next-line react/prop-types
export const TableViewSelect = () => {
  const classes = useStyles();
  const {name} = useParams();
  const dispatch = useDispatch();
  const chart = useSelector(state => state.chart);

  const context = useContext(SelectContext);


  const changeSelectHandler = e => {
    if (!name) return dispatch(getChart({...chart, select: e.target.value, day: chart.check ? 'day' : 'night'}));

    context.updateSelectViewHandler(e.target.value)
  }

  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
    >
      <Select
        inputProps={{
          className: classes.select
        }}
        native
        onChange={changeSelectHandler}
        value={!name ? chart.select : context.params.select}
      >
        <option value="kvt">кВт·ч</option>
        <option value="rub">Руб</option>
      </Select>
    </FormControl>
  );
};

export const initialStateDialog = {
  role: '',
  part: '',
  contact: true,
  second_name: '',
  first_name: '',
  last_name: '',
  birthDate: null,
  inn: '',
  snils: '',
  passportSeries: '',
  passportNumber: '',
  issued_code:'',
  issuedDate: null,
  issued: '',
  birthPlace: '',
  address:'',
  region: '',
  city: '',
  street: '',
  house: '',
  apartments: '',
  addressFact:'',
  regionFact: '',
  cityFact: '',
  streetFact: '',
  houseFact: '',
  apartmentsFact: '',
  addrChecker: false,
  phone: '',
  email: '',
  telegram: '',
  watsapp: '',
  viber: '',
  skype: '',
  vk: '',
  instagram: '',
}

import styled from 'styled-components'

export const WidgetMainInfo = styled.main`
    display: flex;
    align-items: center;
    font-size: 1.5em;
    text-align: center;
    width: min-content;
`;

export const WidgetInfoText = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-align: center;
`;

import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  createContext
} from 'react';
import { useParams } from 'react-router';
import { useHttp } from '../../../../hooks/http.hook';
import { SelectContext } from '../../DashboardCounter';
import { CountersTableDialog } from './CountersTableDialog';

import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';

import {
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid';

import {
  pagingPanelMessages,
  PlotsWrapper,
  tableMessages,
  TemplateWrapper
} from '../../../../utils/settings/settings';
import { Template } from '@devexpress/dx-react-core';

import { Paper } from '@material-ui/core';
import { CountersTableMenu } from './CountersTableMenu';

export const DataContext = createContext(undefined);

// eslint-disable-next-line react/prop-types
export const CountersTable = ({ per }) => {
  const { request } = useHttp();
  const params = useParams();
  const context = useContext(SelectContext);
  const [counters, setCounters] = useState([]);

  // eslint-disable-next-line react/no-multi-comp
  const counterColumnHandler = (day, night, rub = false) => {
    return (
      <>
        <span>
          День:{' '}
          <strong>
            {day !== undefined && day.toString().length
              ? `${day} ${rub ? '₽' : ''}`
              : 'Нет данных'}
          </strong>
        </span>
        <br />
        <span>
          Ночь:{' '}
          <strong>
            {night !== undefined && night.toString().length
              ? `${night} ${rub ? '₽' : ''}`
              : 'Нет данных'}
          </strong>
        </span>
        <br />
      </>
    );
  };

  const getCountersDataHandler = useCallback(async () => {
    const response = await request(
      '/counters/period/id',
      'POST',
      context.params
    );
    if (!response.status) return;

    const { result } = response;
    const rows = [];
    let finalSum = 0;
    let finalDay = 0;
    let finalNight = 0;

    result
      .map((item) => {
        // if(new Date().getDate() !== new Date(item.DATE).getDate()){
        rows.push({
          date: `${new Date(item.DATE).toLocaleDateString('ru', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}`,
          eui: item.eui || 'Нет данных',
          meter: counterColumnHandler(
            item.day_indication,
            item.night_indication,
            false
          ),
          consumption:
            context.params.select === 'kvt'
              ? counterColumnHandler(
                item.day_consumption,
                item.night_consumption,
                false
              )
              : counterColumnHandler(
                item.price_day_consumption,
                item.price_night_consumption,
                true
              ),
          sum: +item.price_summ,
          day: +item.day_consumption,
          night: +item.night_consumption
        });

        finalDay += +item.day_consumption;
        finalNight += +item.night_consumption;
        finalSum += +item.price_summ;
        return true;
        // } else {
        //   return null
        // }
      })
      // .filter(Boolean);

    setFinal({ ...final, finalDay, finalNight, finalSum, per });
    setCounters(() => [...rows]);
    // setInitCounters(rows);
  }, [context, request, per]);

  useEffect(() => {
    getCountersDataHandler();
  }, [getCountersDataHandler]);

  const [final, setFinal] = useState({
    finalSum: 0,
    finalDay: 0,
    finalNight: 0,
    per: {}
  });
  // const [initCounters, setInitCounters] = useState([]);

  const [columns] = useState([
    { name: 'date', title: 'Дата' },
    { name: 'eui', title: '№ счетчика' },
    { name: 'meter', title: 'Показания' },
    { name: 'consumption', title: 'Потребление' }
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'date', width: 130 },
    { columnName: 'eui', width: 200 },
    { columnName: 'meter', width: 140 },
    { columnName: 'consumption', width: 170 }
  ]);

  // const searchStateHandler = e => {
  //   const value = e.target.value;
  //   if (value.length) {
  //     const filteredCounters = initCounters.filter(item => {
  //       const search = value.toLowerCase();
  //       const date = item.date ? item.date.split('.').join('') : '';
  //       const name = item.eui.split(' ').join('').toLowerCase();
  //       const day_consumption = item.consumption.props.children[0].props.children[1].props.children ? item.consumption.props.children[0].props.children[1].props.children.replace(/\D+/g,'').split(' ').join('') : ''
  //       const night_consumption = item.consumption.props.children[2].props.children[1].props.children ? item.consumption.props.children[2].props.children[1].props.children.replace(/\D+/g,'').split(' ').join('') : ''
  //       const day_indication = item.meter.props.children[0].props.children[1].props.children ? item.meter.props.children[0].props.children[1].props.children.replace(/\D+/g,'').split(' ').join('') : ''
  //       const night_indication = item.meter.props.children[2].props.children[1].props.children ? item.meter.props.children[2].props.children[1].props.children.replace(/\D+/g,'').split(' ').join('') : ''
  //
  //       const str = `${date}${name}${day_indication}${night_indication}${day_consumption}${night_consumption}`
  //
  //       return str.includes(search)
  //     })
  //     setCounters(filteredCounters);
  //   } else {
  //     setCounters(initCounters);
  //   }
  // };

  return (
    <DataContext.Provider value={final}>
      <PlotsWrapper className="PlotsTable">
        <Paper className="mb40">
          <Grid
            columns={columns}
            rows={counters}>
            <SortingState
              defaultSorting={[{ columnName: 'marker', direction: 'asc' }]}
            />
            <PagingState
              defaultCurrentPage={0}
              pageSize={5} />
            <IntegratedSorting sorting={['marker', 'plot_number']} />
            <IntegratedPaging />
            <IntegratedFiltering />
            <Table
              columnExtensions={tableColumnExtensions}
              messages={tableMessages}
            />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <Template name="toolbarContent">
              <TemplateWrapper>
                {!params.auto && (
                  <CountersTableDialog
                    id={params.id}
                    update={getCountersDataHandler}
                  />
                )}
              </TemplateWrapper>
              <div className="TableControl">
                {/*<TextField*/}
                {/*  className="PaddingInput"*/}
                {/*  InputProps={{*/}
                {/*    startAdornment: (*/}
                {/*      <InputAdornment position="start">*/}
                {/*        <SearchIcon />*/}
                {/*      </InputAdornment>*/}
                {/*    ),*/}
                {/*    placeholder: 'Найти...'*/}
                {/*  }}*/}
                {/*  onChange={searchStateHandler}*/}
                {/*  variant="outlined"*/}
                {/*/>*/}
                <CountersTableMenu />
              </div>
            </Template>
            <PagingPanel messages={pagingPanelMessages} />
          </Grid>
        </Paper>
      </PlotsWrapper>
    </DataContext.Provider>
  );
};

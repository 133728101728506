import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { Page } from 'components';
import gradients from 'utils/gradients';
import { LoginForm } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  media: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    right: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32
  },
  loginForm: {
    marginTop: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  person: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: '0',
    width: 50,
    height: 50,
    background: 'none'
  }
}));

const style = {
  background: 'url("/images/auth.png") no-repeat center',
  backgroundSize: 'cover'
};

const Login = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      style={style}
      title="Вход"
    >
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image="/images/auth.png"
          title="Cover"
        >
          <div className={classes.person}>
            <Avatar
              alt="Person"
              className={classes.avatar}
              variant="square"
            >
              <img
                alt=""
                className="AuthLogo"
                src="/images/logo.png"
              />
            </Avatar>
            <Typography
              color="inherit"
              variant="h2"
            >
              Садоводство
            </Typography>
          </div>
        </CardMedia>
        <CardContent className={classes.content}>
          <LockIcon className={classes.icon} />
          <Typography
            gutterBottom
            variant="h3"
          >
            Вход
          </Typography>
          <LoginForm className={classes.loginForm} />
        </CardContent>
      </Card>
    </Page>
  );
};

export default Login;

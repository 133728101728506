import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TableViewSelect } from '../DashboardTable/components/TableSelect/TableSelect';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { CardContent, Card, Typography } from '@material-ui/core';

import {
  ControlPanelWrap,
  ContentWrap,
  PickerWrap,
  CalendarWrap,
  ArrowWrap
} from './ControlPanel.style';

// eslint-disable-next-line react/prop-types
export const ControlPanel = ({ period, update }) => {
  // const { tab } = useParams();
  // console.log('tab :>> ', tab);
  // eslint-disable-next-line react/prop-types
  const { start_date, end_date } = period;
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  return (
    <ControlPanelWrap>
      <Card>
        <CardContent className="ControlPanel">
          <ContentWrap>
            <Typography
              component="main"
              variant="h6"
            >
              Выбор периода: &nbsp;
            </Typography>
            <PickerWrap>
              <CalendarWrap>
                <Typography
                  className="textGreen REF"
                  component="span"
                  variant="h5"
                >
                  {`${new Date(start_date).toLocaleString('ru', {
                    day: 'numeric',
                    month: 'short'
                  })} ${new Date(start_date).getFullYear()}`}
                </Typography>
                <KeyboardDatePicker
                  autoOk
                  cancelLabel={'Отмена'}
                  className="DashboardDatepicker"
                  maxDate={end_date}
                  maxDateMessage={false}
                  onChange={(e) => update(e, 'start_date')}
                  value={start_date}
                />
              </CalendarWrap>
              <ArrowWrap>
                <Typography
                  className="textGreen"
                  component="span"
                  variant="h5"
                >
                  <ArrowRightAltIcon />
                </Typography>
              </ArrowWrap>
              <CalendarWrap>
                <Typography
                  className="textGreen REF"
                  component="span"
                  variant="h5"
                >
                  {`${new Date(end_date).toLocaleString('ru', {
                    day: 'numeric',
                    month: 'short'
                  })} ${new Date(end_date).getFullYear()}`}
                </Typography>
                <KeyboardDatePicker
                  autoOk
                  cancelLabel={'Отмена'}
                  className="DashboardDatepicker REF"
                  maxDate={new Date(y, m + 1, 0)}
                  minDate={start_date}
                  minDateMessage={false}
                  onChange={(e) => update(e, 'end_date')}
                  value={end_date}
                />
              </CalendarWrap>
            </PickerWrap>
          </ContentWrap>

          <ContentWrap>
            <Typography
              component="main"
              variant="h6"
            >
              Отобразить в: &nbsp;
            </Typography>
            <PickerWrap>
              <CalendarWrap>
                <TableViewSelect />
              </CalendarWrap>
            </PickerWrap>
          </ContentWrap>
        </CardContent>
      </Card>
    </ControlPanelWrap>
  );
};

import React, {useCallback, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import gradients from '../../../../utils/gradients';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import {useHttp} from '../../../../hooks/http.hook';
import {useSnackbar} from 'notistack';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
  },
  header: {
    paddingBottom: 0,
  },
  card: {
    height: '100%'
  },
  auto: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0',
    height: '100%'
  },
  metrics: {
    display: 'inline-flex',
    alignItems: 'flex-end'
  },
  flow: {
    textAlign: 'center',
  },
  avatar: {
    backgroundImage: gradients.green,
  },
}));

const AutoWrap = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

// eslint-disable-next-line react/prop-types
export const CounterCard = ({counter, auto, name, day, night, serial, update, date}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {request} = useHttp();
  const classes = useStyles();

  const [form, setForm] = useState({
    device_id: counter,
    day_date: Date.now(),
    energy_tariff1: day,
    energy_tariff2: night,
  });

  const changeFormHandler = e => {
    setForm({...form , [e.target.name]:  e.target.value})
  };

  const uploadDatHandler = useCallback(async () => {
    if (!form.day_date) return;

    const response = await request('/counters/insert/manual', 'POST', form);

    if (!response.status) return;

    const status = response.status ? 'success' : 'error';
    const message = response.status ? 'Данные отправлены' : 'Что-то пошло не так...'
    enqueueSnackbar(message, { variant: status })

    update()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, enqueueSnackbar]);

  const parsedDate = nowDate => {
    if (!nowDate) return;

    const _ = new Date(nowDate);
    return _.toLocaleString('ru').split(',')[0];
  };
  
  const submitFormHandler = (e) => {
    e.preventDefault();
    uploadDatHandler();
  };

  const getLastDate = dateNow => {
    const _ = new Date(dateNow)
    return _.toLocaleString('ru', { timeZone: 'UTC' })
  }

  return (
    <form
      className={classes.card}
      onSubmit={submitFormHandler}
    >
      <Card
        className={classes.card}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
            >
              <FlashOnOutlinedIcon />
            </Avatar>
          }
          className={classes.header}
          title={
            <Typography
              color="textSecondary"
              variant="h5"
            >
              Электроэнергия. Показания
            </Typography>
          }
        />
        <CardContent
          className={classes.root}
        >
          <Grid
            container
            justify="center"
            spacing={2}
          >
            <Grid
              className={classes.flow}
              item
              xs={12}
            >
              <Typography
                color="secondary"
                variant="h4"
              >{name}</Typography>
            </Grid>
            <Grid
              className={classes.flow}
              item
              xs={12}
            >
              <Typography variant="body1">Счетчик №{serial}</Typography>
            </Grid>
            {
              auto ?
                <>
                  <Grid
                    className={classes.auto}
                    item
                    xs={12}
                  >
                    <Typography variant="h5">День: </Typography>
                    <AutoWrap>
                      <span className={classes.metrics}>
                        <Typography variant="h2">{day}&nbsp;</Typography>
                        <Typography variant="body1">кВт·ч</Typography>
                      </span>
                      <Typography variant="caption">
                        {getLastDate(date)}
                      </Typography>
                    </AutoWrap>

                  </Grid>
                  <Grid
                    className={classes.auto}
                    item
                    xs={12}
                  >
                    <Typography variant="h5">Ночь: </Typography>
                    <AutoWrap>
                      <span className={classes.metrics}>
                        <Typography variant="h2">{night}&nbsp;</Typography>
                        <Typography variant="body1">кВт·ч</Typography>
                      </span>
                      <Typography variant="caption">
                        {getLastDate(date)}
                      </Typography>
                    </AutoWrap>
                  </Grid>
                </> :
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      helperText={parsedDate(date)}
                      label="День"
                      name="energy_tariff1"
                      onChange={changeFormHandler}
                      required
                      type="number"
                      value={form.energy_tariff1}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      helperText={parsedDate(date)}
                      label="Ночь"
                      name="energy_tariff2"
                      onChange={changeFormHandler}
                      required
                      type="number"
                      value={form.energy_tariff2}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </>
            }
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  ControlPanelWrap,
  ContentWrap,
  PickerWrap,
  CalendarWrap
} from './GroupingPanel.style';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Card, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useHttp } from '../../../../hooks/http.hook';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    padding: '5px 13px'
  }
}));

export const GroupingPanel = ({ update }) => {
  const user = useSelector((state) => state.system.USER_DATA);
  const classes = useStyles();
  const { request } = useHttp();
  const [groups, setGroups] = useState([]);
  const history = useHistory();

  const getGroupsDataHandler = useCallback(async () => {
    if (user.role !== 'CHAIRMAN') return;
    const res = await request('/counters/get/grouping', 'POST', {
      id: user.group
    });
    if (!res.status) return;

    setGroups(res.result);
  }, []);
  const handleClick = () => {
    history.push('/dashboards/groups');
  };
  const filterGroup = (groups) => {
    return groups.filter((group) => {
      if (group.id_type !== undefined && group.id_type == 1) {
        return group;
      }
    });
  };

  useEffect(() => {
    getGroupsDataHandler();
    // getTypesDataHandler();
  }, [getGroupsDataHandler]);
  return (
    <>
      {user.role === 'CHAIRMAN' && (
        <ControlPanelWrap>
          <Card>
            <CardContent>
              <ContentWrap>
                <Typography
                  component="main"
                  variant="h6">
                  Показать: &nbsp;
                </Typography>
                <PickerWrap>
                  <CalendarWrap>
                    <FormControl
                      className={classes.formControl}
                      variant="outlined">
                      <Select
                        inputProps={{
                          className: classes.select
                        }}
                        native
                        onChange={update}>
                        <option value="">Все</option>
                        {filterGroup(groups).map((group) => (
                          <option
                            key={group.id}
                            value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </CalendarWrap>
                </PickerWrap>
                <PickerWrap>
                  <CalendarWrap>
                    <Button
                      color="primary"
                      onClick={handleClick}>
                      Управлять группами
                    </Button>
                  </CalendarWrap>
                </PickerWrap>
              </ContentWrap>
            </CardContent>
          </Card>
        </ControlPanelWrap>
      )}
    </>
  );
};
GroupingPanel.propTypes = {
  update: PropTypes.function
};

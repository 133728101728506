/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Link } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';

import { useSnackbar } from 'notistack';
import { useHttp } from '../../../../hooks/http.hook';
import { systemLogin } from '../../../../redux/actions';
import theme from '../../../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 60
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%'
  }
}));

// eslint-disable-next-line react/prop-types
const LoginForm = ({ systemLogin }) => {
  const classes = useStyles();

  const { loading, request } = useHttp();
  const { enqueueSnackbar } = useSnackbar();
  const [width, setWitdh] = useState(window.innerWidth);

  useEffect(() => {
    setWitdh(window.innerWidth);
  }, [window.innerWidth]);

  const [form, setForm] = useState({
    phone: ''
  });

  const changeFormHandler = (value, name) => {
    if (name === 'phone') value = value.replace(/\D+/g, '');
    setForm({ ...form, [name]: value });
  };

  const loginHandler = async () => {
    const data = await request('/login', 'POST', { ...form });
    const status = data.status ? 'success' : 'error';

    if (!data.status)
      return enqueueSnackbar(data.message, {
        variant: status,
        anchorOrigin: {
          vertical: width > 500 ? 'bottom' : 'top',
          horizontal: 'left'
        }
      });

    const { user } = data;

    const addrCheck = user.addrChecker === 1 || user.addrChecker === 'Y';
    const contCheck = user.contact === 1 || user.contact === 'Y';

    const fetchedData = { ...user, addrChecker: addrCheck, contact: contCheck };
    const newForm = {};

    Object.keys(fetchedData).map((item) => {
      if (fetchedData[item] !== null) {
        return (newForm[item] = fetchedData[item]);
      } else {
        return (newForm[item] = '');
      }
    });

    systemLogin({ TOKEN: data.token, USER_DATA: newForm });
    enqueueSnackbar(data.message, { variant: status });
    if (localStorage.getItem('theme')) return;

    const theme = {
      theme: 'WHITE',
      check: true
    };
    localStorage.setItem('theme', JSON.stringify(theme));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginHandler();
  };

  return (
    <form 
      className={clsx(classes.root)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <PhoneInput
          country={'ru'}
          countryCodeEditable={false}
          disableDropdown
          onChange={(e) => changeFormHandler(e, 'phone')}
          value={form.phone}
        />
        <TextField
          fullWidth
          label="Пароль"
          onChange={(e) => changeFormHandler(e.target.value, 'password')}
          type="password"
          value={form.password}
          variant="outlined"
        />
      </div>
      <Button
        className={classes.submitButton}
        color="secondary"
        disabled={loading}
        size="large"
        type="submit"
        variant="contained"
      >
        Войти
      </Button>
      <Link 
        component={RouterLink} 
        to="/reset" 
        underline="hover"
      >
        Забыли пароль?
      </Link>
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  systemLogin
};

export default connect(null, mapDispatchToProps)(LoginForm);

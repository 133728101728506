import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { useHttp } from '../../hooks/http.hook';
import { useSnackbar } from 'notistack';

const AuthVideo = () => {
  const location = useLocation();
  const { request } = useHttp();
  const history = useHistory();
  const user = useSelector((state) => state.system.USER_DATA);
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  //   const id = query.get('id');
  //   console.log('code', code);

  let data = [];

  for (let params of query.entries()) {
    data.push({ name: params[0], value: params[1] });
  }
  const dataHandler = useCallback(async (code) => {
    // const userId = user.role === 'CHAIRMAN' ? user.group : user.id;
    const response = await request('/oauth/token', 'POST', { id:user.id, groupId:user.group, code: code });
    console.log('response', response)

    if (!response.status) return;

    enqueueSnackbar('Успешно', { variant: status })
    history.push('');
  }, [request]);

  console.log('dara', data);
  useEffect(() => {
    if (code !== undefined) {
      dataHandler(code);
    }
  }, query);
  return (
    <>
      <h1>AuthVideo</h1>
      {data.map((el) => {
        return (
          <p>
            {el.name}: {el.value}
          </p>
        );
      })}
    </>
  );
};
export default AuthVideo;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';

import Aux from '../../layouts/Auxilary';

import { useParams, useHistory } from'react-router-dom'
import {DashboardElectric} from './components/DashboardElectric/DashboardElectric';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const DashboardDefault = () => {
  const classes = useStyles();
  const { tab } = useParams();
  const history = useHistory();

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'electric', label: 'Электроэнергия' },
    { value: 'water', label: 'Вода' },
    { value: 'gas', label: 'Газ' },
  ];

  if (!tab) {
    return <Redirect to="/dashboards/default/electric" />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Aux
      description="Показания"
      name="Показания"
    >
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map((tab, idx) => (
          <Tab
            disabled={idx > 0}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'electric'  && <DashboardElectric />}
      </div>
    </Aux>
  );
};

export default DashboardDefault;

import React, { useState, useCallback, useEffect } from 'react';

import { MarginWrap } from './NewsList.style';
import { Fab } from '@material-ui/core';
import { NewsWrapper } from './components/NewsWrapper/NewsWrapper';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHttp } from '../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';
import SnackMessage from './components/SnackMessage';

// const aloneNew = {
//   name: 'Реконструкция трассы P-23 54-80 км',
//   description:
//     '25.11.2019 будет завершено проектирование нового участка трассы P-23 с 54 до 80 киллометра. Начало строительства нового участка ожидается весной 2020 года.',
//   date: '16-10-2019'
// };
// const news = [
//   { ...aloneNew, id: 1 },
//   { ...aloneNew, id: 2 },
//   { ...aloneNew, id: 3 },
//   { ...aloneNew, id: 4 }
// ];
// console.log('news', news);
const NewsList = () => {
  const history = useHistory();
  const user = useSelector((state) => state.system.USER_DATA);
  const [news, setNews] = useState([]);
  const { request } = useHttp();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    history.push('/new/configure/main');
  };
  const getNewsHandler = useCallback(async () => {
    const userId = user.role === 'CHAIRMAN' ? user.group : user.id;
    const response = await request('/news/all', 'POST', { id: userId });
    const res = response.result;

    if (!response.status) {
      setNews([]);
      return;
    }

    setNews([...res]);
  }, [user, request]);

  const deleteNewsHandler = async (id) => {
    const res = await request('/news/delete', 'POST', {
      id: id
    });
    if (!res.status) {
      enqueueSnackbar('Что-то пошло не так...', {
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Новость успешно удалена!', {
        variant: 'success'
      });
    }
    getNewsHandler();
  };

  const handleClickDelete = (id, name) => {
    const message = `Вы действительно хотите удалить новость "${name.toUpperCase()}"`;

    const action = (id) => {
      deleteNewsHandler(id);
    };
    enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: 3000,
      // eslint-disable-next-line
      content: (key, message) => (
        <SnackMessage
          action={action}
          id={id}
          key={key}
          message={message} />
      )
    });
  };
  useEffect(() => {
    getNewsHandler();
  }, [getNewsHandler]);
  return (
    <>
      <MarginWrap>
        <div>
          <span>
            <Fab
              aria-label="add"
              className="BtnGreen"
              onClick={handleClickOpen}
              size="small">
              <AddIcon />
            </Fab>
            <span className="ButtonTitle">Добавить новость</span>
          </span>
        </div>
        {/* <FullScreenDialog /> */}
      </MarginWrap>
      <MarginWrap>
        <NewsWrapper
          handleClickDelete={handleClickDelete}
          news={news} />
      </MarginWrap>
    </>
  );
};
export default NewsList;

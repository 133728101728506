import React, {useCallback, useState} from 'react';
import {useHttp} from '../../../../hooks/http.hook';
import {useSnackbar} from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import {
  Typography,
  IconButton,
  Button,
  Dialog,
  Fab,
  Grid,
  TextField,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      className={classes.root}
      disableTypography
      {...other}
    >
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%'
  },
}))(MuiDialogContent);

// eslint-disable-next-line react/prop-types
export const CountersTableDialog = ({id, update}) => {
  const {request} = useHttp();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState({
    device_id: id,
    energy_tariff1: '',
    energy_tariff2: '',
    day_date: Date.now(),
    date: new Date().toLocaleDateString('ru', {day: '2-digit', month: '2-digit', year: 'numeric'}),
  })

  const changeFormHandler = e => {
    setData({...data, [e.target.name]: e.target.value})
  };

  const submitFormHandler = useCallback(async e => {
    e.preventDefault();

    const response = await request('/counters/insert/manual', 'POST', data);

    const status = response.status ? 'success' : 'error';
    const message = response.status ? 'Данные отправлены' : 'Что-то пошло не так...'
    enqueueSnackbar(message, { variant: status })

    setData({...data, energy_tariff1: '', energy_tariff2: ''})
    setOpen(false);
    update()
  }, [data, request, enqueueSnackbar, update])

  const theme = JSON.parse(localStorage.getItem('theme'))
  const setTheme = set => {
    if (set && set.theme === 'DARK') return 'DARK';
    return ''
  }

  return (
    <main
      className="CountersDialog"
    >
      <>
        <Fab
          aria-label="add"
          className="BtnGreen floatLeft"
          color="secondary"
          onClick={handleClickOpen}
          size="small"
        >
          <AddIcon />
        </Fab>
        <span className="ButtonTitle">Добавить показания</span>
      </>
      <Dialog
        aria-labelledby="customized-dialog-title"
        className={setTheme(theme)}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Добавление показаний
        </DialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={submitFormHandler}
          >
            <Grid
              container
              justify="center"
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  autoFocus
                  fullWidth
                  label="День"
                  name="energy_tariff1"
                  onChange={changeFormHandler}
                  required
                  type="number"
                  value={data.energy_tariff1}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Ночь"
                  name="energy_tariff2"
                  onChange={changeFormHandler}
                  required
                  type="number"
                  value={data.energy_tariff2}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  disabled
                  fullWidth
                  label="Дата"
                  name="energy_tariff2"
                  value={data.date}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              className="DialogCountersButton"
              color="primary"
              type="submit"
              variant="contained"
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </main>
  );
}

export const phoneView = phone => {
  //Filter only numbers from the input
  const cleaned = ('' + phone).replace(/\D/g, '');

  //Check if the input is of correct
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    let intlCode = (match[1] ? '+7 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], ' ', match[4], '-', match[5]].join('')
  }

  return null;
}

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Template } from '@devexpress/dx-react-core';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedFiltering,
  IntegratedPaging
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Toolbar,
  TableHeaderRow,
  PagingPanel,
  Table
} from '@devexpress/dx-react-grid-material-ui';

import {
  Typography,
  Paper,
  IconButton,
  LinearProgress
} from '@material-ui/core';

import {
  pagingPanelMessages,
  tableMessages,
  DashboardTemplateWrapper,
  DashboardNextWrap
} from '../../../../utils/settings/settings';

import {
  TableBodyItem,
  TableFooterBody,
  TableFooterHeader,
  TableFooterWrap
} from './DashboardTable.style';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { DashboardTableMenu } from './components/TableMenu/TableMenu';
import PDFButton from './components/PDFButton/PDFButton';

export const DashboardTable = () => {
  const history = useHistory();
  const params = useParams();
  const user = useSelector((state) => state.system.USER_DATA);
  const chart = useSelector((state) => state.chart);
  // const { request } = useHttp();

  const redirectHandler = useCallback(
    (id, auto) => {
      history.push(`/dashboards/${id}/${params.tab}${auto ? '/auto' : ''}`);
    },
    [history, params]
  );

  // eslint-disable-next-line react/no-multi-comp
  const editBtn = (id, auto) => {
    return (
      <IconButton onClick={() => redirectHandler(id, auto)}>
        <ArrowForwardIcon />
      </IconButton>
    );
  };

  const pdfBtn = (eui) => {
    return <PDFButton eui={eui} />;
  };

  // eslint-disable-next-line react/no-multi-comp
  const markerColumnHandler = (flag) => {
    return <i className={`ColorIndicator_${flag}`}>{flag}</i>;
  };

  // eslint-disable-next-line react/no-multi-comp
  const counterColumnHandler = (
    day,
    night,
    date,
    start,
    c = false,
    rub = false
  ) => {
    const _ = new Date(date);
    const _start = new Date(start);
    return (
      <>
        <span>
          День:{' '}
          <strong>
            {day !== undefined && day.toString().length
              ? `${day} ${rub ? '₽' : ''}`
              : 'Нет данных'}
          </strong>
        </span>
        <br />
        <span>
          Ночь:{' '}
          <strong>
            {night !== undefined && night.toString().length
              ? `${night} ${rub ? '₽' : ''}`
              : 'Нет данных'}
          </strong>
        </span>
        <br />
        {c ? (
          <span>
            С{' '}
            {`${_start.toLocaleDateString('ru', {
              day: 'numeric',
              month: 'short'
            })}` || 'Нет данных'}{' '}
            по{' '}
            {`${_.toLocaleDateString('ru', {
              day: 'numeric',
              month: 'short'
            })} ${_.getFullYear()}` || 'Нет данных'}
          </span>
        ) : (
          <span>
            На{' '}
            {`${_.toLocaleDateString('ru', {
              day: 'numeric',
              month: 'short'
            })} ${_.getFullYear()}` || 'Нет данных'}
          </span>
        )}
      </>
    );
  };

  const [loading, setLoading] = useState(false);
  const [sum, setSum] = useState({
    kvt: {
      day: 0,
      night: 0,
      all: 0
    },
    rub: {
      day: 0,
      night: 0,
      all: 0
    }
  });

  const final = {
    kvt: {
      day: 0,
      night: 0,
      all: 0
    },
    rub: {
      day: 0,
      night: 0,
      all: 0
    }
  };
  const [plots, setPlots] = useState([]);
  const [initPlots, setInitPlots] = useState([]);

  const getTableData = useCallback(async () => {
    const id = user.role === 'CHAIRMAN' ? user.group : user.id;
    if (!chart.period) return;
    setLoading(true);

    axios
      .post('/counters/period', {
        group: id,
        id,
        role_check: user.role,
        period: chart.period,
        grouping: chart.grouping
      })
      .then((res) => res.data)
      .then((data) => {
        if (!data.status) return;

        const { result } = data;
        const rows = [];

        result.map((item) => {
          rows.push({
            marker: markerColumnHandler(item.marker),
            plot_number: +item.parcel ? +item.parcel : item.parcel,
            eui: item.eui || 'Нет данных',
            meter: counterColumnHandler(
              item.day_indication,
              item.night_indication,
              item.state_date
            ),
            consumption:
              chart.select === 'kvt'
                ? counterColumnHandler(
                  item.day_consumption,
                  item.night_consumption,
                  item.DATE,
                  item.day_start,
                  true,
                  false
                )
                : counterColumnHandler(
                  item.price_day_consumption,
                  item.price_night_consumption,
                  item.DATE,
                  item.day_start,
                  true,
                  true
                ),
            power: item.power,
            actions: editBtn(item.id, !!item.auto),
            // pdf: pdfBtn(item.id)
            pdf: pdfBtn(item.eui)
          });

          if (item.price_day_consumption) {
            final['rub'].day += +item.price_day_consumption;
          }
          if (item.price_night_consumption) {
            final['rub'].night += +item.price_night_consumption;
          }
          if (item.day_consumption) {
            final['kvt'].day += +item.day_consumption;
          }
          if (item.night_consumption) {
            final['kvt'].night += +item.night_consumption;
          }

          return true;
        });

        final['rub'].all = final['rub'].day + final['rub'].night;
        final['kvt'].all = final['kvt'].day + final['kvt'].night;
        setSum(final);
        setInitPlots([...rows]);
        setPlots([...rows]);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, axios]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  const [columns] = useState([
    { name: 'marker', title: ' ' },
    { name: 'plot_number', title: 'Участок' },
    { name: 'eui', title: '№ счетчика' },
    { name: 'meter', title: 'Показания' },
    { name: 'consumption', title: 'Потребление' },
    { name: 'pdf', title: ' ' },
    { name: 'actions', title: ' ' }
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'marker', width: 60 },
    { columnName: 'plot_number', width: 100 },
    { columnName: 'eui' },
    { columnName: 'meter', width: 140 },
    { columnName: 'consumption', width: 190 },
    { columnName: 'pdf', width: 65 },
    { columnName: 'actions', width: 65 }
  ]);

  const searchStateHandler = (e) => {
    const value = e.target.value;
    if (value.length) {
      const filteredPlots = initPlots.filter((plot) => {
        const search = value.toLowerCase();
        const number = plot.plot_number ? plot.plot_number : '';
        const name = plot.eui.split(' ').join('').toLowerCase();
        const day_consumption = plot.consumption.props.children[0].props
          .children[2].props.children
          ? plot.consumption.props.children[0].props.children[2].props.children
            .replace(/\D+/g, '')
            .split(' ')
            .join('')
          : '';
        const night_consumption = plot.consumption.props.children[2].props
          .children[2].props.children
          ? plot.consumption.props.children[2].props.children[2].props.children
            .replace(/\D+/g, '')
            .split(' ')
            .join('')
          : '';
        const day_indication = plot.meter.props.children[0].props.children[2]
          .props.children
          ? plot.meter.props.children[0].props.children[2].props.children
            .replace(/\D+/g, '')
            .split(' ')
            .join('')
          : '';
        const night_indication = plot.meter.props.children[2].props.children[2]
          .props.children
          ? plot.meter.props.children[2].props.children[2].props.children
            .replace(/\D+/g, '')
            .split(' ')
            .join('')
          : '';

        const str = `${number}${name}${day_indication}${night_indication}${day_consumption}${night_consumption}`;

        return str.includes(search);
      });
      setPlots(filteredPlots);
    } else {
      setPlots(initPlots);
    }
  };
  return (
    <Paper style={{ position: 'relative' }}>
      <Grid
        columns={columns}
        rows={plots}>
        {loading && <LinearProgress />}
        <SortingState
          defaultSorting={[{ columnName: 'plot_number', direction: 'asc' }]}
        />
        <PagingState
          defaultCurrentPage={0}
          pageSize={10} />
        <IntegratedSorting sorting={['marker', 'plot_number']} />
        <IntegratedPaging />
        <IntegratedFiltering />
        <Table
          columnExtensions={tableColumnExtensions}
          messages={tableMessages}
        />
        <TableHeaderRow showSortingControls />
        <Toolbar />
        <Template name="toolbarContent">
          <DashboardTemplateWrapper>
            <DashboardNextWrap>
              <Typography variant="caption">
                на {new Date().toLocaleString('ru')}
              </Typography>
            </DashboardNextWrap>
          </DashboardTemplateWrapper>
          <TextField
            className="PaddingInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              placeholder: 'Найти...'
            }}
            onChange={searchStateHandler}
            variant="outlined"
          />
          {user.role === 'CHAIRMAN' && <DashboardTableMenu />}
        </Template>
        <PagingPanel messages={pagingPanelMessages} />
      </Grid>
      <TableFooterWrap>
        <TableFooterHeader>
          <Typography
            component="span"
            variant="caption">
            <Typography
              component="span"
              variant="h3">
              ИТОГО
            </Typography>
            &nbsp; потребление
          </Typography>
        </TableFooterHeader>
        <TableFooterBody>
          <TableBodyItem>
            <Typography
              component="span"
              variant="caption">
              День:
              <Typography
                component="span"
                variant="h4">
                &nbsp;{sum[chart.select].day.toFixed(2)}&nbsp;|&nbsp;
              </Typography>
            </Typography>
          </TableBodyItem>
          <TableBodyItem>
            <Typography
              component="span"
              variant="caption">
              Ночь:
              <Typography
                component="span"
                variant="h4">
                &nbsp;{sum[chart.select].night.toFixed(2)}&nbsp;|&nbsp;
              </Typography>
            </Typography>
          </TableBodyItem>
          <TableBodyItem>
            <Typography
              component="span"
              variant="caption">
              Сутки:
              <Typography
                component="span"
                variant="h4">
                &nbsp;{sum[chart.select].all.toFixed(2)}
              </Typography>
            </Typography>
          </TableBodyItem>
        </TableFooterBody>
      </TableFooterWrap>
    </Paper>
  );
};

import React from 'react';
import {useSelector} from 'react-redux'
import {useParams} from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';
import {makeStyles, useTheme} from '@material-ui/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}));

const Chart = props => {
  const user = useSelector(state => state.system.USER_DATA);
  const params = useParams();
  const { className, data: dataProp, labels, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = canvas => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);

    gradient.addColorStop(0.2, fade('#ffa093', 0.5));
    gradient.addColorStop(0.5, 'rgba(255,255,255,0)');
    gradient.addColorStop(1, 'rgba(255,255,255,0)');

    gradient2.addColorStop(0.6, fade('#9fdfff', 0.5));
    gradient2.addColorStop(1, 'rgba(255,255,255,0)');
    gradient2.addColorStop(1, 'rgba(255,255,255,0)');

    return {
      datasets: [
        {
          data: dataProp.first_lane,
          backgroundColor: gradient,
          borderColor: '#ffa093',
          pointBorderColor: '#FFFFFF',
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#ffa093'
        },
        {
          data: dataProp.second_lane,
          backgroundColor: gradient2,
          borderColor: '#9fdfff',
          pointBorderColor: '#FFFFFF',
          pointBorderWidth: 3,
          pointRadius: 6,
          pointBackgroundColor: '#9fdfff'
        }
      ],
      labels
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7,
            callback: value => {
              return value;
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: tooltipItem => {
          tooltipItem.map((item, idx) => {
            switch (idx) {
              case 0:
                return item.yLabel = params.name ? `Потребление: ${item.yLabel}` : user.role === 'CHAIRMAN' ? `∑ по счетчикам: ${item.yLabel}` : `У вас: ${item.yLabel}`;
              case 1:
                return item.yLabel = params.name ? `Среднее: ${item.yLabel}` : `В среднем: ${item.yLabel}`;
              default:
                return item.yLabel = `Общий: ${item.yLabel}`;
            }
          })
        },
        label: tooltipItem => {
          return tooltipItem.yLabel;
        }
      }
    }
  };

  const keyProvider = () => {
    return Math.random().toString().split('.')[1] + Date.now().toString()
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Line
        data={data}
        datasetKeyProvider={keyProvider}
        options={options}
      />
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array
};

export default Chart;

import React, { useCallback, useState } from 'react';

import { Menu, MenuItem, IconButton } from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TableToExcel } from './TableToExel';
import { ZipPdf } from './ZipPdf';
import { useHttp } from '../../../../../../hooks/http.hook';
import { useSelector } from 'react-redux';

export const DashboardTableMenu = () => {
  const { request, loading } = useHttp();
  const user = useSelector((state) => state.system.USER_DATA);
  const chart = useSelector((state) => state.chart);
  const [anchorEl, setAnchorEl] = useState(null);
  const [excel, setExcel] = useState([]);
  const [countersCount, setCountersCount] = useState(0);

  const dateHandler = (date) => {
    return `${new Date(date).toLocaleString('ru', {
      day: '2-digit',
      month: '2-digit'
    })}.${new Date(date).getFullYear()}`;
  };

  const getTableData = useCallback(async () => {
    const id = user.role === 'CHAIRMAN' ? user.group : user.id;
    if (!chart.period) return;
    const data = await request('/counters/period', 'POST', {
      ...chart,
      group: id,
      id,
      role_check: user.role
    });

    if (!data.status) return;

    const { result } = data;

    const rows = [];

    result.map((item) => {
      rows.push({
        date: `${dateHandler(chart.period.start_date)} - ${dateHandler(
          chart.period.end_date
        )}`,
        number: item.parcel,
        counter: item.eui,
        day_indication: parseFloat(item.day_indication),
        night_indication: parseFloat(item.night_indication),
        sut_indication:
          parseFloat(item.day_indication) + parseFloat(item.night_indication),
        day_consumption: parseFloat(item.day_consumption),
        night_consumption: parseFloat(item.night_consumption),
        sut_consumption:
          parseFloat(item.day_consumption) + parseFloat(item.night_consumption)
      });
      return true;
    });

    setExcel(rows);
    setCountersCount(rows.length)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getTableData();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = JSON.parse(localStorage.getItem('theme'));
  const setTheme = (set) => {
    if (set && set.theme === 'DARK') return 'DARK';
    return '';
  };

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  return (
    <div className="MenuMore">
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        size="small">
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        className={`${setTheme(theme)}`}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}>
        <MenuItem
          disabled={loading}
          onClick={handleOpen}>
          <TableToExcel
            excel={excel}
            loading={loading} />
        </MenuItem>
        <MenuItem
          disabled={loading}
          onClick={() => {
            handleOpen();
            // setOpenDialog(true);
          }}>
          <ZipPdf
            count={countersCount}
            loading={loading}
            open={openDialog}
            setOpen={handleOpenDialog}
            onClose={handleCloseDialog}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

import React from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';

import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Aux from '../../layouts/Auxilary';
import NewsList from './components/NewsList';
import Widgets from './components/Widgets';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const NewsSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const { tab } = useParams();

  const tabs = [
    { value: 'list', label: 'Список новостей' },
    // { value: 'widgets', label: 'Настройка виджетов' }
  ];
  const handleTabsChange = (event, value) => {
    history.push(value);
  };
  if (!tab) {
    return <Redirect to="/newssettings/list" />;
  }
  return (
    <Aux
      description={'Настройка главной страницы'}
      name="Новости">
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'list' && <NewsList />}
        {tab === 'widgets' && <Widgets />}
      </div>
    </Aux>
  );
};
export default NewsSettings;

import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {CountersView} from './components/CountersView/CountersView';
import {Card, CardContent, CardHeader, Divider, Fab, IconButton} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {CountersAdd} from './components/CountersAdd/CountersAdd';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  close: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: '#84be41'
  }
}));

export const PlotCounters = () => {
  const classes = useStyles();
  const user = useSelector(state => state.system.USER_DATA);

  const [expanded, setExpanded] = useState(false);
  const [flag, setFlag] = useState('');
  const [edit, setEdit] = useState(false);

  const handleOpen = flag => {
    setExpanded(true);
    setFlag(flag);
  };

  const clearFlag = () => {
    setFlag('')
  }

  const handleClose = () => {
    setExpanded(false)
    setEdit(false)
  }

  const handleEdit = (panel) => {
    if (edit === panel) {
      setEdit(false)
      setExpanded(false)
      setFlag('')
    } else {
      setTimeout(() => {
        setFlag('')
        setEdit(panel)
        setExpanded(true)
      }, 50)
    }
  }

  return (
    <Card className="mb40">
      {user.role === 'CHAIRMAN' &&
        <>
          <CardHeader
            avatar = {
              <span>
                <Fab
                  aria-label="add"
                  className="BtnGreen"
                  onClick={() => handleOpen('new')}
                  size="small"
                >
                  <AddIcon />
                </Fab>
                <span className="ButtonTitle">Добавить прибор учета</span>
              </span>
            }
          />
          <Divider />
        </>
      }
      <CardContent
        className={classes.root}
      >
        {expanded &&
          <IconButton
            className={classes.close}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        }
        <CountersAdd
          clear={clearFlag}
          close={handleClose}
          flag={flag}
          open={expanded}
          trigger={edit}
        />
        <CountersView
          close={handleClose}
          edit={handleEdit}
          open={handleOpen}
          trigger={edit}
        />
      </CardContent>
    </Card>
  )
};

export const initialState = {
  name: '',
  auto: 0,
  serial: '',
  add_date: null,
  // check_date: null,
  init_tariff1: '',
  init_tariff2: '',
  model: '',
  count_tariffs: 0,
  count_phases: 0,
}

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  Typography,
  FormControlLabel
} from '@material-ui/core';

import SuccessSnackbar from '../SuccessSnackbar';
import { DatePicker } from '@material-ui/pickers';
import { SwitchHandler } from '../../../../../../components/Switcher/Switcher';
import { useApi } from '../../../../../../hooks/api.hook';
import { initialStateSuggestion } from './FormInitialSug';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {},
  container: {
    height: 'fit-content'
  }
}));

const GeneralSettings = (props) => {
  const { profile, className, updateUserData } = props;

  const { api } = useApi();
  const [sug, setSug] = useState(initialStateSuggestion);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [form, setForm] = useState({ ...profile });

  const inputFormHandler = (e) => {
    if (e !== null) {
      if (
        e.target.name === 'part' ||
        e.target.name === 'inn' ||
        e.target.name === 'snils' ||
        e.target.name === 'passportSeries' ||
        e.target.name === 'passportNumber' ||
        e.target.name === 'code'
      )
        e.target.value = e.target.value.replace(/\D+/g, '');
      if (e.target.name === 'part' && e.target.value > 100)
        e.target.value = 100;
      if (e.target.name === 'code')
        e.target.value = e.target.value
          .split(/^(\d{3})(\d{1,3})$/)
          .filter(Boolean)
          .join('-');
      if (e.target.name === 'snils')
        e.target.value = e.target.value
          .split(/^(\d{3})(\d{0,3})(\d{0,3})(\d{0,2})$/)
          .filter(Boolean)
          .join('-');
      setForm({ ...form, [e.target.name]: e.target.value });

      if (e.target.name === 'last_name') {
        fioSug(e.target.name, e.target.value, 'SURNAME');
      }
      if (e.target.name === 'first_name') {
        fioSug(e.target.name, e.target.value, 'NAME');
      }
      if (e.target.name === 'second_name') {
        fioSug(e.target.name, e.target.value, 'PATRONYMIC');
      }
      if (e.target.name === 'code') {
        issuedSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'issued') {
        issuedSug('code', e.target.value);
      }
      if (e.target.name === 'address') {
        addressSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'addressFact') {
        addressSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'passportNumber' && e.target.value.length === 6) {
        passCheck('pass', `${form.passportSeries}${e.target.value}`);
      }
      if (e.target.name === 'snils' && e.target.value.length === 14) {
        setSug({
          ...sug,
          isErroredSnils: !checkSnils(e.target.value)
        });
        enqueueSnackbar('Некорректный СНИЛС', { variant: 'error' });
      }
      // апи с ограничением (не для проды)
      // if(e.target.name === 'inn' && e.target.value.length === 12){
      //   innCheck(e.target.name, {inn: e.target.value});
      // }
    }
  };

  const fioSug = useCallback(
    async (name, val, parts) => {
      const res = await api('/dadata/suggestions/fio', 'POST', {
        query: val,
        parts: parts
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const addressSug = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/address', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const issuedSug = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/fms_unit', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const passCheck = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/passport', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        isErroredPass: res[0].qc === 0 ? false : true
      });
      switch (res[0].qc) {
        case 2:
          enqueueSnackbar('Исходное значение пустое', { variant: 'error' });
          break;
        case 1:
          enqueueSnackbar('Неправильный формат серии или номера', {
            variant: 'error'
          });
          break;
        case 10:
          enqueueSnackbar('Недействительный паспорт', { variant: 'error' });
          break;
        default:
          break;
      }
    },
    [sug]
  );
  // апи с ограничением (не для проды)
  // const innCheck = useCallback(async (name, val) => {
  //   const res = await api('/fns/egr', 'POST',
  //     {query:val});
  //   console.log('res :>> ', res);
  //   if(res.items.length > 0){
  //     let fullFio = res.items[0]['ИП']['ФИОПолн'].split(' ')
  //     setSug({
  //       ...sug,
  //       last_name: [{value: fullFio[0]}],
  //       first_name: [{value: fullFio[1]}],
  //       second_name: [{value: fullFio[2]}],
  //       isErroredInn: false,
  //     })
  //   } else {
  //     setSug({
  //       ...sug,
  //       isErroredInn: true,
  //     })
  //   }
  // },[sug])

  const dateFormHandler = (e, name) => {
    setForm({ ...form, [name]: e });
  };

  const checkerFormHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const updateSettingsHandler = useCallback(() => {
    axios.post('/user/update/main', { ...form }).then((r) => {
      if(r.status){
        updateUserData()
      }
    });
  }, [form]);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateSettingsHandler();
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const checkSnils = (snils) => {
    let arrSnils = snils.split('-');
    let numSnils = `${arrSnils[0]}${arrSnils[1]}${arrSnils[2]}`;
    let control = arrSnils[3];
    let result = 0;

    for (let i = 0; i < numSnils.length; i++) {
      result += (numSnils.length - i) * numSnils[i];
    }
    // Корректируем контрольное число
    if (result == 100 || result == 101) result = '00';
    if (result > 101) result %= 101;
    // Проверчем контрольное число
    if (result == control) return true;
    else return false;
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <CardHeader title="Профиль" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}
            >
              <Grid
                className="TextCenter"
                item
                xs={12}
              >
                <Typography variant="h5">О себе</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) => option.value}
                  inputValue={form.last_name}
                  name="last_name"
                  onChange={(e, newVal) => {
                    setForm({ ...form, last_name: newVal.value });
                  }}
                  onInputChange={(e, value, reason)=>{
                    if(reason === 'clear') {
                      e.target.value = '';
                      e.target.name = 'last_name';
                    }
                    inputFormHandler(e)
                  }}
                  options={sug.last_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      label="Фамилия"
                      name="last_name"
                      required
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => <span>{option.value}</span>}
                />
                {/* <TextField
                  fullWidth
                  label="Фамилия"
                  name="last_name"
                  onChange={inputFormHandler}
                  required
                  value={form.last_name}
                  variant="outlined"
                /> */}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) => option.value}
                  inputValue={form.first_name}
                  name="first_name"
                  onChange={(e, newVal) => {
                    setForm({ ...form, first_name: newVal.value });
                  }}
                  onInputChange={(e, value, reason)=>{
                    if(reason === 'clear') {
                      e.target.value = '';
                      e.target.name = 'first_name';
                    }
                    inputFormHandler(e)
                  }}
                  options={sug.first_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      label="Имя"
                      name="first_name"
                      required
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => <span>{option.value}</span>}
                />
                {/* <TextField
                  fullWidth
                  label="Имя"
                  name="first_name"
                  onChange={inputFormHandler}
                  required
                  value={form.first_name}
                  variant="outlined"
                /> */}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) => option.value}
                  inputValue={form.second_name}
                  name="second_name"
                  onChange={(e, newVal) => {
                    setForm({ ...form, second_name: newVal.value });
                  }}
                  onInputChange={(e, value, reason)=>{
                    if(reason === 'clear') {
                      e.target.value = '';
                      e.target.name = 'second_name';
                    }
                    inputFormHandler(e)
                  }}
                  options={sug.second_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      label="Отчество"
                      name="second_name"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => <span>{option.value}</span>}
                />
                {/* <TextField
                  fullWidth
                  label="Отчество"
                  name="second_name"
                  onChange={inputFormHandler}
                  value={form.second_name}
                  variant="outlined"
                /> */}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <DatePicker
                  autoOk
                  cancelLabel={'Отмена'}
                  disableFuture
                  format="dd.MM.yyyy"
                  fullWidth
                  inputVariant="outlined"
                  label="Дата рождения"
                  name="birthDate"
                  onChange={(e) => dateFormHandler(e, 'birthDate')}
                  value={form.birthDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  error={sug.isErroredInn}
                  fullWidth
                  inputProps={{ maxLength: 12 }}
                  label="ИНН"
                  name="inn"
                  onChange={inputFormHandler}
                  value={form.inn}
                  variant="outlined"
                />
                {/* <TextField
                  fullWidth
                  label="ИНН"
                  name="inn"
                  onChange={inputFormHandler}
                  value={form.inn}
                  variant="outlined"
                /> */}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  error={sug.isErroredSnils}
                  fullWidth
                  inputProps={{ maxLength: 14 }}
                  label="СНИЛС"
                  name="snils"
                  onChange={inputFormHandler}
                  value={form.snils}
                  variant="outlined"
                />
                {/* <TextField
                  fullWidth
                  label="СНИЛС"
                  name="snils"
                  onChange={inputFormHandler}
                  value={form.snils}
                  variant="outlined"
                /> */}
              </Grid>
            </Grid>
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}
            >
              <Grid
                className="TextCenter"
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Typography variant="h5">Паспорт</Typography>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  error={sug.isErroredPass}
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  label="Серия"
                  name="passportSeries"
                  onChange={inputFormHandler}
                  value={form.passportSeries}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={8}
              >
                <TextField
                  error={sug.isErroredPass}
                  fullWidth
                  inputProps={{ maxLength: 6 }}
                  label="Номер"
                  name="passportNumber"
                  onChange={inputFormHandler}
                  value={form.passportNumber}
                  variant="outlined"
                />
                {/* <TextField
                  fullWidth
                  label="Номер"
                  name="passportNumber"
                  onChange={inputFormHandler}
                  value={form.passportNumber}
                  variant="outlined"
                /> */}
              </Grid>
              <Grid
                item
                xs={4}
              >
                <DatePicker
                  autoOk
                  cancelLabel={'Отмена'}
                  disableFuture
                  format="dd.MM.yyyy"
                  fullWidth
                  inputVariant="outlined"
                  label="Дата выдачи"
                  name="issuedDate"
                  onChange={(e) => dateFormHandler(e, 'issuedDate')}
                  value={form.issuedDate}
                />
              </Grid>
              <Grid
                item
                xs={8}
              >
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) =>
                    `${option.data.code} - ${option.data.name}`
                  }
                  inputValue={form.code}
                  name="code"
                  onChange={(e, newVal) => {
                    if (
                      newVal &&
                      newVal !== null &&
                      typeof newVal['value'] !== 'undefined'
                    ) {
                      setForm({
                        ...form,
                        issued: newVal.data.name,
                        code: newVal.data.code
                      });
                    }
                  }}
                  onInputChange={(e, value, reason)=>{
                    if(reason === 'clear') {
                      e.target.value = '';
                      e.target.name = 'code';
                    }
                    inputFormHandler(e)
                  }}
                  options={sug.code}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 7,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      label="Код подразделения"
                      name="code"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <span>
                      {' '}
                      {option.data.code} - {option.data.name}
                    </span>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) =>
                    `${option.data.code} - ${option.data.name}`
                  }
                  inputValue={form.issued}
                  name="issued"
                  onChange={(e, newVal) => {
                    console.log('newVal :>> ', newVal);
                    if (
                      newVal &&
                      newVal !== null &&
                      typeof newVal['value'] !== 'undefined'
                    ) {
                      setForm({
                        ...form,
                        issued: newVal.data.name,
                        code: newVal.data.code
                      });
                    }
                  }}
                  onInputChange={(e, value, reason)=>{
                    if(reason === 'clear') {
                      e.target.value = '';
                      e.target.name = 'issued';
                    }
                    inputFormHandler(e)
                  }}
                  options={sug.code}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      label="Кем выдан"
                      multiline
                      name="issued"
                      rows="4"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <span>
                      {' '}
                      {option.data.code}- {option.data.name}
                    </span>
                  )}
                />
                {/* <TextField
                  fullWidth
                  label="Кем выдан"
                  multiline
                  name="issued"
                  onChange={inputFormHandler}
                  rows="4"
                  value={form.issued}
                  variant="outlined"
                /> */}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Место рождения"
                  multiline
                  name="birthPlace"
                  onChange={inputFormHandler}
                  rows="4"
                  value={form.birthPlace}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}
            >
              <Grid
                className="TextCenter"
                item
                xs={12}
              >
                <Typography variant="h5">Адрес прописки</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Autocomplete
                  freeSolo
                  getOptionLabel={(option) => option.value}
                  inputValue={form.address}
                  name="address"
                  onChange={(e, newVal) => {
                    if (
                      newVal &&
                      newVal !== null &&
                      typeof newVal['data'] !== 'undefined'
                    ) {
                      setForm({
                        ...form,
                        address: newVal.value ?? '',
                        region: newVal.data.region ?? '',
                        city: newVal.data.city ?? '',
                        street: newVal.data.street_with_type ?? '',
                        house: newVal.data.house ?? '',
                        apartments: newVal.data.flat ?? ''
                      });
                    }
                  }}
                  onInputChange={(e, value, reason)=>{
                    if(reason === 'clear') {
                      e.target.value = '';
                      e.target.name = 'address';
                    }
                    inputFormHandler(e)
                  }}
                  options={sug.address}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      label="Адрес в свободном формате"
                      name="address"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => <span>{option.value}</span>}
                />
                {/* <TextField
                  fullWidth
                  label="Область"
                  name="region"
                  onChange={inputFormHandler}
                  value={form.region}
                  variant="outlined"
                /> */}
              </Grid>
              {/* <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Город"
                  name="city"
                  onChange={inputFormHandler}
                  value={form.city}
                  variant="outlined"
                />
              </Grid> */}
              {/* <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Улица"
                  name="street"
                  onChange={inputFormHandler}
                  value={form.street}
                  variant="outlined"
                />
              </Grid> */}
              {/* <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Дом"
                  name="house"
                  onChange={inputFormHandler}
                  value={form.house}
                  variant="outlined"
                />
              </Grid> */}
              {/* <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Квартира"
                  name="apartments"
                  onChange={inputFormHandler}
                  value={form.apartments}
                  variant="outlined"
                />
              </Grid> */}
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  className={classes.control}
                  control={
                    <SwitchHandler
                      checked={form.addrChecker}
                      name="addrChecker"
                      onChange={checkerFormHandler}
                    />
                  }
                  label="Фактический адрес не совпадает"
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.container}
              container
              item
              lg={6}
              md={6}
              spacing={2}
              xs={12}
            >
              {form.addrChecker && (
                <>
                  <Grid
                    className="TextCenter"
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Typography variant="h5">Адрес проживания</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) => option.value}
                      inputValue={form.addressFact}
                      name="addressFact"
                      onChange={(e, newVal) => {
                        console.log('newVal :>> ', newVal);
                        if (
                          newVal &&
                          newVal !== null &&
                          typeof newVal['data'] !== 'undefined'
                        ) {
                          setForm({
                            ...form,
                            addressFact: newVal.value ?? '',
                            regionFact: newVal.data.region ?? '',
                            cityFact: newVal.data.city ?? '',
                            streetFact: newVal.data.street_with_type ?? '',
                            houseFact: newVal.data.house ?? '',
                            apartmentsFact: newVal.data.flat ?? ''
                          });
                        }
                      }}
                      onInputChange={(e, value, reason)=>{
                        if(reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'addressFact';
                        }
                        inputFormHandler(e)
                      }}
                      options={sug.addressFact}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Адрес в свободном формате"
                          name="addressFact"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => <span>{option.value}</span>}
                    />
                    {/* <TextField
                      fullWidth
                      label="Область"
                      name="regionFact"
                      onChange={inputFormHandler}
                      value={form.regionFact}
                      variant="outlined"
                    /> */}
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Город"
                      name="cityFact"
                      onChange={inputFormHandler}
                      value={form.cityFact}
                      variant="outlined"
                    />
                  </Grid> */}
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Улица"
                      name="streetFact"
                      onChange={inputFormHandler}
                      value={form.streetFact}
                      variant="outlined"
                    />
                  </Grid> */}
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Дом"
                      name="houseFact"
                      onChange={inputFormHandler}
                      value={form.houseFact}
                      variant="outlined"
                    />
                  </Grid> */}
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Квартира"
                      name="apartmentsFact"
                      onChange={inputFormHandler}
                      value={form.apartmentsFact}
                      variant="outlined"
                    />
                  </Grid> */}
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="secondary"
            type="submit"
            variant="contained"
          >
            Сохранить изменнения
          </Button>
        </CardActions>
      </form>
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default GeneralSettings;

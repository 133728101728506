import {GET_WEATHER, SET_WEATHER} from '../types';

const initialState = {
  coords: {},
  weather: {},
  loading: false,
  view: false
};

export const weatherReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEATHER:
      return {...state, coords: action.payload, loading: true};
    case SET_WEATHER:
      return {...state, loading: false, view: true, weather: action.payload}
    default:
      return state;
  }
}

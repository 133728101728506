import React, { useCallback, useState } from 'react';
import Aux from '../../layouts/Auxilary';
import { Player } from 'video-react';
import HLSSource from './components/HLSSource';
import { useHttp } from 'hooks/http.hook';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function VideosPage() {
  const { request } = useHttp();
  const user = useSelector((state) => state.system.USER_DATA);
  const [token, setToken] = useState('');
  const [cameras, setCameras] = useState([]);
  const [src, setSrc] = useState(null);

  const getToken = useCallback(async () => {
    const { response } = await request('/token/check', 'POST', { id: user.id });
    setToken(response[0].access_token);
  }, [request]);
  const getCameraId = useCallback(async () => {
    const { response } = await request('/video/cameras/find', 'POST', {
      access_token: token
    });
    setCameras(response.items.filter((el) => el.connected));
  }, [token, request]);
  const getLive = useCallback(
    async (id) => {
      const { response } = await request('/video/camera/get/live', 'POST', {
        access_token: token,
        camera_id: id,
        format: 'hls'
      });
      setSrc((src) => ({ ...src, [id]: response.url }));
    },
    [token, cameras, request]
  );
  useEffect(() => {
    getToken();
  }, [getToken]);
  useEffect(() => {
    if (token) {
      getCameraId();
    }
  }, [getCameraId, token]);
  useEffect(() => {
    let intervals = [];
    if (token && cameras.length > 0) {
      for (const camera of cameras) {
        getLive(camera.id);
        intervals.push(
          setInterval(() => {
            getLive(camera.id);
          }, 540000)
        );
      }
    }
    return () => {
      for (const interval of intervals) {
        clearInterval(interval);
      }
    };
  }, [getLive, token, cameras]);
  return (
    <Aux
      description="Просмотр камер"
      name="Видеонаблюдение">
      {cameras.length > 0 && src
        ? cameras.map((camera) => {
          return (
            <React.Fragment key={camera.id}>
              <h1>{camera.name}</h1>
              <Player>
                <HLSSource
                  getLive={() => getLive(camera.id)}
                  isVideoChild
                  src={src[camera.id] || ''}
                />
              </Player>
            </React.Fragment>
          );
        })
        : null}
    </Aux>
  );
}

export default VideosPage;

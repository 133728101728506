import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider
} from '@material-ui/core';
// import { useSnackbar } from 'notistack';
import { useHttp } from '../../hooks/http.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const TestPage = () => {
  const classes = useStyles();
  const [form, setForm] = useState({
    // headers:{},
    route: '',
    body: ''
  });
  //   const { enqueueSnackbar } = useSnackbar()
  const { request } = useHttp();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('form.body', JSON.parse(form.body));
    sendDataHandler();
  };
  const sendDataHandler = useCallback(async () => {
    console.log('form', form);
    const response = await request(form.route, 'POST', JSON.parse(form.body));
    console.log('response', response);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, form]);
  const formChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <div className={classes.root}>
      <h1>TestPage</h1>
      <Card>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid
              container
              spacing={2}>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="headers.json"
                  multiline
                  name="headers"
                  onChange={formChangeHandler}
                  placeholder='{"Content-Type": "application/json"}'
                  required
                  rows="4"
                  value={form.headers}
                  variant="outlined"
                />
              </Grid> */}
              <Grid
                item
                xs={12}>
                <TextField
                  fullWidth
                  label="route"
                  name="route"
                  onChange={formChangeHandler}
                  placeholder="/oauth/token"
                  required
                  value={form.route}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}>
                <TextField
                  fullWidth
                  label="body.json"
                  multiline
                  name="body"
                  onChange={formChangeHandler}
                  placeholder='{"id":1, "code":"100-a9999c71f4914e86a7c031f6b486b740"}'
                  required
                  rows="4"
                  value={form.body}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardActions>
            <Button
              color="secondary"
              type="submit"
              variant="contained">
              Submit
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};
export default TestPage;

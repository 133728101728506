import {USER_TOKEN, USER_DATA, SYSTEM_LOGIN, SYSTEM_LOGOUT, UPDATE_USER} from '../types';

const initialState = {
  TOKEN: sessionStorage.getItem(USER_TOKEN),
  USER_DATA: JSON.parse(sessionStorage.getItem(USER_DATA))
};

export const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SYSTEM_LOGOUT:
      sessionStorage.clear();
      return {...state, TOKEN: null, USER_DATA: null};
    case SYSTEM_LOGIN:
      sessionStorage.setItem(USER_TOKEN, action.payload.TOKEN);
      sessionStorage.setItem(USER_DATA, JSON.stringify(action.payload.USER_DATA));
      return {...state, TOKEN: action.payload.TOKEN, USER_DATA: action.payload.USER_DATA};
    case UPDATE_USER:
      sessionStorage.setItem(USER_DATA, JSON.stringify(action.payload));
      return {...state, USER_DATA: action.payload};
    default: return state;
  }
};

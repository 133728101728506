import React, { useCallback, useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHttp } from '../../hooks/http.hook';
import { dateHook } from '../../hooks/date.hook';
import Aux from '../../layouts/Auxilary';

import { CountersTable } from './components/CountersTable/CountersTable';
import { ControlPanel } from '../DashboardDefault/components/ControlPanel/ControlPanel';
import { DateChartSelect } from '../DashboardDefault/components/PerformanceOverTime/components/DayChartSelect/DayChartSelect';
import Chart from '../../components/Chart';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CountersPower } from './components/CountersPower/CountersPower';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  inner: {
    height: 375,
    minWidth: 500
  },
  chart: {
    height: '100%'
  }
}));


export const SelectContext = createContext(undefined);

export const DashboardCounter = () => {
  const classes = useStyles();
  const { request } = useHttp();
  const date = dateHook();
  const { id, tab } = useParams();
  const user = useSelector((state) => state.system.USER_DATA);
  const chart = useSelector((state) => state.chart);

  const [name, setName] = useState('');
  const [plot, setPlot] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [power, setPower] = useState({ count: '15000', switched: '' });

  const [data, setData] = useState({});
  const [params, setParams] = useState({
    id,
    group: user.group,
    role_check: user.role,
    format: chart.format,
    day: chart.check ? 'day' : 'night',
    check: chart.check,
    select: chart.select,
    chart: tab,
    period: date
  });

  const updateParamsDateHandler = useCallback(
    (_date, _name) => {
      setParams({
        ...params,
        period: {
          ...params.period,
          [_name]: _date
        }
      });
    },
    [params]
  );

  const updateSelectViewHandler = useCallback(
    (val) => {
      setParams({ ...params, select: val });
    },
    [params]
  );

  const updateFormatViewHandler = useCallback(
    (val) => {
      setParams({ ...params, format: val });
    },
    [params]
  );

  const getConcreteData = useCallback(async () => {
    const response = await request('/counters/chart/id', 'POST', params);

    if (!response.status) return;
    const { result } = response;

    const filtered = result.labels.map((item) => {
      switch (params.format) {
        case 'month':
          return `${new Date(item).toLocaleString('ru', {
            day: 'numeric',
            month: 'short'
          })}`;
        case 'year':
          return `${new Date(item).toLocaleString('ru', {
            month: 'short'
          })} ${new Date(item).getFullYear()}`;
        default:
          return true;
      }
    });

    setData({ ...result, labels: filtered });
  }, [params]);

  const getPlotData = useCallback(async () => {
    const response = await request('/counters/info/id', 'POST', { id: id });

    if (!response.status) return;
    const { result } = response;

    setName(result[0].eui);
    setPlot(result[0].parcel);
    setPower({ count: result[0].power, switched: result[0].swiched_on, server: result[0].server });

    setIsShow(result[0].server !== undefined);

    setParams({ ...params, plot: result[0].parcel });
  }, [name, plot]);

  useEffect(() => {
    getPlotData();
  }, []);

  useEffect(() => {
    getConcreteData();
  }, [getConcreteData]);

  const typeHandler = (type) => {
    switch (type) {
      case 'electric':
        return 'Электричество,';
      case 'water':
        return 'Вода,';
      case 'gas':
        return 'Газ,';
      default:
        return '';
    }
  };

  return (
    <Aux
      description={`Показания по участку №${plot}`}
      name="Подробнее">
      <Typography>
        {typeHandler(tab)} {name.toUpperCase()}
      </Typography>
      <SelectContext.Provider
        value={{ updateSelectViewHandler, updateFormatViewHandler, params }}>
        <div className={classes.content}>
          <ControlPanel
            period={params.period}
            update={updateParamsDateHandler}
          />
          <Card className={classes.card}>
            <CardHeader action={<DateChartSelect />} />
            <Divider />
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Chart
                    className={classes.chart}
                    data={data}
                    labels={data.labels}
                  />
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
          {user.role === 'CHAIRMAN' && isShow && (
            <CountersPower
              count={power.count}
              id={id}
              name={name}
              server={power.server}
              switched={power.switched}
            />
          )}
          <CountersTable per={params.period} />
        </div>
      </SelectContext.Provider>
    </Aux>
  );
};

import React from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { GeneralSettings } from './components';

const useStyles = makeStyles(() => ({
  root: {}
}));

const General = props => {
  const { user, className, updateUserData } = props;
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <GeneralSettings
          profile={user}
          updateUserData={updateUserData}/>
      </Grid>
    </Grid>
  );
};

General.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  updateUserData: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.system.USER_DATA
  }
};


export default connect(mapStateToProps)(General);

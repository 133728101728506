import React, { useState, useCallback, useEffect } from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  TextField,
  Divider,
  Button
} from '@material-ui/core';
import {
  EditorState,
  convertToRaw,
  convertFromRaw
  // Editor
} from 'draft-js';
import RichEditor from '../../../../components/RichEditor';
import { useSnackbar } from 'notistack';
import {useHttp} from '../../../../hooks/http.hook';
import PropTypes from 'prop-types';

const NewMain = ({id}) => {
  const history = useHistory();
  const [form, setForm] = useState({header:''});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const user = useSelector(state => state.system.USER_DATA)
  const { enqueueSnackbar } = useSnackbar();
  const {request} = useHttp();

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!id){
      AddNewsHandler(editorState);
    } else {
      updateNewsHandler(editorState)
    }
  };
  const formChangeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const updateNewsHandler = useCallback(async (editor) => {
    const data = await request('/news/update', 'POST', {id, header:form.header, body:JSON.stringify(convertToRaw(editor.getCurrentContent()))})
    const status = data.status ? 'success' : 'error';
    let message = data.status ? 'Успешно сохранено' : 'Что-то пошло не так!'
    if(!data.status && data.message !== undefined) message = data.message
    enqueueSnackbar(message, { variant: status })

  }, [form, enqueueSnackbar, user, id, request])
  const AddNewsHandler = useCallback(async (editor) => {
    const userId = user.role === 'CHAIRMAN' ? user.group : user.id;
    const data = await request('/news/add', 'POST', {id:userId, header:form.header, body:JSON.stringify(convertToRaw(editor.getCurrentContent()))})
    const status = data.status ? 'success' : 'error';
    let message = data.status ? 'Успешно сохранено' : 'Что-то пошло не так!'
    if(!data.status && data.message !== undefined) message = data.message
    enqueueSnackbar(message, { variant: status })

    if (!data.status) return;
    const {insertId} = data.result;
    if (insertId) {
      history.push(`/new/${insertId}/configure/extras`)
    }
  }, [form, enqueueSnackbar, user, history, id, request])

  const getPrevDataHandler = useCallback(async (id) => {
    const response = await request('/news/id', 'POST', {id: id})
    const res = response.result[0];
    
    setForm({...form, header:res.header})
    // setEditorState(convertFromRaw(JSON.parse(response.result[0].body)))
    const convertedState = convertFromRaw(JSON.parse(res.body))
    const editorValue = EditorState.createWithContent(convertedState);
    setEditorState(editorValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, request])

  useEffect(() => {
    if (id) {
      getPrevDataHandler(id)
    }
  }, [getPrevDataHandler, id]);

  return (
    <Card className="mb40">
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}>
              <TextField
                fullWidth
                label="Заголовок"
                name="header"
                onChange={formChangeHandler}
                required
                value={form.header}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <RichEditor
                BLOCK_TYPES={[{}]}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </Grid>
          
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="secondary"
            type="submit"
            variant="contained">
            {!id ? 'Далее' : 'Сохранить изменения'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
NewMain.propTypes = {
  id:PropTypes.number
}
export default NewMain;

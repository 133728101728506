import React, {useContext} from 'react';
import {useParams} from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {SelectContext} from '../../../../../DashboardCounter/DashboardCounter';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: 8
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    padding: '5px 13px'
  }
}));

// eslint-disable-next-line react/prop-types
export const DateChartSelect = ({update, value}) => {
  const classes = useStyles();
  const {name} = useParams();
  const context = useContext(SelectContext);

  const handleChange = e => {
    if(!name) return update(e.target.value);

    context.updateFormatViewHandler(e.target.value)
  };

  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
    >
      <Select
        inputProps={{
          name: 'period',
          className: classes.select
        }}
        native
        onChange={handleChange}
        value={value}
      >
        <option value="month">По дням</option>
        <option value="year">По месяцам</option>
      </Select>
    </FormControl>
  );
};

export const initialStateSuggestion = {
  first_name: [],
  last_name: [],
  second_name: [],
  code: [],
  address: [],
  addressFact: [],
  email: [],
  inn:[],
  isErroredInn: false,
  isErroredSnils: false,
  isErroredPass: false
};
  
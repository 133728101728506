import React from 'react';
import {Avatar} from '@material-ui/core';
import OpacityOutlinedIcon from '@material-ui/icons/OpacityOutlined';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';

export const avatarIconHandler = icon => {
  switch (icon) {
    case 'water':
      return (
        <Avatar className="AvatarBg">
          <OpacityOutlinedIcon />
        </Avatar>
      );
    case 'electric':
      return (
        <Avatar className="AvatarBg">
          <FlashOnOutlinedIcon />
        </Avatar>
      );
    case 'gas':
      return (
        <Avatar className="AvatarBg">
          <WhatshotOutlinedIcon />
        </Avatar>
      );
    default:
      return (
        <Avatar className="AvatarBg">
          C
        </Avatar>
      );
  }
};

export const counterNameHandler = name => {
  switch (name) {
    case 'water':
      return (
        'воды'
      );
    case 'electric':
      return (
        'электроэнергии'
      );
    case 'gas':
      return (
        'газа'
      );
    default:
      return (
        'не определен'
      );
  }
};

export const counterAutomaticHandler = auto => {
  switch (auto) {
    case 1:
      return (
        'Автоматический сбор показаний'
      );
    case 0:
      return (
        'Ручной сбор показаний'
      );
    default:
      return (
        'не определен'
      );
  }
};

export const counterPhaseHandler = flag => {
  switch (flag) {
    case 1:
      return (
        'Трехфазный'
      );
    case 0:
      return (
        'Однофазный'
      );
    default:
      return (
        'не определен'
      );
  }
};

export const counterRateHandler = flag => {
  switch (flag) {
    case 1:
      return (
        ', двутарифный'
      );
    case 0:
      return (
        ', однотарифный'
      );
    default:
      return (
        ', не определен'
      );
  }
};

export const counterDateHandler = date => {
  const _ = new Date(date);
  return _.toLocaleDateString('ru') || 'нет данных'
};

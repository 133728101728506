import React, {forwardRef} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import { ProfilePages } from './ProfileMenu.pages';
import {connect} from 'react-redux';
import {systemLogout} from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

// eslint-disable-next-line react/display-name
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const mapDispatchToProps = {
  systemLogout
};

// eslint-disable-next-line react/no-multi-comp
export const ProfileMenuNav = connect(null, mapDispatchToProps)(({systemLogout}) => {
  const classes = useStyles();
  const pages = ProfilePages();

  const handleLogout = () => {
    systemLogout();
  };

  return (
    <List>
      {pages.map(page => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          {
            page.href === '/logout' ?
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                onClick={handleLogout}
                to="/login"
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
              :
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
          }
        </ListItem>
      ))}
    </List>
  )
});

import React from 'react';

import phone from '../../resources/images/socials/phone.svg';
import gmail from '../../resources/images/socials/gmail.svg';
import telegram from '../../resources/images/socials/telegram.svg';
import watsapp from  '../../resources/images/socials/whatsapp.svg';
import viber from '../../resources/images/socials/viber.svg';
import instagram from '../../resources/images/socials/instagram.svg';
import skype from '../../resources/images/socials/skype.svg';
import vk from '../../resources/images/socials/vk.svg'

export const PhoneIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={phone}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const GmailIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={gmail}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const TelegramIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={telegram}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const WatsappIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={watsapp}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const ViberIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={viber}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const InstagramIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={instagram}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const SkypeIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={skype}
  /> //eslint-disable-line
};

// eslint-disable-next-line react/no-multi-comp
export const VkIcon = () => {
  return <img
    alt=""
    className="SocialIcon"
    src={vk}
  /> //eslint-disable-line
};

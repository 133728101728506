import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components';
import { PlotUsersTable } from './components/PlotUsersTable'
import { FullScreenDialog } from './components/FullScreenDialog/FullScreenDialog'

import {useHttp} from '../../../../hooks/http.hook';
import {useParams} from 'react-router-dom';

const MarginWrap = styled.main`
  margin-bottom: 30px;    
`

export const PlotUsers = () => {
  const {request, loading} = useHttp();
  const plotId = useParams().id;
  const [peoples, setPeoples] = useState([]);

  const getUsersHandler = useCallback(async () => {
    const data = await request('/parcel/people', 'POST', {id: plotId})
    setPeoples([...data.users]);
  }, [plotId, request]);

  useEffect(() => {
    getUsersHandler()
  }, [plotId, getUsersHandler]);
  
  return (
    <>
      <MarginWrap>
        <FullScreenDialog
          update={getUsersHandler}
        />
      </MarginWrap>
      <MarginWrap>
        <PlotUsersTable
          loading={loading}
          peoples={peoples}
          update={getUsersHandler}
        />
      </MarginWrap>
    </>
  )
};

import React, { useEffect, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import { connect } from 'react-redux';
import { setPlots, clearFunc } from '../../redux/actions';

import { PlotInfo } from './components/PlotInfo/PlotInfo';
import { PlotUsers } from './components/PlotUsers/PlotUsers';
import { PlotCounters } from './components/PlotCounters/PlotCounters';
import Aux from '../../layouts/Auxilary';

import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));
const mapDispatchToProps = {
  setPlots,
  clearFunc
};
const mapStateToProps = (state) => {
  return { user: state.system.USER_DATA, plots: state.plots.plots };
};

const CreatePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ user, plots, setPlots, clearFunc }) => {
  const classes = useStyles();
  const { tab, id } = useParams();
  const history = useHistory();
  const [plot, setPlot] = useState({});

  const getPlotsHandler = useCallback(() => {
    const role = user.role;
    const id = role === 'CHAIRMAN' ? user.group : user.id;
    setPlots({ id, role });
    clearFunc();
  }, [setPlots, user]);

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: 'info', label: 'Информация' },
    { value: 'users', label: 'Пользователи' },
    { value: 'counters', label: 'Счетчики' }
    // { value: 'messages', label: 'Сообщения' }
  ];

  if (!tab) {
    return <Redirect to="plot/configure/info" />;
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }
  useEffect(() => {
    if (plots) {
      const plot = plots.filter((plot) => +plot.id === +id)[0];
      setPlot(plot);
    }
  }, [plots]);
  useEffect(() => {
    if (id) {
      getPlotsHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlotsHandler, id]);

  return (
    <Aux
      description={
        id
          ? `Информация по участку №${!plot ? '' : plot.plot}`
          : 'Создание участка'
      }
      name={id ? 'Редактирование' : 'Создание'}>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable">
        {tabs.map((tab, idx) => (
          <Tab
            disabled={idx > 0 && !id}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'info' && <PlotInfo id={id} />}
        {tab === 'users' && <PlotUsers />}
        {tab === 'counters' && <PlotCounters />}
        {/*{tab === 'messages' && <PlotMessages />}*/}
      </div>
    </Aux>
  );
});

export default CreatePage;

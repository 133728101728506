import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%'
  }
}));

// eslint-disable-next-line no-unused-vars,react/prop-types
const Auth = ({children}) => {
  const classes = useStyles();

  const theme = JSON.parse(localStorage.getItem('theme')) || {theme: 'WHITE'};

  return (
    <Fragment>
      <main className={`${classes.content} ${theme.theme}`}>
        <Suspense fallback={<LinearProgress />}>
          {children}
        </Suspense>
      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;

import React from 'react';
import {useSelector} from 'react-redux';
import ReactExport from 'react-data-export';
import { ListItemIcon, Typography, CircularProgress  } from '@material-ui/core';
import TableChartIcon from '@material-ui/icons/TableChart';

// eslint-disable-next-line react/prop-types
export const TableToExcel = ({excel, loading}) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ExcelFile;
  const { ExcelColumn } = ExcelFile;
  const chart = useSelector(state => state.chart)

  const dateHandler = date => {
    return `${new Date(date).toLocaleString('ru', { day: '2-digit', month: '2-digit'})}.${new Date(date).getFullYear()}`
  }

  return (
    <ExcelFile
      element={
        <div className="AlignCenter">
          <ListItemIcon>
            {loading ?
              <CircularProgress size={24} /> :
              <TableChartIcon fontSize="small" />
            }
          </ListItemIcon>
          <Typography
            noWrap
            variant="inherit"
          >
            Выгрузить Excel
          </Typography>
        </div>
      }
      filename={`report_${dateHandler(chart.period.start_date)}_${dateHandler(chart.period.end_date)}`}
    >
      <ExcelSheet
        data={excel}
        name={`${dateHandler(chart.period.start_date)} - ${dateHandler(chart.period.end_date)}`}
      >
        <ExcelColumn
          label="Период"
          value="date"
        />
        <ExcelColumn
          label="Участок"
          value="number"
        />
        <ExcelColumn
          label="Номер счетчика"
          value="counter"
        />
        <ExcelColumn
          label="Показания день"
          value="day_indication"
        />
        <ExcelColumn
          label="Показания ночь"
          value="night_indication"
        />
        <ExcelColumn
          label="Показания сутки"
          value="sut_indication"
        />
        <ExcelColumn
          label="Потребление день"
          value="day_consumption"
        />
        <ExcelColumn
          label="Потребление ночь"
          value="night_consumption"
        />
        <ExcelColumn
          label="Потребление сутки"
          value="sut_consumption"
        />
      </ExcelSheet>
    </ExcelFile>
  )
}

import React, {useCallback, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Grid
} from '@material-ui/core'
import { WeatherIcon } from './WeatherIcons/WeatherIcons'
import {
  TemperatureIcon,
  FeelsIcon,
  PressureIcon,
  WindIcon,
  HumidityIcon,
  SunriseIcon,
  SunsetIcon
} from './WeatherIcons/ConditionIcons'
import { WidgetMainInfo, WidgetInfoText } from './weather.style'
import moment from 'moment'

import {getWeather} from '../../redux/actions';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
  },
  margin: {
    position: 'absolute',
    top: 10,
    right: 10
  }
}));


export const WeatherCard = props => {
  const weather = useSelector(state => state.weather.weather);
  const view = useSelector(state => state.weather.view);
  const dispatch = useDispatch();
  // eslint-disable-next-line react/prop-types
  const { className, ...rest } = props;
  const classes = useStyles();

  const [timer, setTimer] = useState(true);

  const getWeatherHandler = useCallback(() => {
    navigator.geolocation.getCurrentPosition(position => {
      return dispatch(getWeather(position.coords))
    })

    dispatch(getWeather({'latitude': 60.080128, 'longitude': 30.352998399999997}))
  }, [dispatch]);

  const setTimerToUpdate = useCallback(flag => {
    if (timer || flag) {
      getWeatherHandler()
      setTimer(false)
    }
  }, [getWeatherHandler, timer]);

  useEffect(() => {
    setTimerToUpdate()
  }, [setTimerToUpdate])

  const dateHandler = date => {
    return moment(date).format('dddd, DD MMMM YYYY')
  };

  if (!view) return null;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <IconButton 
        aria-label="Обновить" 
        className={classes.margin}
        onClick={() => setTimerToUpdate(true)}
      >
        <RefreshIcon />
      </IconButton>
      <CardContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            className="WidgetInfo WeatherCity"
            item
            md={12}
            sm={12}
            xs={12}
          >
            {/* eslint-disable-next-line no-undef */}
            { YMaps.location.city || 'Санкт-Петербург' }
          </Grid>
          <Grid
            className="WidgetInfo WeatherDate"
            item
            md={12}
            sm={12}
            xs={12}
          >
            { dateHandler(weather.date) }
          </Grid>
          <div className="Spacer" />
          <Grid
            className="WidgetInfo"
            item
            md={4}
            sm={4}
            xs={4}
          >
            <WidgetMainInfo>{ weather.sunrise }</WidgetMainInfo>
            <WidgetInfoText>
              <SunriseIcon />
            </WidgetInfoText>
          </Grid>
          <Grid
            className="WidgetInfo"
            item
            md={4}
            sm={4}
            xs={4}
          >
            <WeatherIcon icon={weather.condition} />
          </Grid>
          <Grid
            className="WidgetInfo"
            item
            md={4}
            sm={4}
            xs={4}
          >
            <WidgetMainInfo>{ weather.sunset }</WidgetMainInfo>
            <WidgetInfoText>
              <SunsetIcon />
            </WidgetInfoText>
          </Grid>
          <div className="Spacer" />
          <Grid
            className="WidgetInfo"
            item
            md={6}
            sm={6}
            xs={6}
          >
            <WidgetMainInfo>
              { weather.temp }
              <TemperatureIcon condition={weather.temp} />
            </WidgetMainInfo>
            <WidgetInfoText>
                            температура воздуха
            </WidgetInfoText>
          </Grid>
          <Grid
            className="WidgetInfo"
            item
            md={6}
            sm={6}
            xs={6}
          >
            <WidgetMainInfo>
              { weather.feels }
              <FeelsIcon condition={weather.feels} />
            </WidgetMainInfo>
            <WidgetInfoText>
                            ощущается как
            </WidgetInfoText>
          </Grid>
          <div className="Spacer" />
          <Grid
            className="WidgetInfo"
            item
            md={4}
            sm={4}
            xs={4}
          >
            <WidgetMainInfo>
              { weather.humidity }
              <HumidityIcon condition={weather.humidity} />
            </WidgetMainInfo>
            <WidgetInfoText>
                            влажность воздуха
            </WidgetInfoText>
          </Grid>
          <Grid
            className="WidgetInfo"
            item
            md={4}
            sm={4}
            xs={4}
          >
            <WidgetMainInfo>
              { weather.pressure }
              <PressureIcon condition={weather.pressure} />
            </WidgetMainInfo>
            <WidgetInfoText>
                            атмосферное давление
            </WidgetInfoText>
          </Grid>
          <Grid
            className="WidgetInfo"
            item
            md={4}
            sm={4}
            xs={4}
          >
            <WidgetMainInfo>
              { weather.wind }
              <WindIcon condition={weather.wind} />
            </WidgetMainInfo>
            <WidgetInfoText>
                            скорость ветра
            </WidgetInfoText>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};
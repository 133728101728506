import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
  },
  media: {
    height: 190,
  }
}));

export const NewsSkeleton = () => {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
    >
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            height={40}
            variant="circle"
            width={40}
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            height={10}
            width="40%"
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 6 }}
            width="80%"
          />
        }
      />
      {
        <Skeleton
          animation="wave"
          className={classes.media}
          variant="rect"
        />
      }

      <CardContent>
        <>
          <Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
          />
        </>
      </CardContent>
    </Card>
  );
};

import styled from 'styled-components';

export const TableSelectWrap = styled.main`
  margin-right: 10px;
`;

export const TableFooterWrap = styled.main`
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TableFooterHeader = styled.main`
  padding-right: 20px;
  display: flex;
  flex-direction: row;
`;

export const TableFooterBody = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const TableBodyItem = styled.main`
  display: inline-flex;
`;



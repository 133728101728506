import React from 'react';

// import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const ProfilePages = () => {
  let sheets;
  sheets = [
    // {
    //   title: 'Мой профиль',
    //   href: '/profile/1/timeline',
    //   icon: <PermIdentityOutlinedIcon/>
    // },
    {
      title: 'Настройки',
      href: '/settings/general',
      icon: <SettingsOutlinedIcon/>
    },
    {
      title: 'Выйти',
      href: '/logout',
      icon: <ExitToAppIcon/>
    }
  ];

  return sheets
};

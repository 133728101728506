import React from 'react';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
  Fab
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import FullScreenDialog from '../../FullScreenDialog/FullScreenDialog';
import { DeviceDetails } from '../../DeviceDetails/DeviceDetails';
import PropTypes from 'prop-types';

const place = 'expansion';
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%'
  },
  root: {
    width: '100%',
    display: place === 'expansion' ? 'flex' : 'inline-flex'
  },
  container: {
    flexDirection: 'column'
  },
  panel: {
    paddingLeft: 0,
    paddingRight: 16,
    width: '100%'
  },
  margin: {
    marginLeft: theme.spacing(2)
  }
}));
export const GroupDetails = ({
  group,
  devices,
  getDevicesDataHandler,
  handleClickDelete,
  getGroupsDataHandler
}) => {
  const classes = useStyles();

  return (
    <ExpansionPanel
      key={group.id}
      onChange={() => {
        getDevicesDataHandler(group.id, group.id_type);
      }}>
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        className={`${classes.panel}  MuiReverse`}
        expandIcon={<ExpandMoreIcon />}>
        <div className={classes.content}>
          <List
            className={`${classes.root} `}
            onClick={(e) => e.stopPropagation()}>
            <ListItem>
              <ListItemText primary={group.name} />
            </ListItem>
            <ListItem
              onClick={(e) => e.stopPropagation()}
              style={{ justifyContent: 'flex-end', width: '30%' }}>
              <FullScreenDialog
                group={group}
                groupId={group.id}
                updateDevices={getDevicesDataHandler}
                updateGroups={getGroupsDataHandler}
              />
              <div className={classes.margin}>
                <span>
                  <Fab
                    aria-label="delete"
                    className="BtnRed extraSmall"
                    color="secondary"
                    onClick={() => handleClickDelete(group.id, group.name)}
                    size="small">
                    <DeleteIcon />
                  </Fab>
                </span>
              </div>
            </ListItem>
          </List>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.container}>
        {devices[group.id] !== undefined ? (
          devices[group.id].map((device) => {
            return (
              <DeviceDetails
                house={device.address_house}
                id={device.id}
                key={device.id}
                name={device.name}
                number={device.serial}
                street={device.address_street}
                type={device.id_type_name}
              />
            );
          })
        ) : (
          <div>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </div>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
GroupDetails.propTypes = {
  devices: PropTypes.object,
  getDevicesDataHandler: PropTypes.function,
  getGroupsDataHandler: PropTypes.function,
  group: PropTypes.object,
  handleClickDelete: PropTypes.function,
};

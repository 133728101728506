import React from 'react'

import { PlotDetails } from '../PlotDetails/PlotDetails'
import { UserDetails } from '../UserDetails'
import { PlotInformation } from './TableDetails.style'
import Divider from '@material-ui/core/Divider'

// eslint-disable-next-line react/prop-types
export const TableDetails = () => (
  <PlotInformation>
    <PlotDetails/>
    <Divider/>
    <UserDetails/>
  </PlotInformation>
)


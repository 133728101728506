import React, { useEffect } from 'react';
import {connect} from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import { ProfileMenu } from './ProfileMenu/ProfileMenu'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import {pages} from './navigationConfig';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
    marginBottom: 10
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}));

const NavBar = props => {
  // eslint-disable-next-line react/prop-types
  const {user, openMobile, onMobileClose, className} = props;

  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname]); // eslint-disable-line

  const theme = JSON.parse(localStorage.getItem('theme'))
  const setTheme = set => {
    if (set && set.theme === 'DARK') return 'DARK';
    return ''
  }

  // eslint-disable-next-line react/prop-types
  const {avatar, first_name, last_name, role} = user

  const navbarContent = (
    <div className={classes.content}>
      <ProfileMenu
        profile={
          <div className={classes.profile}>
            <Avatar
              alt="Person"
              className={classes.avatar}
              component={RouterLink}
              src={avatar ? avatar : ''}
              to={'/settings/general'}
            />
            <Typography
              className={classes.name}
              variant="h4"
            >
              {`${first_name} ${last_name}`}
            </Typography>
          </div>
        }
      />
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        <Navigation
          admin={role === 'CHAIRMAN'}
          component="div"
          pages={pages}
        />
      </nav>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            className={`${clsx(classes.root, className)} ${setTheme(theme)}`}
          >
            <PerfectScrollbar>
              {navbarContent}
            </PerfectScrollbar>
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          <PerfectScrollbar>
            {navbarContent}
          </PerfectScrollbar>
        </Paper>
      </Hidden>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.system.USER_DATA
  }
};

export default connect(mapStateToProps)(NavBar);

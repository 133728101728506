import {CHANGE_THEME} from '../types';

const initialState = {
  theme: 'WHITE',
  check: true
}

export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      localStorage.setItem('theme', JSON.stringify(action.payload))
      return initialState;
    default: return {...state, theme: 'WHITE'}
  }
};

import styled from 'styled-components'

export const MarginWrap = styled.main`
    margin-bottom: 30px;    
    margin-top: 30px;    
`
export const GroupsWrapper = styled.main`
    margin-top: 20px;
    margin-bottom: 40px;
`
export const PlotDetailsInfo = styled.main`
    display: flex;
    flex-direction: column;
    padding-left: 14px;
    justify-content: space-around;
`
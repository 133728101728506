import React, {useCallback, useEffect} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import EditIcon from '@material-ui/icons/Edit';
import {Fab, Grid} from '@material-ui/core';
import {useParams} from 'react-router';
import {getCounters} from '../../../../../../redux/actions';
import {connect} from 'react-redux';
import {
  avatarIconHandler,
  counterAutomaticHandler, 
  counterDateHandler,
  counterNameHandler,
  counterPhaseHandler,
  counterRateHandler
} from './CountersView.helper';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  panel: {
    paddingLeft: 16,
    paddingRight: 16
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  avatar: {
    display: 'flex'
  },
}));

const mapStateToProps = state => {
  return {
    counters: state.counters.counters,
    loading: state.counters.loading
  }
};

const mapDispatchToProps = {
  getCounters
};

export const CountersView = connect(mapStateToProps, mapDispatchToProps)(({counters, loading, getCounters, open, close, edit, trigger}) => {
  const classes = useStyles();
  const {id} = useParams();

  const handleChange = panel => {
    edit(panel);
    trigger ? close() : open();
  };

  const getAllCountersData = useCallback(() => {
    getCounters(id)
  }, [getCounters, id]);

  useEffect(() => {
    getAllCountersData()
  }, [getAllCountersData])

  return (
    <div className={classes.root}>
      {loading
        ?
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
        :
        counters.map(counter => (
          <ExpansionPanel
            expanded={trigger === counter.id}
            key={counter.id}
            onChange={() => handleChange(counter.id)}
          >
            <ExpansionPanelSummary
              className={classes.panel}
              expandIcon={
                <Fab
                  className="BtnGreen"
                  size="small"
                >
                  <EditIcon />
                </Fab>
              }
            >
              <FormControlLabel
                control={
                  <div className={classes.avatar}>
                    {avatarIconHandler(counter.name)}
                  </div>
                }
                label=""
              />
              <>
                <Grid
                  alignItems="center"
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                  >
                    <Typography
                      component="p"
                      variant="h5"
                    >
                      Прибор учета {counterNameHandler(counter.name)}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                    >
                      {counterAutomaticHandler(counter.auto)}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                    >
                      {counterPhaseHandler(counter.count_phases)}
                      {counterRateHandler(counter.count_tariffs)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                  >
                    <Typography
                      component="p"
                      variant="overline"
                    >
                      {
                        counter.serial &&
                        <strong>№ </strong>
                      }
                      <Typography
                        component="span"
                        variant="h6"
                      >
                        {counter.serial}
                      </Typography>
                    </Typography>
                    <Typography
                      component="p"
                      variant="overline"
                    >
                      <strong>Модель: </strong>
                      <Typography
                        component="span"
                        variant="h6"
                      >
                        {counter.model}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                  >
                    <Typography
                      component="p"
                      variant="overline"
                    >
                      <strong>Установлен: </strong>
                      <Typography
                        component="span"
                        variant="h6"
                      >
                        {counterDateHandler(counter.add_date)}
                      </Typography>
                    </Typography>
                    {/* <Typography
                      component="p"
                      variant="overline"
                    >
                      <strong>Срок службы, лет: </strong>
                      <Typography
                        component="span"
                        variant="h6"
                      >
                        3
                      </Typography>
                    </Typography> */}
                  </Grid>
                </Grid>
              </>
            </ExpansionPanelSummary>
          </ExpansionPanel>
        ))
      }
    </div>
  );
});

import axios from 'axios';
import { USER_DATA } from '../redux/types';
// import {useDispatch} from 'react-redux';
import { changeUserData } from '../redux/actions';

export const uploadImage = (id, type, role, file, dispatch) => {
  // const dispatch = useDispatch() 
  const form = new FormData();
  form.append('id', id)
  form.append('role_check', role)
  form.append('type', type)
  form.append('file', file)
  form.append('name', file.name)

  axios.post('/upload/loading', form, {
    headers: {
      'Content-Type' : 'multipart/form-data'
    }
  }).then(() => {
    axios.post('/people/get/user_info', { id })
      .then(response => response.data)
      .then(data => {
        if (!data.status) return;
        console.log(JSON.parse(sessionStorage.getItem(USER_DATA)))
        const oldData = JSON.parse(sessionStorage.getItem(USER_DATA))
        const {avatar, banner} = data.result[0]
        const newData = {...oldData, avatar, banner}

        dispatch(changeUserData(newData))
      })
  })
}


import React, { useState, createRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  // CardHeader,
  CardMedia,
  Divider,
  Button,
  Typography,
  Avatar,
  Tooltip,
  GridList,
  IconButton
} from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import { DokaModal } from '../../../../plugins/react-doka';
import { useHttp } from '../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import SnackMessage from './SnackMessage';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {
    width: '100% !important',
    maxWidth: '297px !important',
    height: 'auto !important',
    marginTop: '10px'
  },
  header: {
    marginBottom: '-60px',
    padding: 0
  }
}));

const NewExtra = ({ id }) => {
  const [state, setState] = useState({
    mask: (root, setInnerHTML) => {
      setInnerHTML(
        root,
        `
                        <mask id="my-mask">
                            <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                        </mask>
                    `
      );
    },
    profile: {
      enabled: false,
      image: null,
      src: '',
      crop: {
        aspectRatio: 0.55,
        center: {
          x: 0.5378,
          y: 0.355
        }
      }
    }
  });
  const handleAvatarUpload = createRef();
  const handleFilesUpload = createRef();
  const user = useSelector((state) => state.system.USER_DATA);
  const { request } = useHttp();
  const [form, setForm] = useState({ img_link: '' });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const clickUploadHandler = () => {
    return handleAvatarUpload.current.click();
  };
  const clickMultiUploadHandler = () => {
    return handleFilesUpload.current.click();
  };

  const uploadImage = useCallback(
    async (id, type, role, file) => {
      const form = new FormData();
      form.append('id', id);
      form.append('role_check', role);
      form.append('type', type);
      form.append('file', file);
      form.append('name', file.name);
      axios
        .post('/upload/loading', form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (!res.data.result) {
            return enqueueSnackbar('Что-то пошло не так...', {
              variant: 'error'
            });
          }
          getPrevDataHandler(id);
        })
        .catch(() => {
          enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
        });
    },
    [axios]
  );
  const handleToggleProfileEditor = (input) => {
    console.log('Toggle Doka Profile Modal');

    setState({
      ...state,
      profile: {
        ...state.profile,
        src: input,
        enabled: !state.profile.enabled
      }
    });
  };
  const handleDokaConfirmProfile = (output) => {
    console.log('Confirmed Doka Profile Modal', output);
    setState({
      ...state,
      profile: {
        ...state.profile,
        enabled: false,
        image: output.file,
        crop: output.data.crop
      }
    });


    if (form.img_link) deletePhotoHandler({id, type:'news_photo', src:form.img_link});
    
    uploadImage(form.id, 'news_photo', user.role, output.file);
    getPrevDataHandler(id);
  };

  const handleDokaCancelProfile = () => {
    console.log('Cancelled Doka Profile Modal');

    setState({
      ...state,
      profile: {
        ...state.profile,
        enabled: false
      }
    });
  };
  const sourceFileHandler = (e) => {
    return handleToggleProfileEditor(e.target.files[0]);
  };
  const sourceMultiFileHandler = (e) => {
    for (const iterator of e.target.files) {
      uploadImage(form.id, 'news_gallery', user.role, iterator);
    }
  };
  const getPrevDataHandler = useCallback(
    async (id) => {
      const response = await request('/news/id', 'POST', { id: id });
      const res = response.result[0];

      setForm({ ...form, ...res });
    },
    [user, request]
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    enqueueSnackbar('Успешное сохранение', { variant: 'success' });
    history.push('/newssettings/list');
  };

  const deletePhotoHandler = async (data) => {
    const res = await request('/uploads/delete', 'POST', {
      ...data
    });
    if (!res.status) {
      enqueueSnackbar('Что-то пошло не так...', {
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Новость успешно удалена!', {
        variant: 'success'
      });
    }
  };
  const handleDelete = (src) => {
    const message = 'Вы действительно хотите удалить это Фото?';

    const action = (src) => {
      deletePhotoHandler({
        id,
        type: 'news_gallery',
        src,
        galery_json: form.galery_json
      });
      getPrevDataHandler(id);
    };
    enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: 3000,
      // eslint-disable-next-line
      content: (key, message) => (
        <SnackMessage
          action={() => {
            action(src);
          }}
          key={key}
          message={message}
          src={src}
        />
      )
    });
  };

  useEffect(() => {
    if (id) {
      getPrevDataHandler(id);
    }
  }, [getPrevDataHandler, id]);

  const { profile, mask } = state;
  return (
    <Card className="mb40">
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}>
              <Typography variant="subtitle1">
                Прикрепить изображение для фона
              </Typography>
              <Tooltip
                arrow
                title="Изменить">
                <Avatar
                  className="PlotInfoAvatar"
                  onClick={clickUploadHandler}
                  src={`${form.img_link}`}
                  variant="square">
                  <PhotoCameraOutlinedIcon />
                </Avatar>
              </Tooltip>
              {profile.enabled && (
                <DokaModal
                  crop={profile.crop}
                  cropMask={mask}
                  onCancel={handleDokaCancelProfile}
                  onConfirm={handleDokaConfirmProfile}
                  outputData
                  src={profile.src}
                  utils={['crop', 'filter', 'color']}
                />
              )}
              <input
                accept=".jpg, .jpeg, .png"
                className="inputFile"
                name="avatar"
                onChange={sourceFileHandler}
                ref={handleAvatarUpload}
                type="file"
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <Typography variant="subtitle1">
                Прикрепить дополнительные фотографии, которые появятся под
                текстом статьи
              </Typography>
              {form.galery_json !== undefined && (
                <GridList
                  cols={3}
                  spacing={4}>
                  {form.galery_json.map((el, id) => {
                    return (
                      <Card
                        className={`${classes.root}`}
                        key={id}>
                        <CardActions
                          className={classes.header}
                          disableSpacing>
                          <IconButton
                            aria-label="settings"
                            onClick={() => handleDelete(el)}
                            style={{ zIndex: 1, marginLeft: 'auto' }}>
                            <CloseIcon />
                          </IconButton>
                        </CardActions>

                        <CardMedia>
                          <Avatar
                            className="PlotInfoAvatar"
                            src={`${el}`}
                            variant="square">
                            <PhotoCameraOutlinedIcon />
                          </Avatar>
                        </CardMedia>
                      </Card>
                    );
                  })}
                </GridList>
              )}
              <Tooltip
                arrow
                title="Загрузить">
                <IconButton
                  aria-label="update"
                  color="primary"
                  edge="start"
                  onClick={clickMultiUploadHandler}>
                  <PublishIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <input
                accept=".jpg, .jpeg, .png"
                className="inputFile"
                multiple
                name="assets"
                onChange={sourceMultiFileHandler}
                ref={handleFilesUpload}
                type="file"
              />
              {/* <Input
                accept=".jpg, .jpeg, .png"
                className="inputFile"
                id="assets"
                inputProps={{ multiple: true }}
                name="assets"
                onChange={sourceMultiFileHandler}
                ref={handleFilesUpload}
                type="file"
              /> */}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="secondary"
            type="submit"
            variant="contained">
            Сохранить изменения
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
export default NewExtra;

import React from 'react';
import {phoneView} from '../../hooks/phone.hook';

import { Typography } from '@material-ui/core';
import {
  TelegramIcon,
  WatsappIcon,
  ViberIcon,
  SkypeIcon,
  InstagramIcon,
  VkIcon
} from '../SocialIcons/SocialIcons';

// eslint-disable-next-line react/prop-types
export const SocialProfile = ({ watsapp = null, viber = null, telegram = null, skype = null, vk = null, instagram = null }) => {

  return (
    <main>
      <div className="containerInline">
        <WatsappIcon />
        <Typography
          component="span"
          variant="subtitle2"
        >
          &nbsp;
          {
            watsapp ?
              <a
                href={`https://api.whatsapp.com/send?phone=+${watsapp}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {phoneView(watsapp)}
              </a> :
              'Нет данных'
          }
        </Typography>
      </div>
      <div className="containerInline">
        <ViberIcon />
        <Typography
          component="span"
          variant="subtitle2"
        >
          &nbsp;
          {
            viber ?
              <a href={`viber://chat?number=+${viber}`}>
                {phoneView(viber)}
              </a> :
              'Нет данных'
          }
        </Typography>
      </div>
      <div className="containerInline">
        <TelegramIcon />
        <Typography
          component="span"
          variant="subtitle2"
        >
          &nbsp;
          {
            telegram ?
              <a href={`tg://resolve?domain=${telegram.split('@')[1]}`}>{telegram.split('@')[1] ? `@${telegram.split('@')[1]}` : `@${telegram}`}</a> :
              'Нет данных'
          }
        </Typography>
      </div>
      <div className="containerInline">
        <SkypeIcon />
        <Typography
          component="span"
          variant="subtitle2"
        >
          &nbsp;
          {
            skype ?
              <a href={`skype:nikname?${skype}`}>{skype}</a> :
              'Нет данных'
          }
        </Typography>
      </div>
      <div className="containerInline">
        <VkIcon />
        <Typography
          component="span"
          variant="subtitle2"
        >
          &nbsp;
          {
            vk ?
              <a
                href={`https://vk.me/${vk}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {vk}
              </a> :
              'Нет данных'
          }
        </Typography>
      </div>
      <div className="containerInline">
        <InstagramIcon />
        <Typography
          component="span"
          variant="subtitle2"
        >
          &nbsp;
          {
            instagram ?
              <a
                href={`https://www.instagram.com/${instagram}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {instagram}
              </a> :
              'Нет данных'
          }
        </Typography>
      </div>
    </main>
  )
}

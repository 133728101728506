import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';

const useStyles = makeStyles(() => ({
  powerButton: {
    marginTop: 8
  }
}));

export const CountersPower = ({ id, count, switched, name, server }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.system.USER_DATA);
  const [power, setPower] = useState({});
  const [status, setStatus] = useState({});

  useEffect(() => {
    console.log('status :>> ', status);
    statusHandler();
  }, []);
  useEffect(() => {
    setPower({
      power: count,
      count: 15000,
      date: new Date(),
      snt_name: user.snt_name,
      switched
    });
  }, [count]);

  const changeFormHandler = (e) => {
    const value = e.target.value
      .replace(/,/, '.')
      .replace(/[^.\d]+/g, '')
      .replace(/^([^.]*\.)|\./g, '$1');
    setPower({ ...power, count: value });
  };

  // типа название садоводства, номер счетчика, цифра, дата) /counters/info/update/id
  const statusHandler = () => {
    axios
      .post('/counters/info/update/id', { id: id })
      .then((response) => response.data)
      .then((result) => {
        setStatus({ ...result });
        if (result.result == 3) {
          setPower({
            ...power,
            power: result.power,
            switched: result.swiched_on
          });
        }
      });
  };
  const powerSubmitHandler = (e) => {
    e.preventDefault();
    axios
      .post('/counter/power', {
        counter_name: name,
        count: power.count,
        server
      })
      .then((response) => response.data)
      .then((result) => {
        const status = result.status ? 'success' : 'error';
        const message = result.status
          ? 'Команда поставлена в очередь на отправку'
          : result.response !== undefined
            ? result.response
            : 'Что-то пошло не так...';

        enqueueSnackbar(message, { variant: status });
        setStatus({
          status: true,
          result: 1,
          display_status: `Ограничение мощности до ${power.count} Вт на очереди.`
        });
      });
  };
  const statusHelper = useCallback(
    (switched, count) => {
      let text = !switched ? 'Выключен, ' : 'Включен, ';
      text += +count >= 15000 ? 'Без ограничений' : `Ограничен до ${count}`;
      return text;
    },
    [switched, count]
  );

  return (
    <form onSubmit={powerSubmitHandler}>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={2}>
            {' '}
            <Grid
              item
              md={6}
              xs={12}>
              <Grid
                item
                xs={12}>
                <Typography
                  component="main"
                  variant="h6">
                  Установленная мощность: &nbsp;
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}>
                <Typography gutterBottom>
                  {statusHelper(switched, power.power)}
                </Typography>
              </Grid>
              {(status.result == 1 ||
                status.result == 2 ||
                status.result == 3) && (
                <>
                  <Grid
                    item
                    xs={12}>
                    <Typography
                      component="main"
                      variant="h6">
                      Статус отправленного ограничения: &nbsp;
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Typography gutterBottom>
                      {status.display_status}
                    </Typography>
                  </Grid>
                </>
              )}
              {status.result == 1 && (
                <Grid
                  item
                  xs={12}>
                  <IconButton
                    aria-label="update"
                    color="primary"
                    edge="start"
                    onClick={statusHandler}>
                    <AutorenewOutlinedIcon fontSize="large" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}>
              <Grid
                item
                // lg={4}
                // md={6}
                xs={12}>
                <TextField
                  fullWidth
                  helperText="Для ограничение мощности введите желаемую мощность в ватах. Для отключения введите 0. Для снятия ограничения введите 15000."
                  label="Ограничение мощности"
                  name="power"
                  onChange={changeFormHandler}
                  required
                  value={power.count}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                // lg={8}
                // md={8}
                xs={12}>
                <Button
                  className={classes.powerButton}
                  color="primary"
                  type="submit"
                  variant="contained">
                  Отправить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};
CountersPower.propTypes = {
  count: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  server: PropTypes.string,
  switched: PropTypes.number,
};

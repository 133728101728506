import React, {useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { DetailsContext } from '../PlotsPage'
import axios from 'axios';

import { SocialProfile } from '../../../components/UserProfiles/SocialProfile';
import { ContactProfile } from '../../../components/UserProfiles/ContactProfile';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

const mapStateToProps = state => {
  return {
    plots: state.plots.plots
  }
};


export const UserDetails = connect(mapStateToProps)(({ plots, place }) => {
  const useStyles = makeStyles(() => ({
    root: {
      width: '100%',
      display: place === 'expansion' ? 'flex' : 'inline-flex'
    },
    green: {
      backgroundColor: '#84be41',
    },
    skeleton: {
      padding: 16
    },
  }));

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [peoples, setPeoples] = useState([]);
  const value = useContext(DetailsContext);
  const id = value.id;

  useEffect(() => {
    if (!id || id === 0) {
      setLoading(false);
      return;
    }

    const plot = plots.filter(plot => +plot.id === +id)[0]
    if (plot) {
      axios.post('/parcel/people', { id: plot.id })
        .then(response => {
          const { data } = response;
          setPeoples([...data.users]);
          setLoading(false);
        });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, plots]);

  // eslint-disable-next-line react/no-multi-comp
  const primaryHandler = percent => {
    return (
      <span className="containerCol">
        <span>Собственник</span>
        <span>Доля {percent}%</span>
      </span>
    )
  };

  // eslint-disable-next-line react/no-multi-comp
  const primaryExpansionHandler = () => {
    return <span>Пользователь</span> //className="ExpansionRole"
  };

  // eslint-disable-next-line react/no-multi-comp
  const secondaryHandler = (contact = false, name = null, surname = null) => {
    return (
      <span className="containerCol">
        <span>{ name }</span>
        <span>{ surname }</span>
        { contact &&  <span className="textGreen">контактное лицо</span>}
      </span>
    )
  };

  const avatarLetterHandler = (first = null, second = null) => {
    if (first) return first.split('')[0].toUpperCase();
    if (second) return second.split('')[0].toUpperCase();

    return 'A'
  };

  return (
    <div>
      {
        loading
          ?
          <div className={classes.skeleton}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </div>
          :
          peoples.map((user, idx) => {
            return (
              <List
                className={`${classes.root} ${place ? ' MuiMinimize' : ''}`}
                key={idx}
              >
                <ListItem
                  className="fixedInfoWidth"
                >
                  <ListItemAvatar>
                    <Avatar
                      className={classes.green}
                    >
                      {avatarLetterHandler(user.last_name, user.last_name)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.last_name}
                    secondary={secondaryHandler(false, user.first_name, user.second_name)}
                  />
                </ListItem>
                <Divider
                  flexItem
                  orientation="vertical"
                />
                <ListItem
                  className="fixedInfoWidth"
                >
                  <ListItemText
                    className="ListItemTextMobile"
                    primary={user.part ? primaryHandler(user.part) : primaryExpansionHandler()}
                    secondary={user.contact ? secondaryHandler(user.contact) : null}
                  />
                </ListItem>
                <Divider
                  flexItem
                  orientation="vertical"
                />
                <ListItem className="fixedInfoWidth">
                  <ListItemText
                    primary={ContactProfile(user.phone, user.email)}
                  />
                </ListItem>
                <Divider
                  flexItem
                  orientation="vertical"
                />
                <ListItem className="fixedContactWidth">
                  <ListItemText
                    primary={
                      SocialProfile({
                        watsapp: user.watsapp,
                        viber: user.viber,
                        telegram: user.telegram,
                        skype: user.skype,
                        vk: user.vk,
                        instagram: user.instagram
                      })
                    }
                  />
                </ListItem>
              </List>
            )
          })
      }
    </div>
  )
});

import React from 'react';
import {useSelector} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Button
} from '@material-ui/core';
import gradients from '../../../../utils/gradients';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
  },
  header: {
    paddingBottom: 0,
  },
  auto: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0'
  },
  metrics: {
    display: 'inline-flex',
    alignItems: 'flex-end'
  },
  flow: {
    textAlign: 'center',
  },
  avatar: {
    backgroundImage: gradients.green,
  },
}));

// eslint-disable-next-line react/prop-types
export const TariffsCardView = ({trigger}) => {
  const classes = useStyles();
  const user = useSelector(state => state.system.USER_DATA)

  const visualDateHandler = date => {
    const _ = new Date(date);
    return _.toLocaleDateString('ru')
  }

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
          >
            <FlashOnOutlinedIcon />
          </Avatar>
        }
        className={classes.header}
        title={
          <Typography
            color="textSecondary"
            variant="h5"
          >
            Электроэнергия. Тарифы
          </Typography>
        }
      />
      <CardContent
        className={classes.root}
      >
        <Grid
          container
          justify="center"
          spacing={2}
        >
          <Grid
            className={classes.flow}
            item
            xs={12}
          >
            <Typography
              color="secondary"
              variant="h4"
            >
              на сегодня
            </Typography>
          </Grid>
          <Grid
            className={classes.flow}
            item
            xs={12}
          >
            <Typography variant="body1">Действует с {visualDateHandler(user.reg_date)}</Typography>
          </Grid>
          <Grid
            className={classes.auto}
            item
            xs={12}
          >
            <Typography variant="h5">День: </Typography>
            <span className={classes.metrics}>
              {user.price_tarif_1 
                ? (
                  <Typography variant="h2">{user.price_tarif_1}&nbsp;</Typography>
                )
                : (
                  <Typography variant="h5">{'Нет данных'}&nbsp;</Typography>
                )
              }
              {/* <Typography variant="body1">руб/кВтч</Typography> */}
              <Typography variant="body1">руб/кВт</Typography>
            </span>
          </Grid>
          <Grid
            className={classes.auto}
            item
            xs={12}
          >
            <Typography variant="h5">Ночь: </Typography>
            <span className={classes.metrics}>
              {user.price_tarif_2 
                ? (
                  <Typography variant="h2">{user.price_tarif_2}&nbsp;</Typography>
                )
                : (
                  <Typography variant="h5">{'Нет данных'}&nbsp;</Typography>
                )
              }
              {/* <Typography variant="body1">руб/кВтч</Typography> */}
              <Typography variant="body1">руб/кВт</Typography>
            </span>
          </Grid>
          <Grid
            className={classes.auto}
            item
            xs={12}
          >
            <Typography variant="h5">Сутки: </Typography>
            <span className={classes.metrics}>
              {user.price_tarif_3 
                ? (
                  <Typography variant="h2">{user.price_tarif_3}&nbsp;</Typography>
                )
                : (
                  <Typography variant="h5">{'Нет данных'}&nbsp;</Typography>
                )
              }
              {/* <Typography variant="body1">руб/кВтч</Typography> */}
              <Typography variant="body1">руб/кВт</Typography>
            </span>
          </Grid>
          {
            user.role === 'CHAIRMAN' &&
            <Grid
              item
              xs={12}
            >
              <Button
                color="primary"
                onClick={() => trigger(true)}
                variant="contained"
              >
                Настроить
              </Button>
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

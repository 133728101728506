import React from 'react';
import { Typography } from '@material-ui/core';

import { NewsItemDetails } from '../NewsItemDetails/NewsItemDetails';

export const NewsWrapper = ({
  news,
  handleClickDelete,
//   getGroupsDataHandler
}) => {
  if (news.length == 0) {
    return (
      <Typography
        align="center"
        color="textSecondary"
        variant="h5">
        Создавайте новости, которые будут отображаться на главной странице
      </Typography>
    );
  }
  return news.map((newsItem) => {
    return (
      <NewsItemDetails
        handleClickDelete={handleClickDelete}
        // getGroupsDataHandler={getGroupsDataHandler}
        key={newsItem.id}
        newsItem={newsItem}
      />
    );
  });
};

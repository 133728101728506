import styled from 'styled-components'

export const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Выводить по',
  info: '{from} - {to} (всего {count})'
};

export const searchPanelMessages = {
  searchPlaceholder: 'Найти...'
};

export const tableMessages = {
  noData: 'Ничего не найдено...'
};

export const TemplateWrapper = styled.main`
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    padding-right: 10px;
    
    @media screen and (max-width: 548px) {
      padding-bottom: 10px;
    }
`;

export const DashboardTemplateWrapper = styled.main`
    width: auto;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    padding-right: 10px;
    
    @media screen and (max-width: 799px) {
      width: 100%;
      padding-right: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
`;

export const DashboardNextWrap = styled.main`
  display:flex;
  align-items: center;
  padding-right: 5px;

  @media screen and (max-width: 799px) {
    width: max-content;
    padding-bottom: 8px;
    padding-right: 0;
  }
`;

export const PlotsWrapper = styled.main`
  margin-top: 20px;
  margin-bottom: 40px;
`;

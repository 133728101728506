import React, { createRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Collapse,
  Grid,
  TextField,
  CardActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useHttp } from '../../../../hooks/http.hook';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  form: {
    paddingTop: 20,
    paddingBottom: 30
  },
  close: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: '#84be41'
  },
  save: {
    marginLeft: '-24px'
  }
}));

const initialState = { name: '', assets: null, uri: '' , id_parent:'0'};

const FilesAdd = ({ open, flag, trigger, clear, update, close, dirs }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { request } = useHttp();
  const user = useSelector((state) => state.system.USER_DATA);
  //   const dispatch = useDispatch();
  const [form, setForm] = useState({ name: '', assets: null, uri: '', id_parent:'0' });
  const handleFilesUpload = createRef();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const clickUploadHandler = () => {
    return handleFilesUpload.current.click();
  };
  const makeFormData = (id, type, role, file) => {
    const form = new FormData();
    form.append('id', id);
    form.append('role_check', role);
    form.append('type', type);
    form.append('file', file);
    form.append('name', file.name);
    return form;
  };
  const sourceFileHandler = useCallback(
    async (e, form) => {
      const formData = makeFormData(
        user.group,
        'document',
        user.role,
        e.target.files[0]
      );
      axios
        .post('/upload/loading', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (!res.data.result) {
            enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
          }
          setForm({ ...form, uri: res.data.result });
        })
        .catch(() => {
          enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
        });
    },
    [axios]
  );
  const sentFilesAllData = useCallback(async () => {
    if (!form.id) {
      const data = await request('/documents/add', 'POST', {
        name: form.name,
        user_id: user.id,
        group: user.group,
        uri: form.uri,
        id_parent: form.id_parent
      });
      if (!data.result) enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
      
      enqueueSnackbar('Файл успешно добавлен', { variant: 'success' });
    } else {
      const data = await request('/documents/update', 'POST', {
        id: form.id,
        name: form.name,
        user_id: user.id,
        group: user.group,
        uri: form.uri,
        id_parent: form.id_parent
      });
      if (!data.result) {
        enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
      }
      enqueueSnackbar('Файл успешно отредактирован', { variant: 'success' });
    }
    update();

    setTimeout(() => {
      close();
      clear();
    }, 100);
  }, [form, close, clear, trigger]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.uri == '') {
      enqueueSnackbar('Добавьте файл перед сохранением', { variant: 'error' });
      return;
    }
    sentFilesAllData();
  };
  const nameHelper = (path) => {
    return path.split('/')[path.split('/').length - 1];
  };
  useEffect(() => {
    if (flag === 'file') {
      return setForm({...initialState });
    }

    if (trigger && open) {
      const file = { ...trigger };

      setForm({
        ...file
      });
    }
  }, [trigger, flag, open]);
  return (
    <Card className={classes.root}>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit>
        <form
          className={classes.form}
          onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              md={4}
              xs={12}>
              <TextField
                fullWidth
                label="Название файла"
                name="name"
                onChange={handleChange}
                required
                value={form.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={2}>
              <Tooltip
                arrow
                title="Загрузить">
                <IconButton
                  aria-label="update"
                  color="primary"
                  edge="start"
                  onClick={clickUploadHandler}>
                  <PublishIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <input
                accept=".jpg, .jpeg, .png, .pdf"
                className="inputFile"
                name="assets"
                onChange={(e) => {
                  sourceFileHandler(e, form);
                }}
                ref={handleFilesUpload}
                type="file"
              />
              {form.uri !== null && (
                <Typography component="span">{nameHelper(form.uri)}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              md={4}
              xs={12}>
              <FormControl
                className={classes.formControl}
                fullWidth
                variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Подраздел
                </InputLabel>
                <Select
                  fullWidth
                  id="demo-simple-select-outlined"
                  label="Сделать подразделом"
                  labelId="demo-simple-select-outlined-label"
                  name="id_parent"
                  onChange={handleChange}
                  value={form.id_parent+''}>
                  <MenuItem value={0}>
                    <em>Без раздела</em>
                  </MenuItem>
                  {dirs.map((el) => {
                    return <MenuItem
                      key={el.id}
                      value={el.id+''}>{el.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {user.role === 'CHAIRMAN' && (
            <CardActions>
              <Button
                className={classes.save}
                color="secondary"
                type="submit"
                variant="contained">
                Сохранить
              </Button>
            </CardActions>
          )}
        </form>
      </Collapse>
    </Card>
  );
};

export default FilesAdd;

import {CHANGE_DAY, CHANGE_PERIOD, GET_CHART, SET_CHART} from '../types';

const initialState = {
  check: true,
  day: 'day',
  format: 'month',
  chart: 'electric',
  select: 'kvt',
  data: {}
};

export const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHART:
      return {...state, ...action.payload};
    case SET_CHART:
      return {...state, data: action.payload}
    case CHANGE_DAY:
      return {...state, day: action.payload};
    case CHANGE_PERIOD:
      return {...state, period: action.payload};
    default: return state
  }
};

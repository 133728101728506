import React, { useState, useEffect, useCallback } from 'react';
import Aux from '../../layouts/Auxilary';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  Box,
  IconButton
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { useHttp } from '../../hooks/http.hook';
import FilesAdd from './components/FilesAdd';
import DirAdd from './components/DirAdd';
import TreeView from './components/TreeView';
import SnackMessage from './components/SnackMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  close: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: '#84be41'
  },
  span: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8
    }
  }
}));

const DocumentsPage = () => {
  const classes = useStyles();
  const { request } = useHttp();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.system.USER_DATA);
  const [files, setFiles] = useState([]);
  const [dirs, setDirs] = useState([]);
  const [flag, setFlag] = useState('');
  const [expandedFile, setExpandedFile] = useState(false);
  const [expandedDir, setExpandedDir] = useState(false);
  const [edit, setEdit] = useState(false);

  const clearFlag = () => {
    setFlag('');
  };
  const handleEdit = (panel, type) => {
    if (edit === panel) {
      setEdit(false);
      setExpandedFile(false);
      setExpandedDir(false);
      setFlag('');
    } else {
      if (type === 'file') {
        setExpandedDir(false);
        setTimeout(() => {
          setFlag('');
          setEdit(panel);
          setExpandedFile(true);
        }, 50);
      }
      if (type === 'dir') {
        setExpandedFile(false);
        setTimeout(() => {
          setFlag('');
          setEdit(panel);
          setExpandedDir(true);
        }, 50);
      }
    }
  };

  const handleClose = () => {
    setExpandedFile(false);
    setExpandedDir(false);
    setEdit(false);
  };
  const handleOpen = (flag) => {
    if (flag === 'file') {
      setExpandedDir(false);
      setExpandedFile(true);
    }
    if (flag === 'dir') {
      setExpandedDir(true);
      setExpandedFile(false);
    }
    setFlag(flag);
  };

  const deleteFileHandler = async (id, uri) => {
    console.log('id :>> ', id);
    const res = await request('/documents/delete', 'POST', {
      id: id,
      uri: uri
    });
    if (!res.status) {
      enqueueSnackbar('Что-то пошло не так...', {
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Файл успешно удален!', {
        variant: 'success'
      });
    }
    getDataHandler();
  };
  const deleteDirHandler = async (id) => {
    const res = await request('/documents/delete/group', 'POST', {
      id: id
    });
    if (!res.status) {
      enqueueSnackbar('Что-то пошло не так...', {
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Раздел успешно удален!', {
        variant: 'success'
      });
    }
    getDataHandler();
  };

  const handleClickDelete = (id, name, type, uri) => {
    const message =
      type === 'file'
        ? `Вы действительно хотите удалить файл "${name.toUpperCase()}"`
        : `Вы действительно хотите удалить раздел "${name.toUpperCase()}"`;

    const action = (id) => {
      return type === 'file'
        ? deleteFileHandler(id, uri)
        : deleteDirHandler(id);
    };
    enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: 3000,
      // eslint-disable-next-line
      content: function (key, message) {
        return (
          <SnackMessage
            action={action}
            id={id}
            key={key}
            message={message} />
        );
      }
    });
  };
  const getDataHandler = useCallback(async () => {
    const response = await request('/documents/get/grouping', 'POST', {
      group: user.group
    });
    getDirDataHandler();

    if (!response.status || response.result === 'no docs') {
      setFiles([]);
      return;
    }

    const res = response.result;
    setFiles([...res]);

  }, [user, request]);

  const getDirDataHandler = useCallback(async () => {
    const response = await request('/documents/get/group', 'POST', {
      group: user.group
    });

    if (!response.status || response.result === 'no documents') {
      setDirs([]);
      return;
    }

    const res = response.result;
    setDirs([...res]);
  }, [user, request]);

  useEffect(() => {
    getDataHandler();
  }, [getDataHandler]);

  return (
    <Aux
      description={user.role === 'CHAIRMAN' ? 'Редактирование' : 'Просмотр'}
      name="Документы">
      <Card>
        {user.role === 'CHAIRMAN' && (
          <>
            <CardHeader
              className={classes.header}
              title={
                <Box
                  display="flex"
                  flexWrap="wrap">
                  <span className={classes.span}>
                    <Fab
                      aria-label="add"
                      className="BtnGreen"
                      onClick={() => handleOpen('file')}
                      size="small">
                      <AddIcon />
                    </Fab>
                    <span className="ButtonTitle">Добавить файл</span>
                  </span>
                  <span>
                    <Fab
                      aria-label="add"
                      className="BtnGreen"
                      onClick={() => handleOpen('dir')}
                      size="small">
                      <AddIcon />
                    </Fab>
                    <span className="ButtonTitle">Добавить раздел</span>
                  </span>
                </Box>
              }
            />
            <Divider />
          </>
        )}
        <CardContent className={classes.root}>
          {(expandedFile || expandedDir) && (
            <IconButton
              className={classes.close}
              onClick={handleClose}
              size="small">
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
          {user.role === 'CHAIRMAN' && (
            <>
              <FilesAdd
                clear={clearFlag}
                close={handleClose}
                dirs={dirs}
                flag={flag}
                open={expandedFile}
                trigger={edit}
                update={getDataHandler}
              />
              <DirAdd
                clear={clearFlag}
                close={handleClose}
                dirs={dirs}
                flag={flag}
                open={expandedDir}
                trigger={edit}
                update={getDataHandler}
              />
            </>
          )}
          {/* <FilesView
            close={handleClose}
            deleteFile={handleClickDelete}
            edit={handleEdit}
            files={files}
            open={handleOpen}
            trigger={edit}
          /> */}
          <TreeView
            close={handleClose}
            data={files}
            edit={handleEdit}
            handleClickDelete={handleClickDelete}
            open={handleOpen}
            trigger={edit}
          />
        </CardContent>
      </Card>
    </Aux>
  );
};
export default DocumentsPage;

import React, { useState, useEffect, useCallback } from 'react';
// import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  //   Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions
  //   TextField,
  //   Divider,
  //   Button
} from '@material-ui/core';
import { EditorState, convertFromRaw, Editor } from 'draft-js';
import Aux from '../../layouts/Auxilary';
import { makeStyles } from '@material-ui/styles';
import { useHttp } from '../../hooks/http.hook';
import no_image from '../../resources/images/backgrounds/no_image.png';
// import Skeleton from '@material-ui/lab/Skeleton';
import { NewsSkeleton } from './components/NewsSkeleton';
import Gallery from './components/Gallery';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    lineHeigth: 21
  },
  editorContainer: {
    // padding: theme.spacing(2),
    '& .public-DraftEditorPlaceholder-root': {
      ...theme.typography.body2
    },
    '& .public-DraftEditorPlaceholder-hasFocus': {
      display: 'none'
    },
    '& .public-DraftEditor-content': {
      '& p': {
        ...theme.typography.body1
      },
      '& h1': {
        ...theme.typography.h1,
        marginBottom: theme.spacing(2)
      },
      '& h2': {
        ...theme.typography.h2,
        marginBottom: theme.spacing(2)
      },
      '& h3': {
        ...theme.typography.h3,
        marginBottom: theme.spacing(2)
      },
      '& h4': {
        ...theme.typography.h4,
        marginBottom: theme.spacing(2)
      },
      '& h5': {
        ...theme.typography.h5,
        marginBottom: theme.spacing(2)
      },
      '& h6': {
        ...theme.typography.h6,
        marginBottom: theme.spacing(2)
      },
      '& blockquote': {
        ...theme.typography.subtitle1
      },
      '& ul': {
        ...theme.typography.body1,
        marginLeft: theme.spacing(4)
      },
      '& ol': {
        ...theme.typography.body1,
        marginLeft: theme.spacing(4)
      },
      '& pre': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2
      }
    }
  },
  textAlignLeft: {
    textAlign: 'left'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  textAlignRight: {
    textAlign: 'right'
  },
  textAlignJustify: {
    textAlign: 'justify'
  },
  media: {
    minHeight: 320
  }
}));

const NewsPage = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const { request, loading } = useHttp();
  const [form, setForm] = useState({ header: '' });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getPrevDataHandler = useCallback(
    async (id) => {
      const response = await request('/news/id', 'POST', { id: id });
      const res = response.result[0];

      setForm({ ...res });
      // setEditorState(convertFromRaw(JSON.parse(response.result[0].body)))
      const convertedState = convertFromRaw(JSON.parse(res.body));
      const editorValue = EditorState.createWithContent(convertedState);
      setEditorState(editorValue);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [request]
  );

  useEffect(() => {
    if (params.id) {
      getPrevDataHandler(params.id);
    } else {
      history.push('');
    }
  }, [getPrevDataHandler, params.id]);
  return (
    <Aux
      description={form.header}
      name="Просмотр новости">
      {!loading ? (
        <Card className={classes.root}>
          {!form.img_link || (
            <CardMedia
              className={classes.media}
              image={!form.img_link ? no_image : form.img_link}
            />
          )}
          <CardContent className={classes.editorContainer}>
            <Editor
              editorState={editorState}
              readOnly />
          </CardContent>
          <CardActions>
            {form.galery_json !== undefined && (
              <Gallery tileData={form.galery_json} />
            )}
          </CardActions>
        </Card>
      ) : (
        <NewsSkeleton />
      )}
    </Aux>
  );
};
export default NewsPage;

import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import plus from '../../../resources/images/weather/plus.svg'
import minus from '../../../resources/images/weather/minus.svg'
import normal from '../../../resources/images/weather/temp.svg'
import pressure from '../../../resources/images/weather/pressure.svg'
import wind from '../../../resources/images/weather/wind.svg'
import humidity from '../../../resources/images/weather/humidity.svg'
import sunrise from '../../../resources/images/weather/sunrise.svg'
import sunset from '../../../resources/images/weather/sunset.svg'

// eslint-disable-next-line react/prop-types
export const TemperatureIcon = ({ condition }) => {
  let image;
  if (condition > 0) {
    image = plus
  } else if (condition < 0) {
    image = minus
  } else {
    image = normal
  }
  return (
    <Tooltip title="&#8451;" >
      <img
        alt="&#8451;"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};

// eslint-disable-next-line react/prop-types,react/no-multi-comp
export const FeelsIcon = ({ condition }) => {
  let image;
  if (condition > 0) {
    image = plus
  } else if (condition < 0) {
    image = minus
  } else {
    image = normal
  }

  return (
    <Tooltip title="&#8451;" >
      <img
        alt="&#8451;"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};

// eslint-disable-next-line react/prop-types,react/no-multi-comp
export const PressureIcon = ({ condition }) => {
  const image = pressure;

  return (
    <Tooltip title={`${condition} мм/рт.ст`} >
      <img
        alt="мм/рт.ст"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};

// eslint-disable-next-line react/prop-types,react/no-multi-comp
export const WindIcon = ({ condition }) => {
  const image = wind;

  return (
    <Tooltip title={`${condition} м/сек`} >
      <img
        alt="м/сек"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};

// eslint-disable-next-line react/prop-types,react/no-multi-comp
export const HumidityIcon = ({ condition }) => {
  const image = humidity;

  return (
    <Tooltip title={`${condition} %`} >
      <img
        alt="%"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};

// eslint-disable-next-line react/no-multi-comp
export const SunriseIcon = () => {
  const image = sunrise;

  return (
    <Tooltip title="восход" >
      <img
        alt="восход"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};


// eslint-disable-next-line react/no-multi-comp
export const SunsetIcon = () => {
  const image = sunset;

  return (
    <Tooltip title="заход" >
      <img
        alt="заход"
        className="ConditionIcon"
        src={image}
      />
    </Tooltip>
  )
};
import styled from 'styled-components';

export const PaddingControl = styled.main`
  padding: 24px;
  
  @media screen and (max-width: 1279px) {
    padding: 12px;
  }
  
  @media screen and (max-width: 599px) {
    padding: 12px;
  }
  
`;

import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeUserData } from '../../../../../../redux/actions';
import { useHttp } from '../../../../../../hooks/http.hook';
import { useApi } from '../../../../../../hooks/api.hook';
import { emailValidator } from '../../../../../../hooks/email.hook';
import { SwitchHandler } from '../../../../../../components/Switcher/Switcher';
import { initialStateDialog } from './FormInitial';
import { initialStateSuggestion } from './FormInitialSug';
import { DatePicker } from '@material-ui/pickers';

import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Fab,
  Grid,
  CardContent,
  TextField,
  InputLabel,
  FormControl,
  Select,
  FormControlLabel,
  Button,
  Divider,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CardActions
} from '@material-ui/core';

import {
  GmailIcon,
  InstagramIcon,
  SkypeIcon,
  TelegramIcon,
  VkIcon
} from '../../../../../../components/SocialIcons/SocialIcons';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: '#fff'
  },
  select: {
    width: '100%'
  },
  control: {
    height: 53,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    height: 'fit-content'
  },
  row: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  error: {
    margin: '0 14px 0 14px',
    color: '#e53935'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props} />;
});

const mapStateToProps = (state) => {
  return {
    user: state.system.USER_DATA
  };
};

const mapDispatchToProps = {
  changeUserData
};

// eslint-disable-next-line react/no-multi-comp,react/prop-types
export const FullScreenDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ user, changeUserData, userId, userRole, update }) => {
  const classes = useStyles();
  const { request, loading } = useHttp();
  const { api } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [sug, setSug] = useState(initialStateSuggestion);

  const id = useParams().id;

  const [form, setForm] = useState(initialStateDialog);
  const [oldForm, setOldForm] = useState(initialStateDialog);

  const handleClickOpen = (id) => {
    setOpen(true);
    if (id) {
      setForm(initialStateDialog);
      getUserDataHandler();
    }
  };

  const handleClose = () => {
    setForm(initialStateDialog);
    setOpen(false);
  };

  const inputFormHandler = (e) => {
    if (e !== null) {
      if (
        e.target.name === 'part' ||
        e.target.name === 'inn' ||
        e.target.name === 'snils' ||
        e.target.name === 'passportSeries' ||
        e.target.name === 'passportNumber' ||
        e.target.name === 'issued_code'
      )
        e.target.value = e.target.value.replace(/\D+/g, '');
      if (e.target.name === 'part' && e.target.value > 100)
        e.target.value = 100;
      if (e.target.name === 'issued_code')
        e.target.value = e.target.value
          .split(/^(\d{3})(\d{1,3})$/)
          .filter(Boolean)
          .join('-');
      if (e.target.name === 'snils')
        e.target.value = e.target.value
          .split(/^(\d{3})(\d{0,3})(\d{0,3})(\d{0,2})$/)
          .filter(Boolean)
          .join('-');
      setForm({ ...form, [e.target.name]: e.target.value });

      if (e.target.name === 'last_name') {
        fioSug(e.target.name, e.target.value, 'SURNAME');
      }
      if (e.target.name === 'first_name') {
        fioSug(e.target.name, e.target.value, 'NAME');
      }
      if (e.target.name === 'second_name') {
        fioSug(e.target.name, e.target.value, 'PATRONYMIC');
      }
      if (e.target.name === 'issued_code') {
        issuedSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'issued') {
        issuedSug('issued', e.target.value);
      }
      if (e.target.name === 'address') {
        addressSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'addressFact') {
        addressSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'email') {
        emailSug(e.target.name, e.target.value);
      }
      if (e.target.name === 'passportNumber' && e.target.value.length === 6) {
        passCheck('pass', `${form.passportSeries}${e.target.value}`);
      }

      // апи с ограничением (не для проды)
      // if(e.target.name === 'inn' && e.target.value.length === 12){
      //   innCheck(e.target.name, {inn: e.target.value});
      // }
      if (e.target.name === 'snils' && e.target.value.length === 14) {
        setSug({
          ...sug,
          isErroredSnils: !checkSnils(e.target.value)
        });
        enqueueSnackbar('Некорректный СНИЛС', { variant: 'error' });
      }
    }
  };

  const fioSug = useCallback(
    async (name, val, parts) => {
      const res = await api('/dadata/suggestions/fio', 'POST', {
        query: val,
        parts: parts
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const addressSug = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/address', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const issuedSug = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/fms_unit', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const emailSug = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/email', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        [name]: [...res.suggestions]
      });
    },
    [sug]
  );

  const passCheck = useCallback(
    async (name, val) => {
      const res = await api('/dadata/suggestions/passport', 'POST', {
        query: val
      });
      setSug({
        ...sug,
        isErroredPass: res[0].qc === 0 ? false : true
      });
      switch (res[0].qc) {
        case 2:
          enqueueSnackbar('Исходное значение пустое', { variant: 'error' });
          break;
        case 1:
          enqueueSnackbar('Неправильный формат серии или номера', {
            variant: 'error'
          });
          break;
        case 10:
          enqueueSnackbar('Недействительный паспорт', { variant: 'error' });
          break;
        default:
          break;
      }
    },
    [sug]
  );
  // апи с ограничением (не для проды)
  // const innCheck = useCallback(async (name, val) => {
  //   const res = await api('/fns/egr', 'POST',
  //     {query:val});
  //   console.log('res :>> ', res);
  //   if(res.items.length > 0){
  //     let fullFio = res.items[0]['ИП']['ФИОПолн'].split(' ')
  //     setSug({
  //       ...sug,
  //       last_name: [{value: fullFio[0]}],
  //       first_name: [{value: fullFio[1]}],
  //       second_name: [{value: fullFio[2]}],
  //       isErroredInn: false,
  //     })
  //   } else {
  //     setSug({
  //       ...sug,
  //       isErroredInn: true,
  //     })
  //   }
  // },[sug])
  const dateFormHandler = (e, name) => {
    setForm({ ...form, [name]: e });
  };

  const checkerFormHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const changePhoneHandler = (value, name) => {
    value = value.replace(/\D+/g, '');
    setForm({ ...form, [name]: value });
  };

  const checkSnils = (snils) => {
    let arrSnils = snils.split('-');
    let numSnils = `${arrSnils[0]}${arrSnils[1]}${arrSnils[2]}`;
    let control = arrSnils[3];
    let result = 0;

    for (let i = 0; i < numSnils.length; i++) {
      result += (numSnils.length - i) * numSnils[i];
    }
    // Корректируем контрольное число
    if (result == 100 || result == 101) result = '00';
    if (result > 101) result %= 101;
    // Проверчем контрольное число
    if (result == control) return true;
    else return false;
  };

  const dataEventHandler = (data) => {
    if (data) {
      const addrCheck = data.addrChecker === 1 || data.addrChecker === 'Y';
      const contCheck = data.contact === 1 || data.contact === 'Y';
      const status = data.part ? 'owner' : 'user';
      const address = [
        data.region,
        data.city,
        data.street,
        data.house,
        data.apartments
      ]
        .filter(Boolean)
        .join(', ');
      const addressFact = [
        data.regionFact,
        data.cityFact,
        data.streetFact,
        data.houseFact,
        data.apartmentsFact
      ]
        .filter(Boolean)
        .join(', ');

      const fetchedData = {
        ...data,
        addrChecker: addrCheck,
        contact: contCheck,
        role: status,
        address: address,
        addressFact: addressFact
      };
      const newForm = {};

      Object.keys(fetchedData).map((item) => {
        if (fetchedData[item] !== null) {
          return (newForm[item] = fetchedData[item]);
        } else {
          return (newForm[item] = '');
        }
      });

      return newForm;
    }
  };

  const getUserDataHandler = useCallback(async () => {
    if (!userId) return;
    const newUserData = await request('/people/get/user_info', 'POST', {
      role: userRole ? 'owner' : 'user',
      id: userId,
      role_check: user.role
    });

    const response = await request('/people/check/user_changed_info', 'POST', {
      role: userRole ? 'owner' : 'user',
      id: userId,
      role_check: user.role
    });

    const oldData = response.result[0];
    const newData = newUserData.result[0];

    if (user.role === 'CHAIRMAN') {
      if (newData) {
        setForm({ ...form, ...dataEventHandler(newData) });
        setOldForm({ ...form, ...dataEventHandler(oldData) });
      } else {
        setForm({ ...form, ...dataEventHandler(oldData) });
        setOldForm({ ...form, ...dataEventHandler(oldData) });
      }
    } else {
      if (oldData) {
        setForm({ ...form, ...dataEventHandler(oldData) });
        setOldForm({ ...form, ...dataEventHandler(oldData) });
      } else {
        setForm({ ...form, ...dataEventHandler(newData) });
        setOldForm({ ...form, ...dataEventHandler(newData) });
      }
    }
  }, [userId, userRole, form, request, user]);

  const userInfo = useCallback(async () => {
    if (!userId) {
      const result = await request('/people/add', 'POST', {
        ...form,
        id_parcel: id,
        group: user.group,
        role_check: user.role
      });

      const status = result.status ? 'success' : 'error';
      const message = result.status
        ? 'Пользователь успешно добавлен'
        : 'Что-то пошло не так...';
      enqueueSnackbar(message, { variant: status });
      setForm(initialStateDialog);
      setOpen(false);
    } else {
      const result = await request('/people/update', 'POST', {
        ...form,
        id_parcel: id,
        group: user.group,
        role_check: user.role,
        user_id: userId,
        owner_id: userId
      });

      const status = result.status ? 'success' : 'error';
      const message = result.status
        ? 'Пользователь успешно обновлен'
        : 'Что-то пошло не так...';
      enqueueSnackbar(message, { variant: status });
      setForm(initialStateDialog);
      setOpen(false);

      if (+userId === +user.id) {
        changeUserData({ ...form, role: user.role });
      }
    }

    update();
  }, [
    id,
    form,
    userId,
    update,
    user,
    changeUserData,
    enqueueSnackbar,
    request
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    userInfo();
  };

  const helperTextHandler = (oldText, newText, flag) => {
    if (oldText !== newText) {
      if (flag === 'date') {
        const _ = new Date(oldText);
        return _.toLocaleDateString('ru');
      } else {
        return oldText;
      }
    }
  };

  const theme = JSON.parse(localStorage.getItem('theme'));
  const setTheme = (set) => {
    if (set && set.theme === 'DARK') return 'DARK';
    return '';
  };
  return (
    <div>
      {userId ? (
        <span>
          <Fab
            aria-label="edit"
            className="BtnGreen extraSmall"
            color="secondary"
            onClick={() => handleClickOpen(userId)}
            size="small">
            <EditIcon />
          </Fab>
        </span>
      ) : (
        <span>
          <Fab
            aria-label="add"
            className="BtnGreen"
            onClick={handleClickOpen}
            size="small">
            <AddIcon />
          </Fab>
          <span className="ButtonTitle">Добавить пользователя</span>
        </span>
      )}
      {!loading && (
        <Dialog
          className={setTheme(theme)}
          fullScreen
          onClose={handleClose}
          open={open}
          TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography
                className={classes.title}
                variant="h5">
                Создание пользователя
              </Typography>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid
                container
                spacing={2}>
                <Grid
                  item
                  lg={3}
                  md={4}
                  xs={12}>
                  <FormControl
                    className={`${classes.select} NativeSelect`}
                    variant="outlined">
                    <InputLabel htmlFor="outlined-role-native-simple">
                      Роль *
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: 'role'
                      }}
                      label="Роль"
                      name="role"
                      native
                      onChange={inputFormHandler}
                      required
                      value={form.role}>
                      <option
                        disabled
                        value="" />
                      <option value="owner">Собственник</option>
                      <option value="user">Пользователь</option>
                    </Select>
                  </FormControl>
                </Grid>
                {form.role === 'owner' && (
                  <Grid
                    className={classes.row}
                    item
                    lg={1}
                    md={2}
                    xs={4}>
                    <TextField
                      fullWidth
                      label="Доля"
                      name="part"
                      onChange={inputFormHandler}
                      value={form.part}
                      variant="outlined"
                    />
                    <Typography
                      className="pl5"
                      variant="button">
                      %
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  lg={8}
                  md={6}
                  xs={8}>
                  <FormControlLabel
                    className={classes.control}
                    control={
                      <SwitchHandler
                        checked={form.contact}
                        name="contact"
                        onChange={checkerFormHandler}
                      />
                    }
                    label="Контактное лицо"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid
                container
                spacing={2}>
                <Grid
                  className={classes.container}
                  container
                  item
                  lg={6}
                  md={6}
                  spacing={2}
                  xs={12}>
                  <Grid
                    className="TextCenter"
                    item
                    lg={12}
                    md={12}
                    xs={12}>
                    <Typography variant="h5">О себе</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Autocomplete
                      freeSolo
                      // disableClearable={true}
                      getOptionLabel={(option) => option.value}
                      inputValue={form.last_name}
                      name="last_name"
                      onChange={(e, newVal) => {
                        setForm({ ...form, last_name: newVal.value });
                      }}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'last_name';
                        }
                        inputFormHandler(e);
                      }}
                      options={sug.last_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!oldForm.last_name &&
                            form.last_name !== oldForm.last_name
                          }
                          fullWidth
                          // onChange={inputFormHandler}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Фамилия"
                          name="last_name"
                          required
                          value={form.last_name}
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => <span>{option.value}</span>}
                    />
                    {/* <TextField
                      error={!!oldForm.last_name && form.last_name !== oldForm.last_name}
                      fullWidth
                      label="Фамилия"
                      name="last_name"
                      onChange={inputFormHandler}
                      value={form.last_name}
                      variant="outlined"
                      required
                    /> */}
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.last_name, form.last_name)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) => option.value}
                      inputValue={form.first_name}
                      name="first_name"
                      onChange={(e, newVal) => {
                        setForm({ ...form, first_name: newVal.value });
                      }}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'first_name';
                        }
                        inputFormHandler(e);
                      }}
                      options={sug.first_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!oldForm.first_name &&
                            form.first_name !== oldForm.first_name
                          }
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Имя"
                          name="first_name"
                          required
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => <span>{option.value}</span>}
                    />
                    {/* <TextField
                      error={!!oldForm.first_name && form.first_name !== oldForm.first_name}
                      fullWidth
                      helperText={helperTextHandler(oldForm.first_name, form.first_name)}
                      label="Имя"
                      name="first_name"
                      onChange={inputFormHandler}
                      value={form.first_name}
                      variant="outlined"
                      required
                    /> */}
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.first_name, form.first_name)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) => option.value}
                      inputValue={form.second_name}
                      name="second_name"
                      onChange={(e, newVal) => {
                        setForm({ ...form, second_name: newVal.value });
                      }}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'second_name';
                        }
                        inputFormHandler(e);
                      }}
                      options={sug.second_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!oldForm.second_name &&
                            form.second_name !== oldForm.second_name
                          }
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Отчество"
                          name="second_name"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => <span>{option.value}</span>}
                    />
                    {/* <TextField
                      error={!!oldForm.second_name && form.second_name !== oldForm.second_name}
                      fullWidth
                      label="Отчество"
                      name="second_name"
                      onChange={inputFormHandler}
                      value={form.second_name}
                      variant="outlined"
                    /> */}
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.second_name, form.second_name)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <DatePicker
                      autoOk
                      cancelLabel={'Отмена'}
                      disableFuture
                      format="dd.MM.yyyy"
                      fullWidth
                      inputVariant="outlined"
                      label="Дата рождения"
                      name="birthDate"
                      onChange={(e) => dateFormHandler(e, 'birthDate')}
                      value={form.birthDate}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <TextField
                      error={
                        (!!oldForm.inn && form.inn !== oldForm.inn) ||
                        sug.isErroredInn
                      }
                      fullWidth
                      inputProps={{ maxLength: 12 }}
                      label="ИНН"
                      name="inn"
                      onChange={inputFormHandler}
                      value={form.inn}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.inn, form.inn)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <TextField
                      error={
                        (!!oldForm.snils && form.snils !== oldForm.snils) ||
                        sug.isErroredSnils
                      }
                      fullWidth
                      inputProps={{ maxLength: 14 }}
                      label="СНИЛС"
                      name="snils"
                      onChange={inputFormHandler}
                      value={form.snils}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.snils, form.snils)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.container}
                  container
                  item
                  lg={6}
                  md={6}
                  spacing={2}
                  xs={12}>
                  <Grid
                    className="TextCenter"
                    item
                    lg={12}
                    md={12}
                    xs={12}>
                    <Typography variant="h5">Паспорт</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}>
                    <TextField
                      error={
                        (!!oldForm.passportSeries &&
                          form.passportSeries !== oldForm.passportSeries) ||
                        sug.isErroredPass
                      }
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                      label="Серия"
                      name="passportSeries"
                      onChange={inputFormHandler}
                      value={form.passportSeries}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(
                        oldForm.passportSeries,
                        form.passportSeries
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}>
                    <TextField
                      error={
                        (!!oldForm.passportNumber &&
                          form.passportNumber !== oldForm.passportNumber) ||
                        sug.isErroredPass
                      }
                      fullWidth
                      inputProps={{ maxLength: 6 }}
                      label="Номер"
                      name="passportNumber"
                      onChange={inputFormHandler}
                      value={form.passportNumber}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(
                        oldForm.passportNumber,
                        form.passportNumber
                      )}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}>
                    <DatePicker
                      autoOk
                      cancelLabel={'Отмена'}
                      disableFuture
                      format="dd.MM.yyyy"
                      fullWidth
                      inputVariant="outlined"
                      label="Дата выдачи"
                      name="issuedDate"
                      onChange={(e) => dateFormHandler(e, 'issuedDate')}
                      value={form.issuedDate}
                    />
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(
                        oldForm.issuedDate,
                        form.issuedDate,
                        'date'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}>
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) =>
                        `${option.data.code} - ${option.data.name}`
                      }
                      inputValue={form.issued_code}
                      name="issued_code"
                      onChange={(e, newVal) => {
                        if (
                          newVal &&
                          newVal !== null &&
                          typeof newVal['value'] !== 'undefined'
                        ) {
                          setForm({
                            ...form,
                            issued: newVal.data.name,
                            issued_code: newVal.data.code
                          });
                        }
                      }}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'issued_code';
                        }
                        inputFormHandler(e);
                      }}
                      options={sug.issued_code}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!oldForm.issued_code &&
                            form.issued_code !== oldForm.issued_code
                          }
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 7,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Код подразделения"
                          name="issued_code"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <span>
                          {' '}
                          {option.data.code}- {option.data.name}
                        </span>
                      )}
                    />
                    {/* <TextField
                      error={!!oldForm.code && form.code !== oldForm.code}
                      fullWidth
                      label="Код подразделения"
                      name="code"
                      onChange={inputFormHandler}
                      value={form.code}
                      variant="outlined"
                      inputProps={{maxLength: 7}}
                    /> */}
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.issued_code, form.issued_code)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) =>
                        `${option.data.code} - ${option.data.name}`
                      }
                      inputValue={form.issued}
                      name="issued"
                      onChange={(e, newVal) => {
                        if (
                          newVal &&
                          newVal !== null &&
                          typeof newVal['value'] !== 'undefined'
                        ) {
                          setForm({
                            ...form,
                            issued: newVal.data.name,
                            issued_code: newVal.data.code
                          });
                        }
                      }}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'issued';
                        }
                        inputFormHandler(e);
                      }}
                      options={sug.issued_code}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!oldForm.issued && form.issued !== oldForm.issued
                          }
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Кем выдан"
                          multiline
                          name="issued"
                          rows="4"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <span>
                          {' '}
                          {option.data.code}- {option.data.name}
                        </span>
                      )}
                    />
                    {/* <TextField
                      error={!!oldForm.issued && form.issued !== oldForm.issued}
                      fullWidth
                      label="Кем выдан"
                      multiline
                      name="issued"
                      onChange={inputFormHandler}
                      rows="4"
                      value={form.issued}
                      variant="outlined"
                    /> */}
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.issued, form.issued)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <TextField
                      error={
                        !!oldForm.birthPlace &&
                        form.birthPlace !== oldForm.birthPlace
                      }
                      fullWidth
                      label="Место рождения"
                      multiline
                      name="birthPlace"
                      onChange={inputFormHandler}
                      rows="4"
                      value={form.birthPlace}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption">
                      {helperTextHandler(oldForm.birthPlace, form.birthPlace)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid
                container
                spacing={2}>
                <Grid
                  className={classes.container}
                  container
                  item
                  lg={6}
                  md={6}
                  spacing={2}
                  xs={12}>
                  <Grid
                    className="TextCenter"
                    item
                    lg={12}
                    md={12}
                    xs={12}>
                    <Typography variant="h5">Адрес прописки</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) => option.value}
                      inputValue={form.address}
                      name="address"
                      onChange={(e, newVal) => {
                        console.log('e :>> ', e);
                        console.log('newVal :>> ', newVal);
                        if (
                          newVal &&
                          newVal !== null &&
                          typeof newVal['data'] !== 'undefined'
                        ) {
                          setForm({
                            ...form,
                            address: newVal.value ? newVal.value : '',
                            region: newVal.data.region ? newVal.data.region : '',
                            city: newVal.data.city ? newVal.data.city : '',
                            street: newVal.data.street_with_type ? newVal.data.street_with_type : '',
                            house: newVal.data.house ? newVal.data.house : '',
                            apartments: newVal.data.flat ? newVal.data.flat : ''
                          });
                        }
                      }}
                      onInputChange={(e, value, reason) => {
                        console.log('e :>> ', e);
                        console.log('value :>> ', value);
                        console.log('reason :>> ', reason);
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'address';
                        }
                        inputFormHandler(e);
                        setForm((form) => ({
                          ...form, 
                          region: value,
                        }))
                      }}
                      options={sug.address}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!oldForm.address &&
                            form.address !== oldForm.address
                          }
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                          label="Адрес в свободном формате"
                          name="address"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => <span>{option.value}</span>}
                    />
                    {/* <Typography
                      className={classes.error}
                      variant="caption"
                    >{helperTextHandler(oldForm.address, form.address)}</Typography> */}
                    {/* <TextField
                      error={!!oldForm.region && form.region !== oldForm.region}
                      fullWidth
                      label="Область"
                      name="region"
                      onChange={inputFormHandler}
                      value={form.region}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption"
                    >{helperTextHandler(oldForm.region, form.region)}</Typography> */}
                  </Grid>

                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={!!oldForm.city && form.city !== oldForm.city}
                      fullWidth
                      label="Город"
                      name="city"
                      onChange={inputFormHandler}
                      value={form.city}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption"
                    >{helperTextHandler(oldForm.city, form.city)}</Typography>
                  </Grid> */}

                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={!!oldForm.street && form.street !== oldForm.street}
                      fullWidth
                      label="Улица"
                      name="street"
                      onChange={inputFormHandler}
                      value={form.street}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption"
                    >{helperTextHandler(oldForm.street, form.street)}</Typography>
                  </Grid> */}

                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={!!oldForm.house && form.house !== oldForm.house}
                      fullWidth
                      label="Дом"
                      name="house"
                      onChange={inputFormHandler}
                      value={form.house}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption"
                    >{helperTextHandler(oldForm.house, form.house)}</Typography>
                  </Grid> */}

                  {/* <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={!!oldForm.apartments && form.apartments !== oldForm.apartments}
                      fullWidth
                      label="Квартира"
                      name="apartments"
                      onChange={inputFormHandler}
                      value={form.apartments}
                      variant="outlined"
                    />
                    <Typography
                      className={classes.error}
                      variant="caption"
                    >{helperTextHandler(oldForm.apartments, form.apartments)}</Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}>
                    <FormControlLabel
                      className={classes.control}
                      control={
                        <SwitchHandler
                          checked={form.addrChecker}
                          name="addrChecker"
                          onChange={checkerFormHandler}
                        />
                      }
                      label="Фактический адрес не совпадает"
                    />
                  </Grid>
                  {form.addrChecker && (
                    <>
                      <Grid
                        className="TextCenter"
                        item
                        lg={12}
                        md={12}
                        xs={12}>
                        <Typography variant="h5">Адрес проживания</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}>
                        <Autocomplete
                          freeSolo
                          getOptionLabel={(option) => option.value}
                          inputValue={form.addressFact}
                          name="addressFact"
                          onChange={(e, newVal) => {
                            if (
                              newVal &&
                              newVal !== null &&
                              typeof newVal['data'] !== 'undefined'
                            ) {
                              setForm({
                                ...form,
                                addressFact: newVal.value ?? '',
                                regionFact: newVal.data.region ?? '',
                                cityFact: newVal.data.city ?? '',
                                streetFact: newVal.data.street_with_type ?? '',
                                houseFact: newVal.data.house ?? '',
                                apartmentsFact: newVal.data.flat ?? ''
                              });
                            }
                          }}
                          onInputChange={(e, value, reason) => {
                            if (reason === 'clear') {
                              e.target.value = '';
                              e.target.name = 'addressFact';
                            }
                            inputFormHandler(e);
                          }}
                          options={sug.addressFact}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                !!oldForm.addressFact &&
                                form.addressFact !== oldForm.addressFact
                              }
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password' // disable autocomplete and autofill
                              }}
                              label="Адрес в свободном формате"
                              name="addressFact"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => <span>{option.value}</span>}
                        />
                        <Typography
                          className={classes.error}
                          variant="caption">
                          {helperTextHandler(
                            oldForm.addressFact,
                            form.addressFact
                          )}
                        </Typography>
                        {/* <TextField
                          error={!!oldForm.regionFact && form.regionFact !== oldForm.regionFact}
                          fullWidth
                          label="Область"
                          name="regionFact"
                          onChange={inputFormHandler}
                          value={form.regionFact}
                          variant="outlined"
                        />
                        <Typography
                          className={classes.error}
                          variant="caption"
                        >{helperTextHandler(oldForm.regionFact, form.regionFact)}</Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={12}>
                        {/* <TextField
                          error={!!oldForm.cityFact && form.cityFact !== oldForm.cityFact}
                          fullWidth
                          label="Город"
                          name="cityFact"
                          onChange={inputFormHandler}
                          value={form.cityFact}
                          variant="outlined"
                        />
                        <Typography
                          className={classes.error}
                          variant="caption"
                        >{helperTextHandler(oldForm.cityFact, form.cityFact)}</Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={12}>
                        {/* <TextField
                          error={!!oldForm.streetFact && form.streetFact !== oldForm.streetFact}
                          fullWidth
                          label="Улица"
                          name="streetFact"
                          onChange={inputFormHandler}
                          value={form.streetFact}
                          variant="outlined"
                        />
                        <Typography
                          className={classes.error}
                          variant="caption"
                        >{helperTextHandler(oldForm.streetFact, form.streetFact)}</Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={12}>
                        {/* <TextField
                          error={!!oldForm.houseFact && form.houseFact !== oldForm.houseFact}
                          fullWidth
                          label="Дом"
                          name="houseFact"
                          onChange={inputFormHandler}
                          value={form.houseFact}
                          variant="outlined"
                        />
                        <Typography
                          className={classes.error}
                          variant="caption"
                        >{helperTextHandler(oldForm.houseFact, form.houseFact)}</Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={12}>
                        {/* <TextField
                          error={!!oldForm.apartmentsFact && form.apartmentsFact !== oldForm.apartmentsFact}
                          fullWidth
                          label="Квартира"
                          name="apartmentsFact"
                          onChange={inputFormHandler}
                          value={form.apartmentsFact}
                          variant="outlined"
                        />
                        <Typography
                          className={classes.error}
                          variant="caption"
                        >{helperTextHandler(oldForm.apartmentsFact, form.apartmentsFact)}</Typography> */}
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  className={classes.container}
                  container
                  item
                  lg={6}
                  md={6}
                  spacing={2}
                  xs={12}>
                  <Grid
                    className="TextCenter"
                    item
                    lg={12}
                    md={12}
                    xs={12}>
                    <Typography variant="h5">Контакты</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <PhoneInput
                      containerClass="react-tel-input PHONE"
                      country={'ru'}
                      countryCodeEditable={false}
                      disableDropdown
                      inputProps={{
                        required: true,
                        pattern: '.{18,}'
                      }}
                      onChange={(e) => changePhoneHandler(e, 'phone')}
                      value={form.phone}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <Autocomplete
                      freeSolo
                      getOptionLabel={(option) => option.value}
                      inputValue={form.email}
                      label="email"
                      name="email"
                      onChange={(e, newVal) => {
                        setForm({ ...form, email: newVal.value });
                      }}
                      onInputChange={(e, value, reason) => {
                        if (reason === 'clear') {
                          e.target.value = '';
                          e.target.name = 'email';
                        }
                        inputFormHandler(e);
                      }}
                      options={sug.email}
                      renderInput={(params) => (
                        <FormControl
                          fullWidth
                          ref={params.InputProps.ref}
                          variant="outlined">
                          <InputLabel
                            className={
                              !!form.email.trim().length &&
                              !emailValidator(form.email)
                                ? 'ERROR'
                                : ''
                            }>
                            Email *{' '}
                          </InputLabel>
                          <OutlinedInput
                            {...params.inputProps}
                            {...params.inputLabel}
                            error={
                              !!form.email.trim().length &&
                              !emailValidator(form.email)
                            }
                            labelWidth={46}
                            name="email"
                            required
                            startAdornment={
                              <InputAdornment position="start">
                                <GmailIcon />
                              </InputAdornment>
                            }
                            value={form.email}
                          />
                        </FormControl>
                      )}
                      renderOption={(option) => <span>{option.value}</span>}
                    />
                    {/* <FormControl
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel className={!!form.email.trim().length && !emailValidator(form.email) ? 'ERROR' : ''}>Email</InputLabel>
                      <OutlinedInput
                        error={!!form.email.trim().length && !emailValidator(form.email)}
                        labelWidth={36}
                        name="email"
                        onChange={inputFormHandler}
                        startAdornment={
                          <InputAdornment position="start">
                            <GmailIcon />
                          </InputAdornment>
                        }
                        value={form.email}
                        required
                      />
                    </FormControl> */}
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined">
                      <InputLabel>Telegram</InputLabel>
                      <OutlinedInput
                        labelWidth={60}
                        name="telegram"
                        onChange={inputFormHandler}
                        startAdornment={
                          <InputAdornment position="start">
                            <TelegramIcon />
                          </InputAdornment>
                        }
                        value={form.telegram}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <PhoneInput
                      containerClass="react-tel-input WATSAPP"
                      country={'ru'}
                      countryCodeEditable={false}
                      disableDropdown
                      onChange={(e) => changePhoneHandler(e, 'watsapp')}
                      value={form.watsapp}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <PhoneInput
                      containerClass="react-tel-input VIBER"
                      country={'ru'}
                      countryCodeEditable={false}
                      disableDropdown
                      onChange={(e) => changePhoneHandler(e, 'viber')}
                      value={form.viber}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined">
                      <InputLabel>Skype</InputLabel>
                      <OutlinedInput
                        labelWidth={40}
                        name="skype"
                        onChange={inputFormHandler}
                        startAdornment={
                          <InputAdornment position="start">
                            <SkypeIcon />
                          </InputAdornment>
                        }
                        value={form.skype}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined">
                      <InputLabel>VKontakte</InputLabel>
                      <OutlinedInput
                        labelWidth={65}
                        name="vk"
                        onChange={inputFormHandler}
                        startAdornment={
                          <InputAdornment position="start">
                            <VkIcon />
                          </InputAdornment>
                        }
                        value={form.vk}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined">
                      <InputLabel>Instagram</InputLabel>
                      <OutlinedInput
                        labelWidth={65}
                        name="instagram"
                        onChange={inputFormHandler}
                        startAdornment={
                          <InputAdornment position="start">
                            <InstagramIcon />
                          </InputAdornment>
                        }
                        value={form.instagram}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="secondary"
                type="submit"
                variant="contained">
                Сохранить изменения
              </Button>
            </CardActions>
          </form>
        </Dialog>
      )}
    </div>
  );
});

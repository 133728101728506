import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(2),
    lineHeigth: 21
  },
  media: {
    height: 320
  }
}));

export const NewsSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Skeleton
        animation="wave"
        className={classes.media}
        variant="rect" />

      <CardContent>
        <>
          <Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 6 }} />
          <Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 6 }} />
          <Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 6 }} />
          <Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 6 }} />
          <Skeleton
            animation="wave"
            height={10}
            width="80%" />
        </>
      </CardContent>
    </Card>
  );
};

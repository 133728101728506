import React, {Suspense, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {Hidden, LinearProgress} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { NavBar, TopBar } from './components';
import {WeatherCard} from '../../components/WeatherCard/WeatherCard';
import ru from 'date-fns/locale/ru';
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';

// eslint-disable-next-line no-unused-vars,react/prop-types
const Dashboard = ({children, view}) => {
  const {pathname} = useLocation()

  const monthValues = {
    narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
    abbreviated: [
      'янв.',
      'фев.',
      'март',
      'апр.',
      'май',
      'июнь',
      'июль',
      'авг.',
      'сент.',
      'окт.',
      'нояб.',
      'дек.'
    ],
    wide: [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь'
    ]
  };

  ru.localize.month = buildLocalizeFn({
    values: monthValues,
    defaultWidth: 'wide',
    defaultFormattingWidth: 'wide'
  });

  const hideRightBar = addr => {
    const settings = addr.match('settings')
    const configure = addr.match('configure')

    if (addr === '/') return 'none';
    if (
      settings ||
      configure
    ) return 'none';

    return ''
  }

  const useStyles = makeStyles(() => ({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    topBar: {
      zIndex: 2,
      position: 'relative'
    },
    container: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    navBar: {
      zIndex: 3,
      width: 256,
      minWidth: 256,
      flex: '0 0 auto'
    },
    rightBar: {
      zIndex: 3,
      width: 350,
      minWidth: 256,
      flex: '0 0 auto',
      paddingTop: 104,
      paddingRight: 16,
      paddingLeft: 13,
      display: hideRightBar(pathname)
    },
    content: {
      overflowY: 'auto',
      flex: '1 1 auto'
    },
    weather: {
      marginBottom: 16
    }
  }));

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const theme = JSON.parse(localStorage.getItem('theme')) || {theme: 'WHITE'};

  return (
    <div className={`${classes.root} ${theme.theme}`}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={`${classes.navBar}`}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {/* eslint-disable-next-line react/prop-types */}
            {children}
          </Suspense>
        </main>
        <Hidden mdDown>
          <main className={`${classes.rightBar} rightBar`}>
            <WeatherCard
              className={classes.weather}
            />
            <DatePicker
              autoOk
              openTo="date"
              orientation="landscape"
              variant="static"
            />
          </main>
        </Hidden>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
